import React,{ Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import reducer from './reducers/storReducer';
import './i18n';
import Fallback from './fallback';


const store = createStore(reducer);
var currentUrl = window.location.href;
if(currentUrl.indexOf("://www.b2b")>0){
  currentUrl = currentUrl.replace("://www.b2b","://b2b");

  window.location.href=currentUrl;
}
ReactDOM.render(
  <Suspense fallback={(<Fallback />)}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);

reportWebVitals();



