
import './styleSlider.css';
import React, { Component } from 'react';
import jwt_decode from "jwt-decode";
import home from '../../services/home';
import { withTranslation } from 'react-i18next';

class pageVisite extends Component
{
    constructor (props) {
        super(props)
        var userconnected=false;
        var userConnectedType=null;
        var userConnectedID=null;
        if(localStorage.getItem("tokenb2b") && localStorage.getItem("tokenb2b")!=""){
            var dataUser=jwt_decode(localStorage.getItem('tokenb2b'));
            if(dataUser && dataUser.code_user>0){
                userconnected=true;
                userConnectedType=dataUser.type;
                userConnectedID=dataUser.code_user;
            }
        }
        this.state = {
            userconnected:userconnected,
            userConnectedType:userConnectedType,
            userConnectedID:userConnectedID,
            post_id:props.postId,
            city:"",
            continentCode:"",
            continentName:"",
            countryCode:"",
            countryName:"",
            ipAddress:"",
            OS:this.getOS(),
            Browser:this.fnBrowserDetect()
        }   
        home.getIpInfo().then((res)=>{
            if(res && res.data){
                this.setState({
                    city:res.data.city,
                    continentCode:res.data.continentCode,
                    continentName:res.data.continentName,
                    countryCode:res.data.countryCode,
                    countryName:res.data.countryName,
                    ipAddress:res.data.ipAddress,
                });
                if(this.state.ipAddress!=""){
                    const data = new FormData();
                    data.append("userconnected",this.state.userconnected);
                    data.append("userConnectedType",this.state.userConnectedType);
                    data.append("userConnectedID",this.state.userConnectedID);
                    data.append("post_id",this.state.post_id);
                    data.append("city",this.state.city);
                    data.append("continentCode",this.state.continentCode);
                    data.append("continentName",this.state.continentName);
                    data.append("countryCode",this.state.countryCode);
                    data.append("countryName",this.state.countryName);
                    data.append("ipAddress",this.state.ipAddress);
                    data.append("OS",this.state.OS);
                    data.append("Browser",this.state.Browser);
                    home.pageVisite(data);
                    
                }
            }
        });
        
    }
    getOS(){
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
      
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
          os = 'Linux';
        }
      
        return os;
    }
    fnBrowserDetect(){         
        let userAgent = navigator.userAgent;
        let browserName;
        if(userAgent.match(/chrome|chromium|crios/i)){
            browserName = "chrome";
        }else if(userAgent.match(/firefox|fxios/i)){
            browserName = "firefox";
        }  else if(userAgent.match(/safari/i)){
            browserName = "safari";
        }else if(userAgent.match(/opr\//i)){
            browserName = "opera";
        } else if(userAgent.match(/edg/i)){
            browserName = "edge";
        }else{
            browserName="No browser detection";
        }
        return browserName;         
    }
    render() {
        
        return ( 
            <>
              
            </>
        );
    }
}



export default withTranslation()(pageVisite);