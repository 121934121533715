import React, { Component } from 'react';

export default class Button extends Component
{
    constructor (props) {
        super(props)
        var clicked=false;
        if(props.clicked && props.clicked=="oui"){
            clicked=true;
        }
        this.state={
            clicked:clicked,
            tag:this.props.tag,
            id:this.props.id
        }
    }
    click = (e)=>{
        if(this.state.clicked){
            this.setState({clicked:false});
            this.props.update(this.state.id,"del");
        }else{
            this.setState({clicked:true});
            this.props.update(this.state.id,"add");
        }
    }
    render() {
        const spanStyles = {
            height: "auto",
        };
        if(this.props.type=="cont"){
            return (<>
                <div className="col-md-4  type_contrat" style={{display: 'flex', justifyContent: 'center'}}>
                    <button onClick={this.click} type="button" style={spanStyles} className={"btn btn-outline-secondary btn-min-width mr-1 mb-1 "+(this.state.clicked?"btn_clicked":"btn_not_clicked")} >{this.state.tag}</button>
                </div>
            </>);
        }else{
            return (<>
                <button onClick={this.click} type="button" style={spanStyles} className={"btn btn-outline-secondary btn-min-width mr-1 mb-1 "+(this.state.clicked?"btn_clicked":"btn_not_clicked")} >{this.state.tag}</button>
            </>);
        }
       
    }
}
