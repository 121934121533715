import './style.css';
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Auth from '../../services/Auth';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import {b2bNameSite,b2bDescriptionSite} from '../../config.json';
import { Redirect } from 'react-router';
import i18next from 'i18next';
import ReactGA from 'react-ga'
export default class Index extends Component
{

    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
            redirect:<></>,
            stateuser:null,
            expertvalide:false
        } 
        const urlParams = new URLSearchParams(window.location.search);
        const account_activation = urlParams.get('account_activation');
        const useremail = urlParams.get('email');
        if(account_activation && account_activation!="" && useremail &&  useremail!=""){
            
            
            Auth.confirmerEnteprise(account_activation,useremail).then((res)=>{
                
                const long = (i18next.language=="fr-FR"||i18next.language=="fr")?"fr":"en";
                if(res.data.code==1){
                    localStorage.setItem("tokenb2b",res.data.token);
                    if(res.data.token!=""){
                        var dataUser = jwt_decode(res.data.token);
                        this.setState({stateuser:"confirme_email"});
                        if(dataUser.type=="expert" && dataUser.valid_b2b!="ok"){
                            this.setState({expertvalide:true});
                        }
                        //window.location.href="/"+long+'/confirmer-votre-compte-avec-succes';
                        // if(dataUser.type=="expert"){
                            
                        // }else{
                        //     if(dataUser.error!="") window.location.href="/"+long+'/add';
                        //     else window.location.href='/'+long;
                        // }
                    }
                }else if(res.data.code==0 ){
                    if(res.data.reponse=="userconfirme"){
                        this.setState({stateuser:"confirme_deja"});
                    }
                }
            });
        }else{
            const confirme_email = urlParams.get('confirme_email');
            if(confirme_email && confirme_email!="" && confirme_email=="ok"){
                this.setState({stateuser:"confirme_email"});
            }
        }
       
    }
    render() {
        return ( 
            <>
            {this.state.redirect}
            <MetaTags>
                <title>{localStorage.getItem("langb2b")==="fr" ? "Veuillez confirmer votre compte" : "Please confirm your account"} | {b2bNameSite} | {localStorage.getItem("langb2b")==="fr" ? b2bDescriptionSite : "B2B marketplace and B2B networking platform in France and abroad"}</title>
            </MetaTags>
            <div className="page-content">
                <section style={{backgroundColor: '#fff', minHeight: '450px', marginBottom: '-40px'}}>
                    {this.state.stateuser?<>
                        <h1 className="title__bordered">
                        {this.state.stateuser=="confirme_deja"?<>VOTRE ADRESSE E-MAIL EST DÉJÀ CONFIRMÉE</>:<></>}
                        {this.state.stateuser=="confirme_email"?<>VOTRE ADRESSE E-MAIL EST CONFIRMÉE</>:<></>}
                        </h1>
                        {this.state.expertvalide?<>
                        <h1 className="title__bordered">votre compte est en cours de validation</h1>
                        </>:<></>}
                        <div className="container">
                            <div className="col-md-12 widget de_widget_categories prod-list" style={{backgroundColor: '#fff'}}>
                                <div style={{margin: '50px auto'}}>
                                <section className="activation-notification" style={{backgroundColor: '#fff'}}>
                                    <div className="activation-notification-content">
                                    <p>
                                        <span>
                                        <i className="fa fa-check" style={{fontSize:"90px",color:"green"}}/></span>
                                    </p>
                                    </div>
                                </section>
                                </div>
                            </div>
                        </div>
                    </>:<>
                        <h1 className="title__bordered">Veuillez confirmer votre compte </h1>
                        <div className="container">
                            <div className="col-md-12 widget de_widget_categories prod-list" style={{backgroundColor: '#fff'}}>
                                <div style={{margin: '50px auto'}}>
                                <section className="activation-notification" style={{backgroundColor: '#fff'}}>
                                    <div className="activation-notification-content">
                                    <p>
                                        <span>
                                        <i className="fa fa-exclamation-circle" />
                                        Votre compte n'a pas encore été confirmé! Veuillez vérifier votre boîte mail pour activer votre compte                  </span>
                                    </p>
                                    </div>
                                </section>
                                </div>
                            </div>
                        </div>
                    </>}
                </section>
            </div>
            </>
        );
    }
}


