import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import Compteur from './compteur';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import './conference.css';
class Item extends Component
{
    constructor (props) {
        super(props); 
        this.state = {
            resrt_j :this.props.data.resrtdiff.d,
            resrt_h :this.props.data.resrtdiff.h,
            resrt_m :this.props.data.resrtdiff.i,
            resrt_s :this.props.data.resrtdiff.s,
            date_encours : this.props.data.date_encours,
        }
        setInterval(() => {
            var s = this.state.resrt_s;
            var i = this.state.resrt_m;
            var h = this.state.resrt_h;
            var j = this.state.resrt_j;
            s--;
            if(s==0 && i==0 && h==0 && j==0) this.setState({date_encours:true})
            if(s<=0){s = 59; i--;}
            if(i<=0 && this.props.data.resrtdiff.m!=0){i = 59; h--;}
            if(h<=0 && this.props.data.resrtdiff.h!=0){h = 24; j--;}
            // if(j<=0){j = 30; m--;}
            // if(m<=0){m = 12; a--;}
            this.setState({
                resrt_j :j,
                resrt_h :h,
                resrt_m :i,
                resrt_s :s,
            })
        }, 1000);
    }
    clickSousTritance=()=>{
        if(!this.props.userLogin || (this.props.link && this.props.link=="href")){
            this.props.open() 
        } 
    }
    render() {
        const {t} =this.props; 
        const url = this.props.data.url==""?"":"/"+i18next.language+"/webinaires/"+this.props.data.url
        return ( 
            <div className="col-md-6" style={{marginBottom: '20px'}}>
                <div className="wpnaw-news-overlay-main-wrap">

                    <Link className="wpnaw-link-overlay" to={url==""?false:url} onClick={this.clickSousTritance}/>     
                        <div className="wpnaw-news-image-bg" style={{}}>
                            <img onContextMenu={(e)=> e.preventDefault()} className="wpnaw-news-image" src={this.props.data.img} alt={this.props.data.title} />
                        </div>
                        <div className="wpnaw-news-grid-content">
                            <div className="wpnaw-news-date">
                                <div className='w-75'>
                                    <span className="wpnaw-news-date-d">{this.props.data.date_deb_D}</span>
                                    <span className="wpnaw-news-date-m">{this.props.data.date_deb_M}</span>
                                    <span className="wpnaw-news-date-t">{this.props.data.time.replace(':', 'h')}</span> 
                                </div>
                                <div className='w-25 blok-duree'>
                                    <i class="fa fa-clock-o" aria-hidden="true" style={{fontSize: "20px"}}></i>
                                    <span className="wpnaw-news-date-t">{this.props.data.duree}H</span> 
                                </div>
                                
                            </div>
                        </div>
                </div>
                <div className="wpnaw-news-content">
                    <Compteur data={this.props.data}/>

                    <h2 className="wpnaw-news-title">
                        <Link to={url==""?false:url} target="_self" style={{fontSize: '17px', textTransform: 'uppercase'}}><Markup markup={this.props.data.title} /></Link>
                    </h2>
                    <div className="wpnaw-news-short-content" style={{height: '40px', fontFamily: 'calibri', fontSize: '15px'}}><Markup markup={this.props.data.content} /></div>
                    <Link to={url==""?false:url} target="_self" className="readmorebtn" style={{float: 'right', color: '#e11a18'}}>{t('Lire la suite')}</Link>
                </div>
            </div>
        );
    }
}
export default withTranslation()(React.memo(Item));