import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Devis from "../../services/devis";
import { prefixe } from "../../config.json";
export default function EntrepriseModal(props) {
  const [parent, setParent] = useState(props.parent);
  const [prodId, setProdId] = useState(props.IdProd);
  const [optiondevipays, setOptiondevipays] = useState(null);
  const [showsociete, setShowsociete] = useState("d-none");
  const [minimale, setMinimale] = useState(props.minimale);
  const [loderFrome, setLoderFrome] = useState("d-none");
  const [sundDevis, setSundDevis] = useState(false);
  const [showactivite, setShowactivite] = useState("");
  const t = new Date();
  const date = ("0" + t.getDate()).slice(-2);
  const month = ("0" + (t.getMonth() + 1)).slice(-2);
  const year = t.getFullYear();
  const [time, setTime] = useState(`${year}-${month}-${date}`);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setLoderFrome("loder-from");

    Devis.registerDevieOffline(data, prodId, parent).then((res) => {
      if (res.data) {
        reset();
        setSundDevis(true);
      }
      setLoderFrome("d-none");
    });
  };
  useEffect(() => {
    Devis.getCountry().then((res) => {
      if (res.data.get) {
        var countrys = [];
        res.data.data.forEach((country) => {
          if (parseInt(country.code) == parseInt(prefixe)) {
            countrys.push(
              <option selected value={country.code}>
                {country.name}
              </option>
            );
          } else {
            countrys.push(<option value={country.code}>{country.name}</option>);
          }
        });
        setOptiondevipays(countrys);
      }
    });
  }, []);
  const chingeDeviEtes = (e) => {
    if (e.target.value == 1) {
      setShowsociete("d-none");
      setShowactivite("");
    } else {
      setShowsociete("");
      setShowactivite("d-none");
    }
  };
  return (
    <div className="connxtion_body" style={{ position: "relative" }}>
      <div className={loderFrome}>
        <span className="loading-login"></span>
      </div>
      <div className="content-ihc">
        {sundDevis ? (
          <div className="sund-form-sucsses">
            <span>
              Votre demande de participer a été enregistrée avec succès
            </span>
          </div>
        ) : (
          <></>
        )}
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <form>
          <div className="row">
            
            
            <div className="col-md-6 form-group">
              <label htmlFor="devie">Demander un Devis :</label>
              <div > 
              <input
                type="radio"
                id="devieChoice1"
                name="devie"
                value="Non"
                className="form-control"
              />
              <label for="devieChoice1">Non</label>
              
              <input
                type="radio"
                id="devieChoice2"
                name="devie"
                value="oui"
                className="form-control"
              />
              <label for="devieChoice2">Oui</label>
              </div>
              
            </div>
            
            <div className="col-md-6 form-group">
              <label htmlFor="devi_nom">Civilité * :</label>
              <select
                
                className="form-control"
                
              >
                {/* <option value="1">Un particulier</option> */}
                <option value="M">M.</option>
                <option value="Mme">
                  Mme
                </option>
              </select>
              {errors.nom ? (
                <span className="error d-block">Ce champ est obligatoire</span>
              ) : (
                <></>
              )}
            </div>
            
            <div className="col-md-6 form-group">
              <label htmlFor="devi_prenom">Nom et Prénom * :</label>
              <input
                {...register("prenom", { required: true })}
                className="form-control"
              />
              {errors.prenom ? (
                <span className="error d-block">Ce champ est obligatoire</span>
              ) : (
                <></>
              )}
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="devi_nom">Poste/Rôle occupé dans l’entreprise :</label>
              <input
                {...register("nom", { required: true })}
                className="form-control"
              />
              
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="devi_email">Email* :</label>
              <input
                type="email"
                {...register("email", { required: true })}
                className="form-control"
              />
              {errors.email ? (
                <span className="error d-block">Ce champ est obligatoire</span>
              ) : (
                <></>
              )}
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="devi_telephone">Téléphone* :</label>
              <input
                type="tel"
                {...register("telephone", { required: true, maxLength: 10 })}
                className="form-control"
              />
              {errors.telephone ? (
                <span className="error d-block">Ce champ est obligatoire</span>
              ) : (
                <></>
              )}
            </div>
            
            <div className="col-md-6 form-group">
              <label htmlFor="devi_ville">Ville* :</label>
              <input
                {...register("ville", { required: true })}
                className="form-control"
              />
              {errors.ville ? (
                <span className="error d-block">Ce champ est obligatoire</span>
              ) : (
                <></>
              )}
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="devi_adresse">Adresse* :</label>
              <input
                {...register("adresse", { required: true })}
                className="form-control"
              />
              {errors.adresse ? (
                <span className="error d-block">Ce champ est obligatoire</span>
              ) : (
                <></>
              )}
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="devi_pays">Message* :</label>
              <textarea
                className="form-control"
                {...register("message", { required: true })}
              />
              {errors.message ? (
                <span className="error d-block">Ce champ est obligatoire</span>
              ) : (
                <></>
              )}
            </div>
          </div>

          <input type="submit" className="btn btn-b2b" />
        </form>
      </div>
    </div>
  );
}
