import './style.css';
import React, { useState } from 'react';
export default function Header(props) {
    const [active, setActive] = useState(props.active);
    return (
        <div className="row informations-personnelles-professionnelles">
            <div className={"col-md-4 "+(active==1?"active":"")}>
                <div className="text-number text-number-1"><span>1<i className="arc-image" /></span></div>
                <div className="text-etap"><span>Informations <br />personnelles</span> </div>
            </div>
            <div className={"col-md-4 "+(active==2?"active":"")}>
                <div className="text-number text-number-2"><span>2<i className="arc-image" /></span></div>
                <div className="text-etap"><span>Informations <br />professionnelles</span> </div>
            </div>
            <div className={"col-md-4 "+(active==3?"active":"")}>
                <div className="text-number text-number-3"><span>3<i className="arc-image" /></span></div>
                <div className="text-etap"><span>Educations &amp; Expériences <br />professionnelles</span> </div>
            </div>
        </div>          
    );
}
