
import './detail.css';
import React, { Component } from 'react';
import Evenements from '../../services/evenements';
import { Link } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import Select from 'react-select'
import { useParams } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Pagination from "react-js-pagination";
import BtnPartage from '../Slider/btnPartage';
import i18next from 'i18next';
import ReactGA from 'react-ga';
import MetaTags from 'react-meta-tags';
import {b2bNameSite,b2bDescriptionSite} from '../../config.json';
import { withTranslation } from 'react-i18next';
import Login from '../header/loginheader';
import Modal, { closeStyle } from 'simple-react-modal';
import Auth from '../../services/Auth'
import parse from 'react-html-parser'
function Detail(props) {
    let { slug } = useParams();
    return (
        <Index slug={slug} t={props.t} />
    );
}
export default withTranslation()(Detail)

class Index extends Component {
    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        if (document.getElementById("header_b2b")) document.getElementById("header_b2b").style.display = "block";

        super(props)
        this.state = {
            slug: this.props.slug,
            data: null,
            participe: false,
            userLogin: false,
            dataUser: null,
            proposer: "",
            modaleLogin: false,
            htmlModaleLogin: <></>,
        }
        Evenements.getDetail(this.props.slug).then((res) => {
            if (res.data.get) {
                this.setState({ data: res.data.data });

            }
        });
        Auth.isLogin().then((res) => {
            if (res.data != false) {
                this.setState({
                    userLogin: true,
                    dataUser: res.data,
                    proposer: "proposer"
                });
            } else {
                this.setState({
                    userLogin: false,
                    dataUser: null,
                    proposer: ""
                });
            }
        });
    }

    openModal = () => {
        this.setState({ participe: true })
    }

    closeModal = () => {
        this.setState({ participe: false })
    }
    pad2 = (n) => {
        return (n < 10 ? '0' : '') + n;
    }
    participeCour = (e) => {
        if (this.state.userLogin) {
            var months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
            var date = new Date();
            var month = this.pad2(date.getMonth() + 1);//months (0-11)
            var day = this.pad2(date.getDate());//day (1-31)
            var year = date.getFullYear();

            var datestart = year + "-" + month + "-" + day;

            var dateStr1 = this.state.data.end_date;
            var date1 = dateStr1.split(" ");
            var dd1 = this.pad2(date1[0]);
            var mm1 = date1[1];
            var yyyy1 = date1[2];
            mm1 = mm1.toLowerCase();
            var mm2 = this.pad2(months.indexOf(mm1) + 1);


            var dateend = yyyy1 + "-" + mm2 + "-" + dd1;

            Evenements.participe(this.state.data.post_title, this.state.data.post_content, datestart, "08:00", dateend, "08:00")
                .then((res) => {
                    if (res.data.register) {
                        this.openModal();
                    }
                });
        } else {
            this.setState({
                modaleLogin: true,
                htmlModaleLogin: <Login close={this.close.bind(this)} />
            });
        }
    }
    participe = (e) => {
        if (this.state.userLogin) {
            var months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
            var dateStr = this.state.data.start_date;
            var date = dateStr.split(" ");
            var dd = this.pad2(date[0]);
            var mm = date[1];
            var yyyy = date[2];
            mm = mm.toLowerCase();
            var mm1 = this.pad2(months.indexOf(mm) + 1);


            var datestart = yyyy + "-" + mm1 + "-" + dd;

            var dateStr1 = this.state.data.end_date;
            var date1 = dateStr1.split(" ");
            var dd1 = this.pad2(date1[0]);
            var mm1 = date1[1];
            var yyyy1 = date1[2];
            mm1 = mm1.toLowerCase();
            var mm2 = this.pad2(months.indexOf(mm1) + 1);


            var dateend = yyyy1 + "-" + mm2 + "-" + dd1;

            Evenements.participe(this.state.data.post_title, this.state.data.post_content, datestart, "08:00", dateend, "08:00")
                .then((res) => {
                    if (res.data.register) {
                        this.openModal();
                    }
                });
        } else {
            this.setState({
                modaleLogin: true,
                htmlModaleLogin: <Login close={this.close.bind(this)} />
            });
        }
    }
    close = (e) => {
        this.setState({
            modaleLogin: false,
            htmlModaleLogin: <></>
        })
    }
    render() {
        const { t } = this.props
        if (this.state.data) {
            var content = "";
            
            var post_title = "";
            var url = "";
            if (i18next.language == "fr" || i18next.language == "fr-FR") {
                content = this.state.data.post_content;
                
                post_title = this.state.data.post_title;
                url=this.state.data.urlPartage;
            } else if (i18next.language == "en") {
                content = this.state.data.en_post_content;
                
                post_title = this.state.data.en_post_title;
                url=this.state.data.urlPartage.replace("/fr/", "/en/");
            } else {
                content = this.state.data.post_content;
                
                post_title = this.state.data.post_title;
                url=this.state.data.urlPartage;
            }
            return (
<>
<MetaTags>
                    <title>{this.state.data ? post_title : ""} | {b2bNameSite} | {this.state.data ? parse(content.replace(/<[^>]*>?/gm, '').substring(0, 50))+'...' : ""}</title>
                </MetaTags>

                <section id="list-places-wrapper" className="container" style={{ marginTop: 0, paddingTop: '20px', backgroundColor: 'white' }}>
                    <div className="modal-login-hadeer">
                        <Modal
                            closeOnOuterClick={true}
                            show={this.state.modaleLogin}
                            onClose={this.close.bind(this)}
                        >
                            <div className="div-login-expert">
                                <div className="connxtion_header">
                                    <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b" style={{textTransform: "uppercase"}}>{t("VEUILLEZ-VOUS CONNECTER POUR POUVOIR Participer à l'Evenements")}</h2>
                                    <div className="divbtnclose">
                                        <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                                    </div>
                                </div>
                                {this.state.htmlModaleLogin}
                            </div>
                        </Modal>
                    </div>
                    <div className="modal-login-hadeer">
                        <Modal
                            show={this.state.participe}
                            onClose={this.closeModal}

                        >
                            <div className="div-login-expert">
                                <div className="connxtion_header" style={{ display: "flex", flexDirection: "column" }}>
                                    <h2>Participation : </h2>
                                    <p>{`L'événement ${post_title} à été ajouté avec succès sur votre "agenda B2B"`}</p>
                                    <button onClick={this.closeModal}>close</button><br />
                                </div>
                            </div>
                        </Modal>
                    </div>
                    <div className="container mec-wrap clearfix col-md-12" id="mec_skin_7478">
                        <article className="mec-single-event row">
                            <div className="col-md-8">
                                <div className="mec-events-event-image"></div>
                                <div className="mec-event-content">
                                    <h1 className="mec-single-title">{post_title}</h1>
                                    <div id="block-mobile-div" />
                                    <div className="mec-single-event-description mec-events-content" style={{ textAlign: 'justify' }}>
                                        <Markup markup={content} />
                                    </div>
                                </div>
                                <br />
                                <BlockEvenes />
                                <div className="mec-events-meta-group mec-evenpost_titlets-meta-group-tags"> </div>
                            </div>
                            <div className="col-md-4">
                                <div id="block-mobile">
                                    <div className="mec-event-meta mec-color-before mec-frontbox">
                                        <img onContextMenu={(e) => e.preventDefault()} src={this.state.data.img} style={{ width: '100%' }} />
                                    </div>
                                    <br />
                                    <h3 className="mec-social-single mec-frontbox-title"></h3>
                                    <h3 className="mec-social-single mec-frontbox-title">
                                        <i className="mec-sl-calendar"></i>
                                    </h3>
                                    <h3 className="mec-date"></h3>
                                    <h3>
                                        <abbr className="mec-events-abbr">
                                            Du {this.state.data.start_date}
                                            <br />
                                            Au {this.state.data.end_date}
                                        </abbr>
                                    </h3>
                                    <div>

                                        {this.state.data.type == "encours" ? <>

                                            <button type="button" onClick={this.participeCour} className="btn-evenement">{t("Je Participe")}</button>


                                        </> : this.state.data.type == "venir" ? <>

                                            <button type="button" onClick={this.participe} className="btn-evenement"
                                            >{t("Je Participe")}</button>

                                        </> : <>
                                            <button type="button" className="btn-evenement">{t("Événements passés")} </button>
                                        </>}

                                    </div>
                                    <br />
                                </div>
                                <div className="mec-event-social mec-frontbox">
                                    <div className="mec-event-sharing">
                                        <div className="mec-links-details">
                                            <p className="pb-0 mb-0">
                                                <span style={{ fontWeight: 'bold', color: '#5d5f5e' }}>{t("PARTAGER SUR")} : </span>
                                            </p>
                                            <BtnPartage
                                                image={this.state.data.img}
                                                title={post_title}
                                                content={content}
                                                url={url} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
                </>
            );
        } else {
            return (
                <section id="list-places-wrapper" className="container" style={{ marginTop: 0, paddingTop: '20px', backgroundColor: 'white' }}>
                    <div className="container mec-wrap clearfix col-md-12" id="mec_skin_7478">
                        <article className="mec-single-event row">
                            <div className="col-md-8">
                                <div className="mec-events-event-image"></div>
                                <div className="mec-event-content">
                                    <h1 className="mec-single-title loder"></h1>
                                    <div id="block-mobile-div" />
                                    <div className="mec-single-event-description mec-events-content" style={{ textAlign: 'justify' }}>
                                        <p className="event-description-loder"></p>
                                        <p className="event-description-loder"></p>
                                        <p className="event-description-loder"></p>
                                        <p className="event-description-loder"></p>
                                        <p className="event-description-loder"></p>
                                        <p className="event-description-loder"></p>
                                        <p className="event-description-loder"></p>
                                        <p className="event-description-loder"></p>
                                        <p className="event-description-loder"></p>
                                        <p className="event-description-loder"></p>
                                        <p className="event-description-loder"></p>
                                        <p className="event-description-loder"></p>
                                        <p className="event-description-loder"></p>
                                    </div>
                                </div>
                                <br />

                                <BlockEvenes />

                            </div>
                            <div className="col-md-4">
                                <div id="block-mobile">
                                    <div className="mec-event-meta mec-color-before mec-frontbox image-loder">
                                    </div>
                                    <br />
                                    <h3 className="mec-social-single mec-frontbox-title"></h3>
                                    <h3 className="mec-social-single mec-frontbox-title">
                                        <i className="mec-sl-calendar"></i>
                                    </h3>
                                    <h3 className="mec-date"></h3>
                                    <h3>
                                        <abbr className="mec-events-abbr">
                                            <div className="span-loder">Du  <span> </span> 	</div>
                                            <div className="span-loder">Au  <span> </span> 	</div>
                                        </abbr>
                                    </h3>

                                    <br />
                                </div>
                                <div className="mec-event-social mec-frontbox">
                                    <h3 className="mec-social-single mec-frontbox-title">Partagez cet événement :</h3>
                                    <div className="mec-event-sharing partagez-loder">

                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
            );
        }

    }
}

class BlockEvenes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [<ItemEvene />, <ItemEvene />, <ItemEvene />, <ItemEvene />, <ItemEvene />, <ItemEvene />]
        }
        setTimeout(() => {
            Evenements.getEvenementsVenir(1, 0, "").then((res) => {
                if (res.data.get) {
                    var items = [];
                    res.data.data.forEach(data => {
                        items.push(<ItemEvene data={data} />);
                    });
                    this.setState({ items: items });
                }
            });
        }, 2000);

    }
    render() {
        if (this.props.data && this.state.items.length > 0) {
            return (<>
                <h3 style={{ textTransform: 'uppercase' }}>Événements à venir :</h3>
                <div className="list-single-main-item">
                    <div className=" product-list no-padding" width="100%">
                        <AliceCarousel
                            mouseTracking
                            autoPlay={true}
                            items={this.state.items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval={2000}
                            responsive={{ 1200: { items: 3 }, 1000: { items: 2 }, 800: { items: 2 }, 300: { items: 1 } }} />
                    </div>
                </div>
            </>);
        } else {
            return (<></>);
        }
    }
}
class ItemEvene extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {

        if (this.props.data) { //
            return (
                <div className="col-md-12 px-1">
                    <Link to={"/" + i18next.language + this.props.data.url}>
                        <div className="col-md-12 px-0" style={{ height: '170px' }}>
                            <img onContextMenu={(e) => e.preventDefault()} src={this.props.data.img} alt={this.props.data.title} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                            <h4 style={{ position: 'absolute', top: 0, left: '0px', color: '#fff', background: '#ed2829', fontSize: '12px', padding: '1px 5px' }}>{this.props.data.category}</h4>
                        </div>
                        <div className="col-md-12 px-1" style={{ fontFamily: 'calibri', textAlign: 'justify' }}>
                            <h4 style={{ color: '#000', textTransform: 'capitalize', fontSize: 12, lineHeight: '12px', marginTop: '12px' }}>{this.props.data.title}</h4>
                        </div>
                    </Link>
                </div>
            );
        } else {
            return (
                <div className="partenaires-item item">
                    <figure>
                        <div width="200" height="200" className="loder-image" />
                    </figure>
                    <h6><span className="loder-title"></span></h6>
                </div>
            );
        }
    }
}
