
import './addStyle.css';
import React, { Component } from 'react';
import Modal, { closeStyle } from 'simple-react-modal';
import Enteprises from '../../services/enteprises';
import Auth from '../../services/Auth';
import { Redirect } from 'react-router';
import { prefixe, b2bNameSite, lengthTel, b2bDescriptionSite } from '../../config.json';
import MetaTags from 'react-meta-tags';
import Select from 'react-select'
import i18next from 'i18next';
import ReactGA from 'react-ga'
import { withTranslation } from 'react-i18next';
class Index extends Component {
    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        super(props)
        this.state = {
            isLogin: 0
        }
        Auth.isLogin().then((res) => {
            if (res.data != false) {
                this.setState({ isLogin: 1 });
            } else {
                this.setState({ isLogin: 2 });
            }
        });
    }
    render() {
        const { t } = this.props;
        
        if (this.state.isLogin == 1) {
            return (<Add t={t} />);
        } else if (this.state.isLogin == 2) {
            return (<Redirect to={"/" + i18next.language + "/404"} />);
        } else {
            return (<></>);
        }
    }
}
export default withTranslation()(Index)
class Add extends Component {

    constructor(props) {
        window.scrollTo(0, 0);
        super(props)
        const urlParams = new URLSearchParams(window.location.search);
        //const categorie = urlParams.get('categorie');
        var optiondatefondation = [{ value: '', label: 'Sélectionner' }];
        var currentYear = new Date().getFullYear();
        for (let index = parseInt(currentYear); index >= 1900; index--) {
            optiondatefondation.push({ value: index, label: index })
        }
        var optionnbemp = [
            { value: '', label: 'Sélectionner' },
            { value: 'De 1 à 9', label: 'de 1 à 9' },
            { value: 'De 10 à 19', label: 'de 10 à 19' },
            { value: 'De 20 à 49', label: 'de 20 à 49' },
            { value: 'De 50 à 99', label: 'de 50 à 99' },
            { value: 'Plus de 100', label: 'plus de 100' },
        ];
        this.state = {
            prefixe: prefixe,
            redirect: <></>,
            submitFormAdd: false,
            dataregion: [],
            regionoptions: [{ value: '', label: '' }],
            villeoptions: [],
            entreprisePropose: [],
            htmlHorairesTravail: <HorairesTravail />,
            optiondatefondation: optiondatefondation,
            optionnbemp: optionnbemp,
            allCategories: [],
            searchCategories: [],
            horairesTravailToujoursOuvert: false,
            searchCategorie: "",
            encuredusubmite: false,
            modaleverifieNomEntreprise: false,
            htmlModaleverifieNomEntreprise: <></>,
            nom_entreprise_valide: false,
            error_nom_entreprise: false,
            error_telephon1: false,
            error_datefondation: false,
            error_nbmployes: false,
            error_categories: false,
            error_logo: false,
            error_adresse: false,
            error_gouv_ville: false,
            imageLogo: <></>,
            gouv_ville: "",
            nom_entreprise: "",
            adresse: "",
            description: "",
            activites: "",
            categories: [],
            datefondation: "",
            nbmployes: "",
            telephon1: "",
            telephon2: "",
            logo: null,
            fax: "",
            gsm: "",
            email: "",
            urlLinkedin: "",
            urlFacebook: "",
            urlSite: "",
            dirigeants: [{ "id": 1, "genre": "M", "nom_prenom": "", "fonction": "", "email": "", "tel": "" }],
            horairesTravail: ['08:00-18:00', '08:00-18:00', '08:00-18:00', '08:00-18:00', '08:00-18:00', '08:00-16:00', 'true'],
            toujoursOuvert: "",
            niveau: 1
        }

        setTimeout(() => {
            Enteprises.getRegions().then((res) => {
                if (res.data.get) {
                    //var items = [{ value: '', label: 'Sélectionner' ,selected:true}];
                    var items = [];
                    res.data.data.forEach(region => {
                        items.push({ value: region.id, label: region.name });

                    });
                    this.setState({ regionoptions: [] });
                    this.setState({
                        regionoptions: items,
                        dataregion: res.data.data
                    });
                    var items = [];
                    this.state.dataregion.forEach(region => {
                        if (region.id == 338) {
                            region.childrens.forEach(ville => {
                                items.push({ value: ville.id, label: ville.name });
                            });
                        }
                    });
                    this.setState({ villeoptions: [] });
                    this.setState({ villeoptions: items });
                }
            });
            Enteprises.getCategories(false).then((res) => {
                if (res.data.get) {
                    var allCategories = [];
                    res.data.data.forEach(categorie => {
                        if (categorie.parent) {
                            allCategories.push({ value: categorie.id, label: categorie.name });
                        }

                    });

                    this.setState({ allCategories: [] });
                    this.setState({ allCategories: allCategories });
                }
            });
        }, 1000);

    }
    changeUrlSite = (e) => {
        this.setState({ urlSite: e.target.value });
    }
    changeUrlFacebook = (e) => {
        this.setState({ urlFacebook: e.target.value });
    }
    changeUrlLinkedin = (e) => {
        this.setState({ urlLinkedin: e.target.value });
    }
    changeEmail = (e) => {
        this.setState({ email: e.target.value });
    }
    changeGSM = (e) => {
        let value = e.target.value;
        value = value.replace(/o/g, "");
        value = value.replace(/[^\d]/g, "");
        this.setState({ gsm: value });
    }
    changeFAX = (e) => {
        let value = e.target.value;
        value = value.replace(/o/g, "");
        value = value.replace(/[^\d]/g, "");
        this.setState({ fax: value });
    }
    changeTelephon2 = (e) => {
        let value = e.target.value;
        value = value.replace(/o/g, "");
        value = value.replace(/[^\d]/g, "");
        this.setState({ telephon2: value });
    }
    changeTelephon1 = (e) => {
        let value = e.target.value;
        value = value.replace(/o/g, "");
        value = value.replace(/[^\d]/g, "");
        this.setState({ telephon1: value });
        this.setState({ error_telephon1: false });
    }
    changeGouvernorat = (e) => {
        var items = [];
        this.state.dataregion.forEach(region => {
            if (region.id == e.value) {
                region.childrens.forEach(ville => {
                    items.push({ value: ville.id, label: ville.name });
                });
            }
        });
        this.setState({ villeoptions: [] });
        this.setState({ villeoptions: items });
    }
    changeVille = (e) => {
        this.setState({ error_gouv_ville: false, gouv_ville: e.value });
    }

    changeDateFondation = (e) => {
        this.setState({ datefondation: e.value, error_datefondation: false });
    }
    changeNombreEmployes = (e) => {
        this.setState({ error_nbmployes: false, nbmployes: e.value });
    }
    changeNomEntreprise = (e) => {
        this.setState({
            error_nom_entreprise: false,
            nom_entreprise_valide: false,
            nom_entreprise: e.target.value
        });

        if (e.target.value.length > 2) {
            Enteprises.Enteprisespropose(e.target.value).then((res) => {
                var entreprisePropose = [];
                if (res.data.get) {
                    res.data.data.forEach(enteprise => {
                        entreprisePropose.push(<span onClick={(e) => { this.setState({ nom_entreprise: enteprise, nom_entreprise_valide: false }); }} className='enteprisespropose'>{enteprise}</span>);
                    });
                }
                this.setState({ entreprisePropose: entreprisePropose });
            });
        }
    }
    changeAdresse = (e) => {
        this.setState({ error_adresse: false, adresse: e.target.value });
    }
    changeLogo = (e) => {
        let value = URL.createObjectURL(e.target.files[0]);
        this.setState({
            imageLogo: <img onContextMenu={(e) => e.preventDefault()} src={value} />,
            logo: e.target.files[0]
        });
        this.setState({ error_logo: false });
    }
    changeDescription = (e) => {
        this.setState({ description: e.target.value });
        this.setState({ error_description: false });
    }
    changeActivites = (e) => {
        this.setState({ activites: e.target.value });
        this.setState({ error_activites: false });
    }
    clickCategorie = (e) => {
        this.setState({ error_categories: false });
        if (this.state.categories.length < 3) {
            var categories = this.state.categories;
            this.state.allCategories.forEach(categorie => {
                if (e.target.id == categorie.value) {
                    categories.push({ value: categorie.value, label: categorie.label });
                }
            });
            this.setState({
                categories: categories,
                searchCategorie: "",
                searchCategories: []
            });
        }

    }
    removeCategorie = (e) => {
        var categories = this.state.categories;
        var newcategories = categories.filter((c) => {
            return c.value != e.target.id;
        })
        this.setState({ categories: newcategories });
    }
    changeCategorie = (e) => {
        this.setState({ error_categories: false });
        this.setState({ searchCategorie: e.target.value });
        let searcjQery = e.target.value.toLowerCase();

        if (searcjQery.length > 2) {
            var categories = this.state.allCategories;
            var displayedContacts = categories.filter((c) => {
                let searchValue = c.label.toLowerCase();
                return searchValue.indexOf(searcjQery) !== -1;
            })
            var searchCategories = [];
            displayedContacts.forEach(categorie => {
                var addedcategorie = false;
                this.state.categories.forEach(c => {
                    if (c.value == categorie.value) addedcategorie = true;
                });
                if (!addedcategorie) {
                    searchCategories.push(
                        <li onClick={this.clickCategorie} className="searched_companies li_not_clicked" id={categorie.value} name={categorie.label}>{categorie.label}</li>
                    );
                }

            });
            this.setState({ searchCategories: [] });
            this.setState({ searchCategories: searchCategories });
        } else {
            this.setState({ searchCategories: [] });
        }

    }

    addRemoveDirigeant = () => {
        var dirigeants = this.state.dirigeants;
        dirigeants = dirigeants.slice(0, -1);
        this.setState({ dirigeants: dirigeants });
    }
    addDirigeant = () => {
        var dirigeants = this.state.dirigeants;
        dirigeants.push({ "id": this.state.dirigeants.length + 1, "genre": "M", "nom_prenom": "", "fonction": "", "email": "", "tel": "" });
        this.setState({ dirigeants: dirigeants });
    }
    updateDirigeant = (dirigeant, key) => {
        var dirigeants = this.state.dirigeants;
        var newdirigeants = [];
        dirigeants.forEach(d => {
            if (d.id == key) {
                newdirigeants.push({
                    "id": key,
                    "genre": dirigeant.genre,
                    "nom_prenom": dirigeant.nom_prenom,
                    "fonction": dirigeant.fonction,
                    "email": dirigeant.email,
                    "tel": dirigeant.tel
                })
            } else {
                newdirigeants.push(d);
            }
        });
        this.setState({ dirigeants: newdirigeants });

    }
    changeHorairesTravailToujoursOuvert = (e) => {
        this.setState({ horairesTravailToujoursOuvert: e.target.checked });
        if (e.target.checked) {
            this.setState({ toujoursOuvert: "toujoursouvert" });
        } else {
            this.setState({ toujoursOuvert: "" });
        }

    }
    changeHorairesTravail = (data) => {
        if (!this.state.horairesTravailToujoursOuvert) {
            this.setState({ horairesTravail: data });
        }

    }
    verifieNomEntreprise = () => {
        if (this.state.nom_entreprise != "") {
            this.setState({ modaleverifieNomEntreprise: true });
            this.setState({ nom_entreprise_valide: false });
            Enteprises.searchEntreprises(this.state.nom_entreprise).then((res) => {
                if (res.data.get) {

                    if (res.data.data.length > 0) {
                        this.setState({ htmlModaleverifieNomEntreprise: <ModaleVerifieNomEntreprise data={res.data.data} /> });

                    } else {
                        this.setState({
                            nom_entreprise_valide: true,
                            htmlModaleverifieNomEntreprise:
                                <div class="entreprise-valid" id="entreprise_valid"><p>Ce nom d’entreprise est valide, vous pouvez commencer à remplir les informations de votre entreprise</p>
                                    <div onClick={this.close.bind(this)} class="btn btn-info">continuer</div>
                                </div>
                        });
                    }
                }

            });
        }
    }
    close = () => {
        this.setState({
            modaleverifieNomEntreprise: false,
            htmlModaleverifieNomEntreprise: <></>
        })
    }
    submitFrom = () => {
        this.setState({
            error_nom_entreprise: false
        });
        if (this.state.nom_entreprise_valide && this.state.nom_entreprise != "") {
            if (this.state.gouv_ville == "") {
                this.setState({ error_gouv_ville: true });
                document.getElementById("Gouvernorat_entreprise").scrollIntoView({ block: "end" });
                return false;
            }
            if (this.state.adresse == "") {
                this.setState({ error_adresse: true });
                document.getElementById("adresse_entrepriseb2b").focus();
                return false;
            }
            if (this.state.logo == null) {
                this.setState({ error_logo: true });
                document.getElementById("logo_entrepriseb2b").scrollIntoView({ block: "end" });
                return false;
            }
            if (this.state.description == "") {
                this.setState({ error_description: true });
                document.getElementById("description_entreprisb2b").focus();
                return false;
            }

            if (this.state.activites == "") {
                this.setState({ error_activites: true });
                document.getElementById("activites_entreprisb2b").focus();
                return false;
            }

            if (this.state.categories.length == 0) {
                this.setState({ error_categories: true });
                document.getElementById("inputsearchCategorie").focus();
                return false;
            }
            if (this.state.datefondation == "") {
                this.setState({ error_datefondation: true });
                document.getElementById("datefondation_entreprisb2b").scrollIntoView({ block: "end" });
                return false;
            }
            if (this.state.nbmployes == "") {
                this.setState({ error_nbmployes: true });
                document.getElementById("nbmployes_entreprisb2b").scrollIntoView({ block: "end" });
                return false;
            }

            if (this.state.telephon1 == "") {
                this.setState({ error_telephon1: true });
                document.getElementById("telephon1_entreprisb2b").focus();
                return false;
            }

            var niveau = this.state.niveau;
            if (this.state.activites != "" && this.state.description != "" && this.state.email != "") {
                this.setState({ niveau: 2 });
                niveau = 2;
            }
            var categories = [];
            this.state.categories.forEach(categorie => {
                categories.push(categorie.value);
            });
            this.setState({ encuredusubmite: true });
            const data = new FormData();
            data.append("token", localStorage.getItem("tokenb2b"));
            data.append("nom_entreprise", this.state.nom_entreprise);
            data.append("gouv_ville", this.state.gouv_ville);
            data.append("adresse", this.state.adresse);
            data.append("description", this.state.description);
            data.append("activites", this.state.activites);
            data.append("categories", categories);
            data.append("datefondation", this.state.datefondation);
            data.append("nbmployes", this.state.nbmployes);
            data.append("telephon1", this.state.telephon1);
            data.append("telephon2", this.state.telephon2);
            data.append("fax", this.state.fax);
            data.append("gsm", this.state.gsm);
            data.append("email", this.state.email);
            data.append("urlSite", this.state.urlSite);
            data.append("urlFacebook", this.state.urlFacebook);
            data.append("urlLinkedin", this.state.urlLinkedin);
            data.append("niveau", niveau);
            var i = 0;
            if (this.state.dirigeants[0].nom_prenom != "") {
                this.state.dirigeants.forEach(d => {
                    i++;
                    data.append("dirigeant" + i, [d.genre, d.nom_prenom, d.fonction, d.email, d.tel]);
                });
            }
            data.append("dirigeantsLangth", i);
            data.append("horairesTravail", this.state.horairesTravail);
            data.append("toujoursOuvert", this.state.toujoursOuvert);
            data.append("logo", this.state.logo);
            this.setState({ submitFormAdd: true });
            Auth.addEnteprise(data).then((res) => {
                if (res.data.add) {
                    const long = (i18next.language == "fr-FR" || i18next.language == "fr") ? "fr" : "en"
                    this.setState({ redirect: <Redirect to={"/" + long + "/ajouter-entreprise-avec-succes"} /> });
                } else {
                    console.log(res);
                    alert("error add enteprise");
                }
                this.setState({
                    submitFormAdd: false,
                    encuredusubmite: false
                });
            })

        } else {
            this.setState({ error_nom_entreprise: true });
            document.getElementById("nom_entreprise").scrollIntoView(false);
        }
    }
    render() {
        const { t } = this.props;
        var categories = [];
        this.state.categories.forEach(c => {
            categories.push(<span class="span_destinataire">{c.label}<b id={c.value} onClick={this.removeCategorie}>X</b></span>)
        });
        var dirigeants = [];
        this.state.dirigeants.forEach((dirigeant) => {
            dirigeants.push(<Dirigeant dirigeant={dirigeant} update={this.updateDirigeant} />)
        });
        return (
            <>
                {this.state.redirect}
                <MetaTags>
                    <title>{t('Ajouter Entreprise')} | {b2bNameSite} | {b2bDescriptionSite}</title>
                </MetaTags>
                <div className="modal-verifie-nom-entreprise">
                    <Modal
                        closeOnOuterClick={true}
                        show={this.state.modaleverifieNomEntreprise}
                        onClose={this.close.bind(this)}
                    >
                        <div className="ModaleVerifieNomEntreprise">
                            <div className="div-close-modale">
                                <div onClick={this.close.bind(this)} className="close-modale">X</div>
                            </div>
                            <div className="content-modale">
                                {this.state.htmlModaleverifieNomEntreprise}
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className="page-content">
                    <section id="list-places-wrapper" style={{ backgroundColor: '#fff', margin: 0 }}>
                        {this.state.submitFormAdd ? <div className='lodesubmitform'></div> : <></>}
                        <div className="container" style={{ backgroundColor: '#fff' }}>
                            <form id="regForm" method="post" action encType="multipart/form-data" className="row px-0">
                                <div className="col-md-6" style={{ display: 'flex' }}>
                                    <div style={{ border: '1px solid #e11a18', padding: '20px' }}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label style={{ fontWeight: 'normal', color: '#969696' }}>{t("Nom de l'entreprise")} <span className="star">*</span> :</label>
                                                {/* <p className="text-name-company">Veuillez <strong>VÉRIFIER</strong>  l’existence du nom de votre entreprise. Vous devez <strong>AJOUTER</strong> un nom qui lui est propre, et qui n’est pas déjà enregistré sur « <strong>{b2bNameSite}</strong> ».</p>
                                            <p className="text-name-company">Sinon veuillez nous <strong>RÉCLAMER</strong> que votre entreprise est enregistrée sur « <strong>{b2bNameSite}</strong> », et que vous n’êtes pas à l’origine de cet enregistrement.</p> */}
                                                <div className="row mb-3 mt-3">
                                                    <div className="col-md-8 mt-1">
                                                        <input autoFocus required type="text" placeholder={t('Veuillez saisir le Nom de votre entreprise')} value={this.state.nom_entreprise} onChange={this.changeNomEntreprise} id="nom_entreprise" className="circleinput text-field input-item input" />
                                                        {this.state.entreprisePropose.length > 0 ? <div className='listeenteprisespropose'>{this.state.entreprisePropose}</div> : <></>}
                                                    </div>
                                                    <div className="col-md-4 mt-1 d-flex justify-content-center align-items-center">
                                                        <button className={this.state.nom_entreprise_valide ? "btn-verifie-existence-true" : "btn-verifie-existence-false"} type="button" onClick={this.verifieNomEntreprise} >Je vérifie l'existence </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.error_nom_entreprise ?
                                                <p className="col-md-12 mb-0">
                                                    <label className="error" id="verifier_existence_error" style={{ display: "block" }}>
                                                        {t("Le champ « Nom Entreprise » est obligatoire")} <br />
                                                        {t("Vérifiez l'existence du nom de l'entreprise en cliquant sur le bouton « Je vérifie l'existence » ")}
                                                    </label>
                                                </p>
                                                : <></>}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6" >
                                                <label style={{ fontWeight: 'normal', color: '#969696' }}> {t("Région")} <span className="star">*</span> :</label>
                                                <input type='hidden' id="Gouvernorat_entreprise" />
                                                <Select options={this.state.regionoptions} placeholder="Sélectionner" className="circleinput select2" onChange={this.changeGouvernorat} />
                                                {this.state.error_gouv_ville ?
                                                    <label className="error text-transform-unset" id="verifier_existence_error" style={{ display: "block" }}>
                                                        {t("Le champ « Région » est obligatoire")}
                                                    </label> : <></>}
                                            </div>
                                            <div className="col-md-6">
                                                <label style={{ fontWeight: 'normal', color: '#969696' }}> {t("Ville")} <span className="star">*</span> :</label>
                                                <Select options={this.state.villeoptions} placeholder={t("Sélectionner")} className="circleinput select2" onChange={this.changeVille} />
                                                {this.state.error_gouv_ville ?
                                                    <label className="error text-transform-unset" id="verifier_existence_error" style={{ display: "block" }}>
                                                        {t("Le champ « Ville » est obligatoire")}
                                                    </label> : <></>}
                                            </div>
                                        </div>
                                        <div className="form-field icon-input mt-3" >
                                            <label style={{ fontWeight: 'normal', color: '#969696' }}>Adresse exacte <span className="star">*</span> :</label>
                                            <input type="text" placeholder={t('Veuillez saisir votre Adresse exacte')} value={this.state.adresse} id="adresse_entrepriseb2b" className="circleinput input input1" onChange={this.changeAdresse} required />
                                            {this.state.error_adresse ?
                                                <label className="error text-transform-unset" id="verifier_existence_error" style={{ display: "block" }}>
                                                    {t("Le champ « Adresse » est obligatoire")}
                                                </label> : <></>}
                                        </div>
                                        <div className="mb-0 mt-3" >
                                            <label style={{ fontWeight: 'normal', color: '#969696' }}>Logo <span className="star">*</span> :</label>
                                            {this.state.error_logo ?
                                                <label className="error text-transform-unset" id="verifier_existence_error" style={{ display: "block" }}>
                                                    {t("Le champ « Logo » est obligatoire")}
                                                </label> : <></>}
                                            <input type='hidden' id="logo_entrepriseb2b" />
                                            <div style={{ display: 'flex' }}>
                                                <div className="input-logo">
                                                    <label htmlFor="logo" style={{ textAlign: 'center' }}>
                                                        <img onContextMenu={(e) => e.preventDefault()} className="btn img-b2b-logo" src="https://www.b2b.tn/upload/image/ajouter-logo.png" />
                                                        <span className="btn btn-b2b-logo">{t("Téléchargez votre logo")}</span>
                                                    </label>
                                                    <input type="file" name="file" required id="logo" accept="image/*" onChange={this.changeLogo} />
                                                </div>
                                                <div className="input-logo mb-0" id="input-logo">{this.state.imageLogo}</div>
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <label style={{ fontWeight: 'normal', color: '#969696' }}>Description : <span className="star">*</span></label>
                                            <textarea id="description_entreprisb2b" placeholder={t('Veuillez ajouter une description pour votre entreprise')} onChange={this.changeDescription} value={this.state.description} className="circleinput text-field input-item input" style={{ marginTop: '0px', marginBottom: '0px', height: '103px', width: '100%', border: '1px solid #aaaaaa' }} />
                                            {this.state.error_description ?
                                                <label className="error text-transform-unset" id="verifier_existence_error" style={{ display: "block" }}>
                                                    {t("Le champ « Déscription » est obligatoire")}
                                                </label> : <></>}
                                        </div>
                                        <div >
                                            <label className='mt-3' style={{ fontWeight: 'normal', color: '#969696' }}>Activités : <span className="star">*</span></label>
                                            <textarea id="activites_entreprisb2b" placeholder={t('Veuillez ajouter vos activités')} onChange={this.changeActivites} value={this.state.activites} className="circleinput text-field input-item input" style={{ marginTop: '0px', marginBottom: '0px', height: '103px', width: '100%', border: '1px solid #aaaaaa' }} />
                                            {this.state.error_activites ?
                                                <label className="error text-transform-unset" id="verifier_existence_error" style={{ display: "block" }}>
                                                    {t("Le champ « Activités » est obligatoire")}
                                                </label> : <></>}
                                        </div>
                                        <div >
                                            <div className='mt-3' id="destinataires_container" align="center">
                                                {categories}
                                            </div>
                                            {t("Catégorie (max 03 catégories)")} : <span className="star">*</span>

                                            {categories.length === 3 ?
                                                <input disabled placeholder={t('Vous avez choisis 3 catégories')} value={t('Vous avez atteint le nombre maximum de catégories')} id="inputsearchCategorie" className="form-control input" type="text" autoComplete="off" />
                                                :
                                                <input placeholder={t('Veuillez choisir vos catégories (3 catégories max)')} id="inputsearchCategorie" onChange={this.changeCategorie} value={this.state.searchCategorie} className="form-control input" type="text" autoComplete="off" />
                                            }
                                            <label id="destinataire-error" className="error">{t("Catégorie (min 1 catégories)")} ! </label>
                                            <div id="responsecontainer" style={{ maxHeight: '150px', overflow: 'auto' }}>
                                                {this.state.searchCategories}
                                            </div>
                                            {this.state.error_categories ?
                                                <label className="error text-transform-unset" id="verifier_existence_error" style={{ display: "block" }}>
                                                    {t("Le champ « Catégories » est obligatoire")}
                                                </label> : <></>}
                                        </div>
                                        <div className="row">
                                            <p className="col-md-6" >
                                                <label style={{ fontWeight: 'normal', color: '#969696' }}>{t("Année de création")} <span className="star">*</span> :</label>
                                                <Select options={this.state.optiondatefondation} id="datefondation_entreprisb2b" placeholder="Sélectionner" className="select2" onChange={this.changeDateFondation} />
                                                {this.state.error_datefondation ?
                                                    <label className="error text-transform-unset" id="verifier_existence_error" style={{ display: "block" }}>
                                                        {t("Le champ « Année de création » est obligatoire")}
                                                    </label> : <></>}
                                            </p>
                                            <p className="col-md-6" >
                                                <label style={{ fontWeight: 'normal', color: '#969696' }}>{t("Nombre d'employés")} <span className="star">*</span> :</label>
                                                <Select options={this.state.optionnbemp} id="nbmployes_entreprisb2b" placeholder={t("Indéterminé")} className="select2" onChange={this.changeNombreEmployes} />
                                                {this.state.error_nbmployes ?
                                                    <label className="error text-transform-unset" id="verifier_existence_error" style={{ display: "block" }}>
                                                       {t("Le champ « Nombre d’employés » est obligatoire")}
                                                    </label> : <></>}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" style={{ display: 'flex' }}>
                                    <div style={{ border: '1px solid #e11a18', padding: '20px 20px 17px 20px' }}>
                                        <div className="row">
                                            <div className="col-md-6" >
                                                <label style={{ fontWeight: 'normal', color: '#969696' }}>{t("Tél")} 1 <span className="star">*</span> :</label>
                                                <div className="content-input-tel">
                                                    <span className="prefixe">{this.state.prefixe}</span>
                                                    <span className="input-tel">
                                                        <input type="tel" placeholder={t('Saisissez votre num tél')} id="telephon1_entreprisb2b" className="text-field input-item input" maxLength={lengthTel} pattern="[0-9]*" required onChange={this.changeTelephon1} value={this.state.telephon1} style={{ height: "41px" }} />
                                                    </span>
                                                </div>
                                                {this.state.error_telephon1 ?
                                                    <label className="error text-transform-unset" id="verifier_existence_error" style={{ display: "block" }}>
                                                        Le champ « Tel1 » est obligatoire
                                                    </label> : <></>}
                                            </div>
                                            <div className="col-md-6">
                                                <label style={{ fontWeight: 'normal', color: '#969696' }}>{t("Tél")} 2  :</label>
                                                <div className="content-input-tel">
                                                    <span className="prefixe">{this.state.prefixe}</span>
                                                    <span className="input-tel">
                                                        <input type="tel" placeholder={t('Saisissez votre num tél')} className="text-field input-item input" maxLength={lengthTel} pattern="[0-9]*" onChange={this.changeTelephon2} value={this.state.telephon2} style={{ height: "41px" }} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><label style={{ fontWeight: 'normal', color: '#969696' }}>{t("Fax")} :</label>
                                                <div className="content-input-tel">
                                                    <span className="prefixe">{this.state.prefixe}</span>
                                                    <span className="input-tel">
                                                        <input type="tel" placeholder={t('Saisissez votre num Fax')} className="text-field input-item input" maxLength={lengthTel} pattern="[0-9]*" onChange={this.changeFAX} value={this.state.fax} style={{ height: "41px" }} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-6"><label style={{ fontWeight: 'normal', color: '#969696' }}>{t("GSM")} :</label>
                                                <div className="content-input-tel">
                                                    <span className="prefixe">{this.state.prefixe}</span>
                                                    <span className="input-tel">
                                                        <input type="tel" placeholder={t('Saisissez votre num GSM')} className="circleinput text-field input-item input" maxLength={lengthTel} onChange={this.changeGSM} value={this.state.gsm} style={{ height: "41px" }} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label style={{ fontWeight: 'normal', color: '#969696' }}>{t("Email commercial")} :</label>
                                                <input type="email" placeholder={t('Veuillez saisir votre adresse E-mail')} className="circleinput text-field input-item input" onChange={this.changeEmail} value={this.state.email} />
                                            </div>
                                            <div className="col-md-6"><label style={{ fontWeight: 'normal', color: '#969696' }}>Site web :</label>
                                                <input type="text" placeholder={t('Veuillez saisir le lien de votre Site Web')} className="text-field input-item is_url" onChange={this.changeUrlSite} value={this.state.urlSite} />
                                            </div>
                                        </div>
                                        <div>
                                            <label style={{ fontWeight: 'normal', color: '#969696' }}>{t("Lien Page Facebook")} :</label>
                                            <input type="text" placeholder={t('Veuillez saisir le lien de votre page sur Facebook')} className="text-field input-item is_url" onChange={this.changeUrlFacebook} value={this.state.urlFacebook} />
                                        </div>
                                        <div>
                                            <label style={{ fontWeight: 'normal', color: '#969696' }}>{t("Lien Page Linkedin")} :</label>
                                            <input type="text" placeholder={t('Veuillez saisir le lien de votre page sur LinkedIn')} className="text-field input-item is_url" onChange={this.changeUrlLinkedin} value={this.state.urlLinkedin} />
                                        </div>
                                        <div>
                                            <label style={{ fontWeight: 'normal', color: '#969696' }}>{t("Dirigeants")}</label>
                                            {dirigeants}
                                            <div className="col-md-12 col-sm-12 pl-0" style={{ marginBottom: '15px' }}>
                                                <span onClick={this.addDirigeant} className="btn btn-b2b rounded-0">{t("Ajouter")}</span>
                                                {this.state.dirigeants.length > 1 ? <span onClick={this.addRemoveDirigeant} className="btn btn-b2b rounded-0 ml-2" >Supprimer</span> : <></>}
                                            </div>
                                        </div>

                                        <p style={{ marginBottom: '12px' }}>{t("Horaires de Travail")} :</p>
                                        <input type="checkbox" name="allwaysopen" id="allwaysopen" onChange={this.changeHorairesTravailToujoursOuvert} /><label for="allwaysopen" className="impu-form-label-remember">{t("Toujours Ouvert")}</label><br />
                                        {this.state.horairesTravailToujoursOuvert ? <></> : <HorairesTravail upadte={this.changeHorairesTravail} data={this.state.horairesTravail} />}
                                    </div>
                                </div>
                                <div style={{ padding: '30px' }} className="col-md-12">
                                    {!this.state.encuredusubmite ?
                                        <div style={{ float: 'right' }}>
                                            <button type="button" onClick={this.submitFrom} className="btn btn-b2b" name="ok">{t("Valider")}</button>
                                        </div>
                                        : <div style={{ float: 'right' }}>
                                            <button type="button" className="btn btn-b2b" name="ok"><span className='loading-login d-block'></span></button>
                                        </div>}
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}



class Dirigeant extends Component {

    constructor(props) {
        super(props)
        this.state = {
            genreoptions: [{ value: 'M', label: 'M' }, { value: 'Mme', label: 'Mme' }, { value: 'Melle', label: 'Melle' }],
            genrevalue: { value: 'M', label: 'M' },
            id: props.dirigeant.id,
            genre: props.dirigeant.genre,
            nom_prenom: props.dirigeant.nom_prenom,
            fonction: props.dirigeant.fonction,
            email: props.dirigeant.email,
            tel: props.dirigeant.tel,
        }
    }

    changeNomPrenom = (e) => {
        this.setState({ nom_prenom: e.target.value });
        this.props.update({
            "genre": this.state.genre,
            "nom_prenom": e.target.value,
            "fonction": this.state.fonction,
            "email": this.state.email,
            "tel": this.state.tel
        }, this.state.id);
    }
    changeFonction = (e) => {
        this.setState({ fonction: e.target.value });
        this.props.update({
            "genre": this.state.genre,
            "nom_prenom": this.state.nom_prenom,
            "fonction": e.target.value,
            "email": this.state.email,
            "tel": this.state.tel
        }, this.state.id);
    }
    changeEmail = (e) => {
        this.setState({ email: e.target.value });
        this.props.update({
            "genre": this.state.genre,
            "nom_prenom": this.state.nom_prenom,
            "fonction": this.state.fonction,
            "email": e.target.value,
            "tel": this.state.tel
        }, this.state.id);
    }
    changeTel = (e) => {
        let value = e.target.value;
        value = value.replace(/o/g, "");
        value = value.replace(/[^\d]/g, "");
        this.setState({ tel: value });
        this.props.update({
            "genre": this.state.genre,
            "nom_prenom": this.state.nom_prenom,
            "fonction": this.state.fonction,
            "email": this.state.email,
            "tel": value
        }, this.state.id);
    }
    changeGenre = (e) => {
        let value = e.value;
        if (value == "M") {
            this.setState({ genrevalue: { value: 'M', label: 'M' } });
        } else if (value == "Mme") {
            this.setState({ genrevalue: { value: 'Mme', label: 'Mme' } });
        } else if (value == "Melle") {
            this.setState({ genrevalue: { value: 'Melle', label: 'Melle' } });
        } else {
            this.setState({ genrevalue: { value: 'M', label: 'M' } });
        }
        this.setState({ genre: value });
        this.props.update({
            "genre": value,
            "nom_prenom": this.state.nom_prenom,
            "fonction": this.state.fonction,
            "email": this.state.email,
            "tel": this.state.tel
        }, this.state.id);
    }
    render() {
        return (
            <div id="dirigeants_div_inner">
                <div className="col-md-12 m-0 p-0 row">
                    <div className="col-md-3 col-sm-3 pl-0">
                        <Select options={this.state.genreoptions} value={this.state.genrevalue} className="select2" onChange={this.changeGenre} />
                    </div>
                    <div className="col-md-5 col-sm-5" style={{ marginBottom: '10px', padding: "0px 5px" }}>
                        <input type="text" style={{ height: '40px' }} placeholder="Nom et Prénom" value={this.state.nom_prenom} onChange={this.changeNomPrenom} />
                    </div>
                    <div className="col-md-4 col-sm-4" style={{ marginBottom: '10px', padding: 0 }}>
                        <input type="text" style={{ height: '40px' }} placeholder="Fonction" value={this.state.fonction} onChange={this.changeFonction} />
                    </div>
                    <div className="col-md-3 col-sm-3" />
                    <div className="col-md-5 col-sm-5" style={{ marginBottom: '10px', padding: "0px 5px" }}>
                        <input style={{ height: '40px' }} placeholder="Email" type="Email" value={this.state.email} onChange={this.changeEmail} />
                    </div>
                    <div className="col-md-4 col-sm-4" style={{ marginBottom: '10px', padding: 0 }}>
                        <input placeholder="Téléphone" style={{ height: '40px' }} type="tel" maxLength={lengthTel} value={this.state.tel} onChange={this.changeTel} />
                    </div>
                </div>
            </div>
        )
    }
}

class HorairesTravail extends Component {

    constructor(props) {
        super(props)
        var horairesTravail = ["08:00-18:00", "08:00-18:00", "08:00-18:00", "08:00-18:00", "08:00-18:00", "08:00-18:00", "08:00-18:00"];
        if (props.data && props.data.length == 7) {
            horairesTravail = props.data;
        }
        this.state = {
            horairesTravail: horairesTravail
        }
    }
    updateHorairesTravail = (data, id) => {
        var horairesTravail = this.state.horairesTravail;
        horairesTravail[id] = (data.ferme ? "true" : data.timeStart + "-" + data.timeEnd);
        this.setState({ horairesTravail: horairesTravail });
        this.props.upadte(horairesTravail);
    }
    render() {
        return (
            <div id="divopen">
                <ItemHorairesTravail joure="Lundi" id={0} update={this.updateHorairesTravail} data={this.state.horairesTravail[0]} />
                <ItemHorairesTravail joure="Mardi" id={1} update={this.updateHorairesTravail} data={this.state.horairesTravail[1]} />
                <ItemHorairesTravail joure="Mercredi" id={2} update={this.updateHorairesTravail} data={this.state.horairesTravail[2]} />
                <ItemHorairesTravail joure="Jeudi" id={3} update={this.updateHorairesTravail} data={this.state.horairesTravail[3]} />
                <ItemHorairesTravail joure="Vendredi" id={4} update={this.updateHorairesTravail} data={this.state.horairesTravail[4]} />
                <ItemHorairesTravail joure="Samedi" id={5} update={this.updateHorairesTravail} data={this.state.horairesTravail[5]} />
                <ItemHorairesTravail joure="Dimanche" id={6} update={this.updateHorairesTravail} data={this.state.horairesTravail[6]} />
            </div>
        )
    }
}

class ItemHorairesTravail extends Component {

    constructor(props) {
        super(props)
        var ferme = false;
        var disabled = "";
        var timeStart = "08:00";
        var timeEnd = "18:00";

        if (props.data == "true") {
            ferme = true;
            disabled = "disabled";
        } else {
            var time = props.data.split('-');
            timeStart = time[0];
            timeEnd = time[1];
            console.log('time:');
            console.log(time);
            console.log(timeStart);
            console.log(timeEnd);
        }
        this.state = {
            options: [
                { value: '00:00', label: '00:00' },
                { value: '00:30', label: '00:30' },
                { value: '01:00', label: '01:00' },
                { value: '01:30', label: '01:30' },
                { value: '02:00', label: '02:00' },
                { value: '02:30', label: '02:30' },
                { value: '03:00', label: '03:00' },
                { value: '03:30', label: '03:30' },
                { value: '04:00', label: '04:00' },
                { value: '04:30', label: '04:30' },
                { value: '05:00', label: '05:00' },
                { value: '05:30', label: '05:30' },
                { value: '06:00', label: '06:00' },
                { value: '06:30', label: '06:30' },
                { value: '07:00', label: '07:00' },
                { value: '07:30', label: '07:30' },
                { value: '08:00', label: '08:00' },
                { value: '08:30', label: '08:30' },
                { value: '09:00', label: '09:00' },
                { value: '09:30', label: '09:30' },
                { value: '10:00', label: '10:00' },
                { value: '10:30', label: '10:30' },
                { value: '11:00', label: '11:00' },
                { value: '11:30', label: '11:30' },
                { value: '12:00', label: '12:00' },
                { value: '12:30', label: '12:30' },
                { value: '13:00', label: '13:00' },
                { value: '13:30', label: '13:30' },
                { value: '14:00', label: '14:00' },
                { value: '14:30', label: '14:30' },
                { value: '15:00', label: '15:00' },
                { value: '15:30', label: '15:30' },
                { value: '16:00', label: '16:00' },
                { value: '16:30', label: '16:30' },
                { value: '17:00', label: '17:00' },
                { value: '17:30', label: '17:30' },
                { value: '18:00', label: '18:00' },
                { value: '18:30', label: '18:30' },
                { value: '19:00', label: '19:00' },
                { value: '19:30', label: '19:30' },
                { value: '20:00', label: '20:00' },
                { value: '20:30', label: '20:30' },
                { value: '21:00', label: '21:00' },
                { value: '21:30', label: '21:30' },
                { value: '22:00', label: '22:00' },
                { value: '22:30', label: '22:30' },
                { value: '23:00', label: '23:00' },
                { value: '23:30', label: '23:30' },
            ],
            id: props.id,
            disabled: disabled,
            timeStart: timeStart,
            timeEnd: timeEnd,
            ferme: ferme
        }
    }
    changeFerme = (e) => {
        if (e.target.checked) {
            this.setState({
                disabled: "disabled",
                ferme: true
            });
        } else {
            this.setState({
                disabled: "",
                ferme: false
            });
        }
        this.updatedata();
    }
    changeTimeEnd = (e) => {
        this.setState({ timeEnd: e.target.value });
        this.updatedata();
    }
    changeTimeStart = (e) => {
        this.setState({ timeStart: e.target.value });
        this.updatedata();
    }
    updatedata() {
        setTimeout(() => {
            this.props.update({
                timeStart: this.state.timeStart,
                timeEnd: this.state.timeEnd,
                ferme: this.state.ferme
            }, this.state.id);
        }, 10);
    }
    render() {
        return (
            <div className="divdayshorairestravail">
                <label id="days">{this.props.joure}</label>
                DU<select onChange={this.changeTimeStart} disabled={this.state.disabled}>{this.state.options.map(option => <option value={option.value} selected={option.value == this.state.timeStart ? "selected" : ""} >{option.label}</option>)} </select>
                AU<select onChange={this.changeTimeEnd} disabled={this.state.disabled}>{this.state.options.map(option => <option value={option.value} selected={option.value == this.state.timeEnd ? "selected" : ""} >{option.label}</option>)} </select>
                <input type="checkbox" onChange={this.changeFerme} id={"id" + this.props.joure} checked={this.state.ferme ? "checked" : ""} /><label for={"id" + this.props.joure} className="impu-form-label-remember">Fermé</label>
            </div>

        )
    }
}
class ModaleVerifieNomEntreprise extends Component {

    constructor(props) {
        super(props)
        this.state = {
            reclamation: false,
            id_reclamation: null,
            telephone: "",
            message: "",
            file: null,
            error: false,
            sundReclamation: false,
            textError: ""
        }
    }
    reclamation = (id) => {
        this.setState({
            reclamation: true,
            id_reclamation: id
        });
    }
    annulerReclamation = (id) => {
        this.setState({
            reclamation: false,
            id_reclamation: null
        });
    }
    changeMessage = (e) => {
        this.setState({ message: e.target.value });
        this.setState({
            error: false,
            textError: ""
        });
    }
    changeTelephone = (e) => {
        let value = e.target.value;
        value = value.replace(/o/g, "");
        value = value.replace(/[^\d]/g, "");
        this.setState({ telephone: value });
        this.setState({
            error: false,
            textError: ""
        });
        return false;
    }
    changeFile = (e) => {
        this.setState({ file: e.target.files[0] });
        this.setState({
            error: false,
            textError: ""
        });
    }
    submitReclamation = (e) => {
        this.setState({
            error: false,
            textError: ""
        });
        if (this.state.telephone == "") {
            this.setState({
                error: true,
                textError: "Téléphone est obligatoire"
            });
            return false;
        }
        if (this.state.message == "") {
            this.setState({
                error: true,
                textError: "Message est obligatoire"
            });
            return false;
        }
        if (this.state.file == null) {
            this.setState({
                error: true,
                textError: "Piece justificativ est obligatoire"
            });
            return false;
        }
        Enteprises.reclamation(this.state.telephone, this.state.message, this.state.file, this.state.id_reclamation).then((res) => {
            if (res.data.reclamation) {
                this.setState({ sundReclamation: true });
            }
        })
    }
    render() {
        var items = [];
        this.props.data.forEach(element => {
            items.push(<ItemVerifieNomEntreprise entreprise={element} Reclamation={this.reclamation} />);
        });
        return (
            <>
                <div className="info-form-addentreprise">
                    <p> Il existe des entreprises ayant le même nom que celui de votre entreprise, si vous êtes à l’origine de cette action veuillez juste <strong>VOUS-CONNECTER</strong>  et GÉRER librement votre entreprise, sinon veuillez nous <strong>RÉCLAMER</strong> que votre entreprise est enregistrée sur « <strong>{b2bNameSite}</strong> », et que vous n’êtes pas à l’origine de cet enregistrement. </p>
                </div>
                {this.state.reclamation ?
                    <div id="form_claim">
                        <form className="w-100 row ml-1" >
                            {this.state.sundReclamation ? <>
                                <p className="col-md-12 reclamation-succes">Réclamation envoyée avec succès</p>
                            </> : <>
                                <p className="col-md-12">
                                    <label style={{ fontWeight: 'normal', color: '#969696' }}>Téléphone : {prefixe}</label>
                                    <input type="tel" className="text-field input-item input" value={this.state.telephone} onChange={this.changeTelephone} maxLength={lengthTel} />
                                </p>
                                <p className="col-md-12">
                                    <label style={{ fontWeight: 'normal', color: '#969696' }}>Message : </label>
                                    <textarea className="input-logo w-100" onChange={this.changeMessage} defaultValue={this.state.message} />
                                </p>
                                <p className="col-md-12">
                                    <label style={{ fontWeight: 'normal', color: '#969696' }}>Piece justificativ (une facture de vente, une facture d’achat, un bulletin de paie, un relevé bancaire, un listing informatique…): </label>
                                    <input type="file" className="text-field input-item input" onChange={this.changeFile} />
                                </p>
                                {this.state.error ? <div className="col-md-12"><span id="error">{this.state.textError}</span></div> : <></>}
                                <div className="col-md-6">
                                    <div style={{ float: 'right' }}>
                                        <input type="hidden" name="claim_id" id="claim_id" />
                                        <button type="button" className="btn btn-b2b" onClick={this.submitReclamation}>Valider</button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div style={{ float: 'left' }}>
                                        <button type="button" onClick={this.annulerReclamation} className="btn btn-b2b">Annuler</button>
                                    </div>
                                </div>
                            </>}
                        </form>
                    </div>
                    :
                    <ul className="list-places list-posts row" style={{ listStyle: "none" }}>
                        {items}
                    </ul>
                }
            </>
        )
    }
}

class ItemVerifieNomEntreprise extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <li className="place-item post-1809 place type-place status-publish has-post-thumbnail hentry place_category-industrie location-tunis col-md-3 col-xs-6" style={{ paddingLeft: '0px', paddingRight: '11px' }}>
                <div className="place-wrapper" style={{ backgroundColor: '#ffffff', border: '1px solid #b8c7c8', boxShadow: 'none', height: '90%' }}>
                    <a target="_blank" href={this.props.entreprise.permalink} title={this.props.entreprise.title}>
                        <h2 className="title-place notranslate">
                            {this.props.entreprise.title}
                        </h2>
                    </a>
                    <div className="hidden-img">
                        <a href={this.props.entreprise.permalink} className="img-place" title={this.props.entreprise.title}>
                            <img onContextMenu={(e) => e.preventDefault()} className="lazy" src={this.props.entreprise.image} style={{ display: 'inline' }} />
                        </a>
                    </div>
                    <div className="place-detail-wrapper details-list" style={{ height: 'auto' }}>
                        <span className="address-place" adresse itemProp="addressLocality">
                            <i className="fa fa-phone" />
                            {this.props.entreprise.fixe != "" ? "Tél : " + prefixe + " " + this.props.entreprise.fixe : "GSM : " + prefixe + " " + this.props.entreprise.gsm}
                        </span>
                        <span className="address-place" adresse itemProp="addressLocality">
                            <i className="fa fa-map-marker" />
                            {this.props.entreprise.adresse}
                        </span>
                        <span className="address-place" adresse itemProp="addressLocality">
                            <i className="fa fa-newspaper-o" />
                            {this.props.entreprise.products}produits
                        </span>
                    </div>
                </div>
                <div className="btn_entreprise_author">
                    {this.props.entreprise.author > 0 ?
                        <p onClick={() => { this.props.Reclamation(this.props.entreprise.ID) }}>Cette entreprise m’appartient, je veux Gérer mon entreprise</p>
                        :
                        <p onClick={() => { this.props.Reclamation(this.props.entreprise.ID) }}>Réclamation</p>}
                </div>
            </li>
        )
    }
}

