import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import DataHome from '../../services/home';
import { Link,NavLink } from 'react-router-dom';
import i18next from 'i18next';
import './style.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import { withTranslation } from 'react-i18next';
import { Markup } from 'react-render-markup';

class ProduitsEnVedette extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            items : [<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>]
        }
        DataHome.getProduitsEnVedette().then((res)=>{
            if(res.data.get){
                var items=[];
                res.data.data.forEach(produit => {
                    items.push(<Item url={produit.url} img={produit.img} title={produit.title}/>);
                });
                setTimeout(() => {
                    this.setState({items:[]});
                    this.setState({items:items});
                }, 1000);
            }
        });
    }
    
  
    render() {
        const { t } = this.props;
        return ( 
            <div className="section-full dlab-we-find bg-white p-t50 p-b20 ">
                <div className="container">
                    <div className="section-head text-center">
                        <h2 className="text-uppercase"> {t("PRODUITS EN VEDETTE")}</h2>
                        <div className="dlab-separator-outer ">
                            <div className="dlab-separator bg-secondry style-skew"></div>
                        </div>
                    </div>
                    <div className="section-content">
                        <AliceCarousel 
                        mouseTracking 
                        autoPlay={true}
                        items={this.state.items}
                        infinite
                        renderDotsItem={false}
                        autoPlayInterval = {2000}
                        responsive={{1200: {items: 5},1000: {items: 4},800: {items: 3},300: {items: 2}}} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(React.memo(ProduitsEnVedette));
class Item extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            url : props.url,
            img : props.img,
            title : props.title
        }
    }
render() {
    
    return ( 
        <div className="item" style={{border:"1px solid #a9aaaa",margin:"10px",overflow:"hidden"}}>
            <div className="ow-client-logo">
                <div className="client-logo">
                    <NavLink exact={true} to={"/"+i18next.language+this.state.url} title={this.state.title}>
                        <img onContextMenu={(e)=> e.preventDefault()} src={this.state.img} alt={this.state.title} className="loader-image bg-transparent"/>
                        
                        <h2  className="title-place2" style={{
                            fontFamily: "calibri",
                            textTransform: "uppercase",
                            margin: "0",
                            color: "#000",
                            fontSize: "16px",
                            height: "25px",
                            lineHeight: "1.3",
                            textAlign: "center"
                            }}>
                        <Markup markup={this.state.title} />
                        </h2>
                    </NavLink>
                </div>
            </div>
        </div>
        );
    }
}
class ItemLoader extends Component
{
  
render() {
    
    return ( 
        <div className="item" style={{border:"1px solid #a9aaaa",margin:"10px"}}>
            <div className="ow-client-logo">
                <div className="client-logo">
                    <a href="javascript:void(0)">
                        <div className="loader-image"></div>
                        <div className="loader-title"></div>
                    </a>
                </div>
            </div>
        </div>
        );
    }
}

