import http from "./httpService";
import { apiUrl } from  "../config.json";

const getProduitsEnVedette = () => {
    const apiEndoint =  apiUrl+"/home/ProduitsEnVedette.php";
    return http.get(apiEndoint);
}
const getEntreprisesEnVedette = () => {
    const apiEndoint =  apiUrl+"/home/EntreprisesEnVedette.php";
    return http.get(apiEndoint);
}
const getTopPosts = () => {
    const apiEndoint =  apiUrl+"/home/TopPosts.php";
    return http.get(apiEndoint);
}
const getCompteursStat = () => {
    const apiEndoint =  apiUrl+"/home/Stat.php";
    return http.get(apiEndoint);
}
const getSecteurs = () => {
    const apiEndoint =  apiUrl+"/home/Secteurs.php";
    return http.get(apiEndoint);
}
const getRegions = () => {
    const apiEndoint =  apiUrl+"/home/Regions.php";
    return http.get(apiEndoint);
}
const getRecommande = () => {
    const apiEndoint =  apiUrl+"/home/Recommande.php";
    return http.get(apiEndoint);
}
const getEvennements = () => {
    const apiEndoint =  apiUrl+"/home/Evennements.php";
    return http.get(apiEndoint);
}
const getSearch = (search,typeSearch,lng) => {
    const apiEndoint =  apiUrl+"/home/SearchHader.php?typesearch="+typeSearch+"&s="+search+"&lng="+lng;
    return http.get(apiEndoint);
}
const getIpInfo = () => {
    return http.get("https://api.db-ip.com/v2/free/self");
}
const pageVisite = (data) => {
    const apiEndoint =  apiUrl+"/page-visit-counter.php";
    return http.post(apiEndoint,data);
}
const getPrixPack = () => {
    const apiEndoint =  apiUrl+"/home/prixPack.php";
    return http.get(apiEndoint);
}
export default 
{
    getProduitsEnVedette,
    getEntreprisesEnVedette,
    getTopPosts,
    getCompteursStat,
    getSecteurs,
    getRegions,
    getRecommande,
    getEvennements,
    getSearch,
    getIpInfo,
    pageVisite,
    getPrixPack
}