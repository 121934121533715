import './style.css';
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Auth from '../../services/Auth';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import {b2bNameSite,b2bDescriptionSite} from '../../config.json';
import { Redirect } from 'react-router';
import i18next from 'i18next';
import ReactGA from 'react-ga'
export default class Index extends Component
{

    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
           
        } 
      
    }
    render() {
        return ( 
            <>
            <MetaTags>
                <title>{localStorage.getItem("langb2b")==="fr" ? "Confirmer votre compte" : "Confirm your account"} | {b2bNameSite} | {localStorage.getItem("langb2b")==="fr" ? b2bDescriptionSite : "B2B marketplace and B2B networking platform in France and abroad"}</title>
            </MetaTags>
            <div className="page-content">
                
                <section style={{backgroundColor: '#fff', minHeight: '450px', marginBottom: '-40px'}}>
                    <div className="container">
                        <div className="col-md-12 widget de_widget_categories prod-list" style={{backgroundColor: '#fff'}}>
                            <div style={{margin: '50px auto'}}>
                            <section className="activation-notification" style={{backgroundColor: '#fff'}}>
                                <div className="activation-notification-content">
                                    <p>
                                        <span>
                                            <i className=""  style={{
                                                backgroundImage:"url(/assets/img/confirme-compte.png)",
                                                width:"100px",
                                                height:"100px",
                                                display:"block",
                                                margin: "auto",
                                                backgroundPosition: "center",
                                                backgroundSize: "contain"
                                            }}/>
                                        </span>
                                    </p>
                                    <h1 className="title__bordered" style={{fontSize:"25px"}}>Votre email a été confirmé, et votre compte est encours de validation.</h1>
                                    <h1 className="title__bordered" style={{fontSize:"25px"}}>Vous recevrez un email dés que votre compte EXPERT sera validé et affiché sur {b2bNameSite}</h1>
                                    <p style={{fontSize:"18px"}}>Nous tenons à vous informer que l'activation de votre compte ainsi que la validation de votre profil EXPERT sont assurés manuellement est ce dans le but de vous fournir autant de fiabilité et de visibilité auprès des recruteurs, dans la mesure de vos attentes.</p>
                                    <p style={{fontSize:"18px"}}>Merci pour votre confiance</p>
                                </div>
                            </section>
                            </div>
                        </div>
                    </div>
                   
                </section>
            </div>
            </>
        );
    }
}


