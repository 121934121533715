
import './style.css';
import React, { Component } from 'react';
import Actualites from '../../services/actualites';
import { Link,NavLink } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import {b2bNameSite,b2bDescriptionSite} from '../../config.json';
import Pagination from "react-js-pagination";
import Slider from '../Slider/SliderFacebook';
import MetaTags from 'react-meta-tags';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga'
class Index extends Component
{

    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
            items : [<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>],
            search_mot_cle : "" ,
            activePage : 1 ,
            totalItemsCount : 0,
            itemsPerPage :20,
            progressPage : 0,
            showProgressPage:true,
            l:localStorage.getItem("langb2b")
        }
        setInterval(() => {
            if(this.state.progressPage<80){
                this.setState({progressPage:this.state.progressPage++});
            }
        }, 100);
        
        Actualites.getActualites(1,"").then((res)=>{
            if(res.data.get){
                var items = [];
                res.data.data.forEach(actualite => {
                    items.push(<Item 
                        key={actualite.id} 
                        id={actualite.id} 
                        url={actualite.url} 
                        img={actualite.img} 
                        title={(i18next.language == "en") ? actualite.en_title : actualite.title}
                        date={actualite.date}
                        category={actualite.category}
                        content={(i18next.language == "en") ? actualite.en_content : actualite.content}
                        />);
                }); 
                this.setState({progressPage : 100});
                setTimeout(() => {
                    this.setState({showProgressPage:false}); 
                }, 1000);
                this.setState({items : []});
                this.setState({items : items});
                this.setState({totalItemsCount : res.data.nbposts});
            }
        });
    }

    changeSearch = (e)=>{
        this.setState({
            search_mot_cle : e.target.value
        });
    }
    submitSearch= ()=>{
        Actualites.getActualites(this.state.activePage,this.state.search_mot_cle).then((res)=>{
            if(res.data.get){
                var items = [];
                res.data.data.forEach(actualite => {
                    items.push(<Item 
                        key={actualite.id} 
                        id={actualite.id} 
                        url={actualite.url} 
                        img={actualite.img} 
                        title={(i18next.language == "en") ? actualite.en_title : actualite.title} 
                        date={actualite.date}
                        category={actualite.category}
                        content={(i18next.language == "en") ? actualite.en_content : actualite.content}
                        />);
                }); 
                this.setState({items : []});
                this.setState({items : items});
                this.setState({totalItemsCount : res.data.nbposts});
            }
        });
    }
    handlePageChange(pageNumber) {
        
        this.setState({activePage: pageNumber});
        this.initItems();
        Actualites.getActualites(pageNumber,this.state.search_mot_cle).then((res)=>{
            if(res.data.get){
                var items = [];
                res.data.data.forEach(actualite => {
                    items.push(<Item 
                        key={actualite.id} 
                        id={actualite.id} 
                        url={actualite.url} 
                        img={actualite.img} 
                        title={(i18next.language == "en") ? actualite.en_title : actualite.title} 
                        date={actualite.date}
                        category={actualite.category}
                        content={(i18next.language == "en") ? actualite.en_content : actualite.content}
                        />);
                }); 
                this.setState({items : []});
                this.setState({items : items});
                this.setState({totalItemsCount : res.data.nbposts});
            }
        });
    }
    initItems(){
        window.scrollTo(0, 0);
        this.setState({items : [
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>, 
            <ItemLoader/>,
            <ItemLoader/>
        ]});
    }
    componentDidUpdate(){
        if(this.state.l !== localStorage.getItem("langb2b")){
            Actualites.getActualites(1,"").then((res)=>{
                if(res.data.get){
                    var items = [];
                    res.data.data.forEach(actualite => {
                        items.push(<Item 
                            key={actualite.id} 
                            id={actualite.id} 
                            url={actualite.url} 
                            img={actualite.img} 
                            title={(i18next.language == "en") ? actualite.en_title : actualite.title} 
                            date={actualite.date}
                            category={actualite.category}
                            content={(i18next.language == "en") ? actualite.en_content : actualite.content}
                            />);
                    }); 
                    this.setState({progressPage : 100});
                    setTimeout(() => {
                        this.setState({showProgressPage:false}); 
                    }, 1000);
                    this.setState({items : []});
                    this.setState({items : items});
                    this.setState({totalItemsCount : res.data.nbposts});
                }
            });
        this.setState({
            l:localStorage.getItem("langb2b")
        })
        }
       }
    render() {
        return ( 
            <>
            {this.state.showProgressPage?<div class="progress-div" style={{width: "100%"}}><div style={{width: this.state.progressPage+"%"}} class="progress"></div></div>:<></>}
            <MetaTags>
                <title>{localStorage.getItem("langb2b")==="fr" ? "Actualités B2B" : "B2B News"} | {b2bNameSite} | {localStorage.getItem("langb2b")==="en" ? "B2B marketplace and B2B networking platform in France and abroad" :b2bDescriptionSite }</title>
            </MetaTags>
            <div className="page-content">  
                <section id="list-places-wrapper" style={{marginTop: 0, backgroundColor: '#fff'}}>
                    <div className="container" style={{backgroundColor: '#fff'}}>
                        <br /><br />
                        <div className="row">
                            <div className="col-md-8">    
                                <form role="search" className="place_search_form" action method="GET" style={{marginBottom: '30px'}}>
                                    <div className="row" style={{position:"relative"}}>
                                        <div className="col-md-11 de-search-form " id="search-places" style={{border:"none"}}>
                                            <div className="col-md-12" style={{border: '1px solid #b8c7c8'}}>
                                                <div className="de-scontrol">
                                                    <input type="text" name="mot_cle" onChange={this.changeSearch} className="keyword_search" placeholder={localStorage.getItem("langb2b")==="en" ?"Enter a keyword..." : "Entrez un mot-clé ..."}  value={this.state.search_mot_cle} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 p-0 btn-search-actualite" style={{marginLeft: '-15px'}}>
                                            <button className="btn btn-primary" type="button" onClick={this.submitSearch} style={{backgroundColor: '#ED2829', border: 'none', height: '60px', width: '100%', float: 'right'}}>
                                            <i className="fa fa-search fa-lg" /></button>
                                        </div>
                                    </div>
                                </form>
                                <div className="row">
                                    {this.state.items}
                                </div>
                                <div className="paginations-wrapper">
                                    <div className="paginations">
                                        {this.state.totalItemsCount>this.state.itemsPerPage?
                                        <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.itemsPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                        />
                                        :<></>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4"> 
                                <div className="borde-block">
                                   <Slider /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            </>
        );
    }
}
export default withTranslation()(Index);




class Item extends Component
{
    constructor (props) {
        super(props);  

    }
  
    render() {

        return ( 
            <div className="col-md-6" style={{marginBottom: '20px'}}>
                <div className="wpnaw-news-overlay-main-wrap">
                    <Link className="wpnaw-link-overlay" to={"/"+i18next.language+this.props.url} />     
                        <div className="wpnaw-news-image-bg" style={{}}>
                            <img onContextMenu={(e)=> e.preventDefault()} className="wpnaw-news-image" src={this.props.img+""} alt={this.props.title} />
                        </div>
                        <div className="wpnaw-news-grid-content">
                            <div className="wpnaw-news-categories">
                                <span>{this.props.category}</span>
                            </div>
                            <div className="wpnaw-news-date" style={{float: 'right', padding: '4px 0', fontSize: '12px'}}>{this.props.date}</div>
                            <div className="wpnaw-title-content">
                                <h2 className="wpnaw-news-title">
                                    <Link to={"/"+i18next.language+this.props.url} target="_self" style={{fontSize: '17px', textTransform: 'uppercase'}}><Markup markup={this.props.title} /></Link>
                                </h2>
                            </div>
                        </div>
                </div>
                <div className="wpnaw-news-content">
                    <div className="wpnaw-news-short-content" style={{height: '40px', fontFamily: 'calibri', fontSize: '15px'}}><Markup markup={this.props.content} /></div>
                    <Link to={"/"+i18next.language+this.props.url} target="_self" className="readmorebtn" style={{float: 'right', color: '#e11a18'}}>{localStorage.getItem("langb2b")==="en" ?"Read more" : "Lire la suite"}</Link>
                </div>
            </div>
        );
    }
}



class ItemLoader extends Component
{
    render() {

        return ( 
            <div className="col-md-6" style={{marginBottom: '20px'}}>
                <div className="wpnaw-news-overlay-main-wrap">
                    <a className="wpnaw-link-overlay" href="#" />     
                        <div className="wpnaw-news-image-bg" style={{}}>
                           <div className="lowder-image-post"></div>
                        </div>
                        <div className="wpnaw-news-grid-content">
                            <div className="wpnaw-news-categories">
                                <span>      </span>
                            </div>
                            <div className="wpnaw-news-date" style={{float: 'right', padding: '4px 0', fontSize: '12px'}}>     </div>
                            <div className="wpnaw-title-content">
                                <h2 className="wpnaw-news-title">
                                    <a href="#" target="_self" style={{fontSize: '17px', textTransform: 'uppercase'}}>    </a>
                                </h2>
                            </div>
                        </div>
                </div>
                <div className="wpnaw-news-content">
                    <div className="wpnaw-news-short-content lowder-text-post" ></div>
                    <a href="#" target="_self" className="readmorebtn" style={{float: 'right', color: '#e11a18'}}>Lire la suite</a>
                </div>
            </div>
        );
    }
}
