

import './style.css';
import React, { Component } from 'react';
import { Redirect } from 'react-router'
import i18next from 'i18next';

export default class Index extends Component
{
    constructor (props) {
      super(props)
      if(localStorage.getItem('tokenb2b')!=""){
        localStorage.setItem('tokenb2b',"");
        window.location.reload(false);
      }
      
    }
    
    render() {
        return ( <Redirect to={"/"+i18next.language+"/signin"}/> );
    }
}
