import http from "./httpService";
import { apiUrl } from  "../config.json";

const getEntreprises = (paged=1,categorie=null,region=null,mot_cle=null) => {
    const apiEndoint =  apiUrl+"/entreprises/getEntreprises.php?paged="+paged+"&mot_cle="+mot_cle+"&categorie="+categorie+"&region="+region;
    return http.get(apiEndoint);
}
const getEntreprisesAll = (nb) => {
    const apiEndoint =  apiUrl+"/entreprises/getEntreprisesAll.php?nb="+nb;
    return http.get(apiEndoint);
}
const getDetail = (slug) => {
    const apiEndoint =  apiUrl+"/entreprises/getDetail.php?slug="+slug;
    return http.get(apiEndoint);
}
const getRegions = () => {
    const apiEndoint =  apiUrl+"/entreprises/getRegions.php?";
    return http.get(apiEndoint);
}
const getCategories = (hide_empty=1) => {
    var apiEndoint =  apiUrl+"/entreprises/getCategories.php";
    if(hide_empty!==1){
        apiEndoint =  apiUrl+"/entreprises/getCategories.php?hide_empty=false";
    }
    return http.get(apiEndoint);
}

const searchEntreprises = (search) => {
    const apiEndoint =  apiUrl+"/entreprises/searchEntreprises.php";
    const data = new FormData();
    data.append("search",search);
    return http.post(apiEndoint,data);
}

const registerMessage = (entrepriseid,entrepriseuser,data) => {
    const apiEndoint =  apiUrl+"/security/registerMessageEntreprise.php";
    const formData = new FormData();
    formData.append("entrepriseid", entrepriseid);
    formData.append("entrepriseuser", entrepriseuser);
    formData.append("token", localStorage.getItem("tokenb2b"));
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return http.post(apiEndoint,formData);
}
const registerMessageOf = (entrepriseuser,data) => {
    const apiEndoint =  apiUrl+"/security/registerMessageOffline.php";
    const formData = new FormData();
    
    
    formData.append("entrepriseuser", entrepriseuser);
    
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return http.post(apiEndoint,formData);
}
const reclamation = (telephone,message,file,id) => {
    const apiEndoint =  apiUrl+"/security/reclamation.php";
    const formData = new FormData();
    formData.append("claim_tel", telephone);
    formData.append("claim_msg", message);
    formData.append("claim_file", file);
    formData.append("claim_id", id);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
const Enteprisespropose = (name) => {
    const apiEndoint =  apiUrl+"/entreprises/getEnteprisespropose.php?name="+name;
    return http.get(apiEndoint);
}


export default 
{
    getEntreprises,
    getEntreprisesAll,
    getDetail,
    getRegions,
    getCategories,
    registerMessageOf,
    searchEntreprises,
    registerMessage,
    reclamation,
    Enteprisespropose
}