import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Searchpro from '../header/serchpro';
import i18next from 'i18next';
import './style.css';
import 'react-alice-carousel/lib/alice-carousel.css';


class Login extends Component
{
    constructor (props) {
        super(props)
        this.state = {
          
        }
    }
    
    render() {
        const { t } = this.props;
        return (
            <div className="home_search_b2b" style={{width: '20%', height: 'auto', right: '5%',top: "0px", marginTop: '4%', background: '#f0f2f3a6', position: 'absolute', zIndex: 99, paddingTop: '30px'}}>
                <div className="home-search-input">
                    <Searchpro placeholder="Entrez votre mot-clé Entreprise" type="entreprise"/>
                </div>
                <h1 className="top-title-post-place"> OU </h1>
                <div className="home-search-input">
                    <Searchpro placeholder="Entrez votre mot-clé produit" type="produit"/>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6 col-sm-12 btn-search btn-search-2" style={{textAlign: 'center', marginTop: '15px'}}>
                        {/* <a href="#" style={{color: '#fff', backgroundColor: '#e11a18', padding: '4px 18px'}}>
                            Trouver|<i className="fa fa-search" />
                        </a> */}
                    </div>
                    <div className="col-md-6 col-sm-12 btn-search btn-search-2" style={{textAlign: 'center', marginTop: '15px'}}>
                        {/* <Link to={"/"+i18next.language+"/add/"} style={{color: '#fff', backgroundColor: '#e11a18', padding: '4px 18px'}}>
                        Ajouter|<i className="fa fa-map-marker" />
                        </Link> */}
                        {/* <a href="#" style={{color: '#fff', backgroundColor: '#e11a18', padding: '4px 18px'}}>
                            Trouver|<i className="fa fa-search" />
                        </a> */}
                    </div>
                </div>
          </div>    
        )
    }
}
export default React.memo(Login);  
