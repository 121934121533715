import http from "./httpService";
import { apiUrl } from  "../config.json";

const getProduits = (paged=1,categorie=null,entreprise=null,mot_cle=null) => {
    const apiEndoint =  apiUrl+"/produits/getProduits.php?paged="+paged+"&mot_cle="+mot_cle+"&categorie="+categorie+"&entreprise="+entreprise;
    return http.get(apiEndoint);
}

const getProduitsAll = (nb=-1) => {
    const apiEndoint =  apiUrl+"/produits/getProduitsAll.php?nb="+nb;
    return http.get(apiEndoint);
}
const getDetail = (slug) => {
    const apiEndoint =  apiUrl+"/produits/getDetail.php?slug="+slug;
    return http.get(apiEndoint);
}
const getEntreprises = () => {
    const apiEndoint =  apiUrl+"/produits/getEntreprises.php";
    return http.get(apiEndoint);
}
const getCategories = () => {
    const apiEndoint =  apiUrl+"/produits/getCategories.php";
    return http.get(apiEndoint);
}
const getAvis = (id) => {
    const apiEndoint =  apiUrl+"/produits/getAvis.php?id="+id;
    return http.get(apiEndoint);
}
const saveAvis = (produit,note,text) => {
    const apiEndoint =  apiUrl+"/security/saveAvis.php";
    const formData = new FormData();
    formData.append("note", note);
    formData.append("text",text);
    formData.append("produit",produit);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}

export default 
{
    getProduits,
    getProduitsAll,
    getDetail,
    getEntreprises,
    getCategories,
    getAvis,
    saveAvis
}