
import React, { Component } from 'react';
import jwt_decode from "jwt-decode";
import Auth from  "../../services/Auth";
import Mtb2bConected from './indexConected';
import Mtb2bNonConected from './indexNonConected';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga'
class Index extends Component
{
    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        super(props)
        var dataUser=null;
        if(localStorage.getItem('tokenb2b') && localStorage.getItem('tokenb2b')!="undefined" && localStorage.getItem('tokenb2b')!=""){
            dataUser=jwt_decode(localStorage.getItem('tokenb2b'));
        }
        this.state = {
            dataUser:dataUser,
        }
        Auth.isLogin().then((res)=>{
            if(res.data!=false){
               this.setState({
                  dataUser:res.data
               });
            }else{
               this.setState({
                  dataUser:null
               });
            }
        });
    }
    
    render() {
        
        if(this.state.dataUser==null){
            return (
              <Mtb2bNonConected />
            )
        }else{
            return (
              <Mtb2bConected dataUser={this.state.dataUser} />
            )
        }
        
    }
}
export default withTranslation()(Index); 

