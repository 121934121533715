import http from "./httpService";
import { apiUrl } from  "../config.json";

const getSousTritance = (paged=1,mot_cle=null,type=null,domaines=null,localisation=null) => {
    const apiEndoint =  apiUrl+"/sousTritance/getSousTritance.php";
    const formData = new FormData();
    formData.append("mot_cle", mot_cle);
    formData.append("paged", paged);
    formData.append("type", type);
    formData.append("domaines", domaines);
    formData.append("localisation", localisation);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
const getDetail = (slug) => {
    const apiEndoint =  apiUrl+"/sousTritance/getDetail.php";
    const formData = new FormData();
    formData.append("slug", slug);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
const getInfoSousTritance = () => {
    const apiEndoint =  apiUrl+"/sousTritance/getInfoSousTritance.php";
    return http.get(apiEndoint);
}

const postuler = (offre,message,object,company) => {
    const apiEndoint =  apiUrl+"/sousTritance/postuler.php";
    const formData = new FormData();
    formData.append("offre", offre);
    formData.append("message", message);
    formData.append("object", object);
    formData.append("company", company);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
export default 
{
    getSousTritance,
    getDetail,
    getInfoSousTritance,
    postuler
}