import http from "./httpService";
import { apiUrl } from  "../config.json";

const getConference = (paged=1,mot_cle=null) => {
    const apiEndoint =  apiUrl+"/conference/getConference.php";
    const formData = new FormData();
    formData.append("mot_cle", mot_cle);
    formData.append("paged", paged);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
const getDetail = (slug) => {
    const apiEndoint =  apiUrl+"/conference/getDetail.php";
    const formData = new FormData();
    formData.append("slug", slug);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}

const postuler = (offre,message,object,company) => {
    const apiEndoint =  apiUrl+"/conference/postuler.php";
    const formData = new FormData();
    formData.append("offre", offre);
    formData.append("message", message);
    formData.append("object", object);
    formData.append("company", company);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
const participe = (titre,description,start_date_fixe,start_time_fixe) => {
    const apiEndoint =  apiUrl+"/conference/participe.php";
    const formData = new FormData();
    formData.append("titre", titre);
    formData.append("description", description);
    formData.append("start_date_fixe", start_date_fixe);
    formData.append("start_time_fixe", start_time_fixe);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
export default 
{
    getConference,
    getDetail,
    postuler,
    participe
}