import http from "./httpService";
import { apiUrl } from  "../config.json";

const getOffresMissions = (paged=1,mot_cle="",domaine=[],budget=[],duree_mission=[],emplacement=[]) => {
    var apiEndoint =  apiUrl+"/offresMissions/getOffresMissions.php?paged="+paged+"&mot_cle="+mot_cle;
    if(domaine.length>0){
        domaine.forEach(element => {
            apiEndoint = apiEndoint+"&domaine%5B%5D="+element;
        });
    }
    if(budget.length>0){
        budget.forEach(element => {
            apiEndoint = apiEndoint+"&budget%5B%5D="+element;
        });
    }
    if(duree_mission.length>0){
        duree_mission.forEach(element => {
            apiEndoint = apiEndoint+"&duree_mission%5B%5D="+element;
        });
    }
    if(emplacement.length>0){
        emplacement.forEach(element => {
            apiEndoint = apiEndoint+"&emplacement%5B%5D="+element;
        });
    }
    return http.get(apiEndoint);
}
const getDetail = (slug) => {
    const apiEndoint =  apiUrl+"/offresMissions/getDetail.php?slug="+slug;
    return http.get(apiEndoint);
}
const registerMessage = (entrepriseid,entrepriseuser,offreid,data) => {
    const apiEndoint =  apiUrl+"/security/registerMessageOffresMission.php";
    const formData = new FormData();
    formData.append("entrepriseid", entrepriseid);
    formData.append("entrepriseuser", entrepriseuser);
    formData.append("offreid", offreid);
    formData.append("token", localStorage.getItem("tokenb2b"));
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return http.post(apiEndoint,formData);
}

export default 
{
    getOffresMissions,
    getDetail,
    registerMessage
}