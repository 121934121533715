import React from "react";
import AliceCarousel from "react-alice-carousel";
import "./gallery.css";

const Modal = ({ modal, items, handleModalFalse, currentIndex, featured }) => {
  return (
    <div className={modal ? "modal" : "hide"} onClick={handleModalFalse}>
      {/* this only features the first image */}
      {/* <img onContextMenu={(e)=> e.preventDefault()} src={featured} /> */}
      {/* shows every image on modal*/}
      <div className="wrapper">
        <img onContextMenu={(e)=> e.preventDefault()} src={items[currentIndex]} alt="modal" />
      </div>
    </div>
  );
};

class Gallery extends React.Component {
  constructor (props) {
      super(props)
     
      var items=[this.props.data.thumbnail];
      this.props.data.galerie.forEach(image => {
        if(image)
          items.push(image);
      });
    
      this.state = {
        currentIndex: 0,
        items: items,
        modal: false,
        featured: undefined,
        renderGallery:<RenderGallery
                        items={items}
                        responsive={{
                          0: { items: 1 },
                          1024: { items: 1 }
                        }}
                        onSlideChanged={this.onSlideChanged}
                        currentIndex={0}
                      />
      };
  }
  responsive = {
    0: { items: 1 },
    1024: { items: 1 }
  };

  componentDidMount() {
    this.setState({ featured: this.state.items[0] });
  }

 

  slideTo = (i) => {
    
    this.setState({renderGallery:<></>});
    setTimeout(() => {
      this.setState({ 
      renderGallery:<RenderGallery
                      items={this.state.items}
                      responsive={{
                        0: { items: 1 },
                        1024: { items: 1 }
                      }}
                      onSlideChanged={this.onSlideChanged}
                      currentIndex={i}
                    />
     })
    }, 10);
    
  
  
  }

  // Do I need this method at all?
  onSlideChanged = e => this.setState({ currentIndex: e.item });

  slideNext = () =>
    this.setState({ currentIndex: this.state.currentIndex + 1 });

  slidePrev = () =>
    this.setState({ currentIndex: this.state.currentIndex - 1 });

  handleEnlargeClick = () => {
    this.setState({ modal: true });
  };

  handleModalFalse = () => {
    this.setState({ modal: false });
  };

  render() {
    if(this.props.data){
      var items=[this.props.data.thumbnail];
      this.props.data.galerie.forEach(image => {
        if(image)
        items.push(image);
      });

      return (
        <>
        <div style={{height:"330px"}}>{this.state.renderGallery}</div>
         
          <RenderThumbs
            items={items}
            slideNext={this.slideNext}
            slidePrev={this.slidePrev}
            slideTo={this.slideTo}
            responsive={this.responsive}
          />
        </>
      );
    }else{
      return (
        <>
          <div className="loder-gallery-image"></div>
          <div className="loder-gallery-images">
            <div className="loder-gallery-image-min"></div>
            <div className="loder-gallery-image-min"></div>
          </div>
        </>
      );
    }
    
  }
}

const RenderGallery = ({ currentIndex, items, onSlideChanged, responsive }) => {

  return (
    <AliceCarousel
      dotsDisabled={false}
      buttonsDisabled={true}
      activeIndex ={currentIndex}
      onSlideChanged={onSlideChanged}
      responsive={responsive}
    >
      {items.map((item, i) => (
        <div key={i} className="wrapper">
          
          <img onContextMenu={(e)=> e.preventDefault()} src={item} alt="carousel" />
          
          
        </div>
      ))}
    </AliceCarousel>
  );
};

// Thumbnails
const RenderThumbs = ({ items, slideNext, slidePrev, slideTo }) => (

  <div className="renderThumbs">
    {items.length>4?<>
    <AliceCarousel
      disableButtonsControls={false}
      activeIndex ={0}
      onSlideChanged={slideTo}
      responsive={{
        0: { items: 4 },
        1024: { items: 4 }
      }}
    >
      {items.map((item, i) => (
        <div className="thumb" key={i} onClick={() => slideTo(i)}>
          <img onContextMenu={(e)=> e.preventDefault()} src={item} style={{width:"100%",height:"100px",objectFit:"cover",border:"solid 1px #aaa"}} alt="slide" />
        </div>
      ))}
    </AliceCarousel>
    </>:<>
      <div className="content-item-slide-prod d-flex">
        {items.map((item, i) => (
        <div className="thumb" key={i} onClick={() => slideTo(i)} style={{margin: "5px"}}>
          <img onContextMenu={(e)=> e.preventDefault()} src={item} style={{width:"100px",height:"100px",objectFit:"cover",border:"solid 1px #aaa",margin: "0px"}} alt="slide" />
        </div>
        ))}
        </div>
    </>}
  </div>
);

export default React.memo(Gallery);
