
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
export default class LogoB2b extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            log_gif:false,
        }
        setTimeout(() => {
            this.setState({log_gif:true});
        }, 5000);
    }
  
    render() {
        if(this.state.log_gif){
            return ( 
                <>
                    <div className="logo-header mostion block-logo-dicktop" style={{width:"260px"}}>
                        <NavLink exact={true} to="/" className="logo-imagee" style={{backgroundImage:"url(/assets/img/logo-gif.webp)"}}></NavLink >
                    </div>
                    <div className="logo-header mostion block-logo-mobile" style={{width:"260px"}}>
                        <NavLink exact={true} to="/" className="logo-imagee" style={{backgroundImage:"url(/assets/img/logo.webp)"}}></NavLink >
                    </div>
                </>
                
            );
        }else{
            return ( 
                <>
                    <div className="logo-header mostion block-logo-dicktop" style={{width:"260px"}}>
                        <NavLink exact={true} to="/" className="logo-imagee" style={{backgroundImage:"url(/assets/img/logo.webp)",backgroundSize: "contain"}}>&nbsp;</NavLink >
                    </div>
                    <div className="logo-header mostion block-logo-mobile" style={{width:"260px"}}>
                        <NavLink exact={true} to="/" className="logo-imagee" style={{backgroundImage:"url(/assets/img/logo.webp)",backgroundSize: "contain"}}>&nbsp;</NavLink >
                    </div>
                </>
                
            );
        }
       
    }
}
