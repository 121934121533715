import './style.css';
import React, { Component } from 'react';
import Auth from '../../../services/Auth'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import i18next from 'i18next';
import Header from './header';
import { useForm } from "react-hook-form";
import jwt_decode from "jwt-decode";
import Experts from "../../../services/experts"
import { useEffect } from "react";
import { useState } from "react";
import Button from './button.js';
import Compitonse from './compitonse.js';

export default class Index extends Component
{
    constructor (props) {
        window.scrollTo(0, 0);
        super(props)
        var userLogin=false;
        var dataUser=null;
        if(localStorage.getItem('tokenb2b') && localStorage.getItem('tokenb2b')!="undefined" && localStorage.getItem('tokenb2b')!=""){
           userLogin=true;
           dataUser=jwt_decode(localStorage.getItem('tokenb2b'));
        }
        this.state = {
            isLogin:userLogin,
            dataUser:dataUser
        }
        Auth.isLogin().then((res)=>{
            if(res.data!=false){
                this.setState({
                    isLogin:true,
                    dataUser:res.data
                });
            }else{
                this.setState({
                    isLogin:false,
                    dataUser:null
                });
            }
        });
    }
    render() {
        if (this.state.isLogin) {
            return ( <Inscription dataUser={this.state.dataUser}/> );
        } else {
            return ( <Redirect to={"/"+i18next.language}/> );
        }
    }
}
function useScrollToError(errors) {
    useEffect(() => {
      const errorsvalues = Object.values(errors)
      if (errorsvalues.length > 0) {
        errorsvalues[0].ref.scrollIntoView(false)
      }
    }, [errors])
}
function Inscription(props) {
    const [dataUser, setDataUser] = useState(props.dataUser);
    const [dataInscription, setDataInscription] = useState(null);
    const [optionNiveauEtudes, setOptionNiveauEtudes] = useState([]);
    const [optionDomainesTravail, setOptionDomainesTravail] = useState([]);
    const [optionDomainesFreelance, setOptionDomainesFreelance] = useState(null);
    const [selectCompetencesFreelance, setSelectCompetencesFreelance] = useState([]);
    const [optionCompetencesFreelance, setOptionCompetencesFreelance] = useState([]);
    const [domainesFreelance, setDomainesFreelance] = useState(null);
    const [optionExperience, setOptionExperience] = useState(null);
    const [dataTypeContrat, setDataTypeContrat] = useState([]);
    const [optionTypeContrat, setOptionTypeContrat] = useState(null);
    const [selectOptionTypeContrat, setSelectTypeContrat] = useState([]);
    const [optionSalaires, setOptionSalaires] = useState(null);
    const [loderFrome, setLoderFrome] = useState("d-none");
    const [showFreeLance, setShowFreeLance] = useState("d-none");
    const [showEmploi, setShowEmploi] = useState("d-none");
    const [enPoste, setEnPoste] = useState("");
    const [competences, setCompetences] = useState([{"id":0,"competence":"","p":"1"}]);
    const [competenceserrormsg, setCompetenceserrormsg] = useState(false);
    const [errors_competences_freelance, setErrorsCompetencesFreelance] = useState(false);
    const [errors_disponibilite, setErrorsDisponibilite] = useState(false);
    const [errorsTypeContrat, setErrorsTypeContrat] = useState(false);
    const [redirect, setRedirect] = useState(<></>);
    const [disponibilite, setDisponibilite] = useState("");
    const [searchCategorie, setSearchCategorie] = useState("");
    const [searchCategories, setSearchCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    //const [showCategories, setShowCategories] = useState([]);
    const [error_categories, setErrorCategories] = useState(false);
    const [texte_error_categories, setTexteErrorCategories] = useState("");
    const { register, handleSubmit, setValue , reset , formState: { errors } } = useForm();
    useScrollToError(errors);
    const clickSubmit = () =>{
        setTimeout(() => {
            var input_demandeur_radio = document.querySelector('input[name="demandeur_radio"]:checked');
            if(input_demandeur_radio){
                var demandeur_radio = input_demandeur_radio.value;
                setErrorCategories(false);
                setCompetenceserrormsg(false);
                setErrorsTypeContrat(false);
                setErrorsDisponibilite(false);
                setErrorsCompetencesFreelance(false);
                if(demandeur_radio=="demandeurdemploi"){
                    if(categories.length<=0){
                        setErrorCategories(true);
                    }
                    if(competences.length<=0){
                        setCompetenceserrormsg(true);
                    }else{
                        competences.forEach(c => {
                            if(c.competence=="" || c.p=="" || c.p==0){
                                setCompetenceserrormsg(true);
                            }
                        });
                    }
                    if(selectOptionTypeContrat<=0){
                        setErrorsTypeContrat(true);
                    }
                    if(disponibilite=="" ){
                        setErrorsDisponibilite(true);
                    } 
                }else{
                    if(selectCompetencesFreelance<=0){
                        setErrorsCompetencesFreelance(true);
                    }
                }
            }
        }, 10);
    }
    const onSubmit = (data) => 
    {
        setErrorCategories(false);
        setCompetenceserrormsg(false);
        setErrorsTypeContrat(false);
        setErrorsDisponibilite(false);
        setErrorsCompetencesFreelance(false);
        if(data.demandeur_radio=="demandeurdemploi"){
            if(categories.length<=0){
                setErrorCategories(true);
                return false;
            }
            if(competences.length<=0){
                setCompetenceserrormsg(true);
                return false;
            }else{
                competences.forEach(c => {
                    if(c.competence=="" || c.p=="" || c.p==0){
                        setCompetenceserrormsg(true);
                        return false;
                    }
                });
            }
            if(selectOptionTypeContrat<=0){
                setErrorsTypeContrat(true);
                return false;
            }
            if(disponibilite==""){
                setErrorsDisponibilite(true);
                return false;
            } 
        }else{
            if(selectCompetencesFreelance<=0){
                setErrorsCompetencesFreelance(true);
                return false;
            }
        }

        setLoderFrome("loder-from");
        // console.info("_____________________________________________________________________________________________");
        // console.info("*********data*********");
        // console.info(data);
        // console.info("*********select Option Competences Freelance*********");
        // console.info(selectCompetencesFreelance);
        // console.info("*********select Option Type Contrat*********");
        // console.info(selectOptionTypeContrat);
        // console.info("*********competences*********");
        // console.info(competences);
        // console.info("_____________________________________________________________________________________________");
        var competence_emploi = [];
        var competence_p_emploi = [];
        var number_of_competence_emploi = competences.length;
        competences.forEach(c => {
            competence_emploi.push(c.competence);
            competence_p_emploi.push(c.p);
        });
        var categorieseTravail = [];
        categories.forEach(c => {
            categorieseTravail.push(c.id);
        });
        categorieseTravail = categorieseTravail.join(';');
        Auth.inscriptionExpert2(data,selectCompetencesFreelance,selectOptionTypeContrat,competence_emploi,competence_p_emploi,number_of_competence_emploi,disponibilite,categorieseTravail).then((res)=>{
            if(res.data.register){
                setLoderFrome("");
                setRedirect(<Redirect to={"/"+i18next.language+"/expert/inscription-fin"}/>);
            }
        });
    }
    const removeCompetence = ()=>{
        setCompetences(competences.slice(0, -1))
    } 
    const addCompetence= ()=>{
        var newCompetences = [];
        var add = true;
        setCompetenceserrormsg(false);
        competences.forEach(c => {
            newCompetences.push(c);
            if(c.competence=="" || c.p==0){
                add = false;
            }
        });
        if(add){
            newCompetences.push({"id":competences.length,"competence":"","p":0});
        }else{
            setCompetenceserrormsg(true);
        }
        
        setCompetences(newCompetences);
    }
    const updateCompetencesFreelance= (val,stat)=>{
        var tab =[];
        
        if(stat=="add"){
            tab = selectCompetencesFreelance;
            tab.push(val);
        }else if(stat=="del"){
            var index = selectCompetencesFreelance.indexOf(val);
            tab = selectCompetencesFreelance;
            tab.splice(index, 1)
        } 
        setSelectCompetencesFreelance(tab);
    }
    
    const updateCompetence= (competence,p,key)=>{
        var newCompetences = [];
        competences.forEach(c => {
            if(c.id==key){
                newCompetences.push({
                    "id":key,
                    "competence":competence,
                    "p":p
                })
            }else{
                newCompetences.push(c);
            }
        });
        setCompetenceserrormsg(false);
        setCompetences(newCompetences);
    }
    const updateTypeContrat= (val,stat)=>{
        //alert();
        setErrorsTypeContrat(false);
        // if(stat=="add"){
        //     setSelectTypeContrat(selectOptionTypeContrat.push(val));
        // }else if(stat=="del"){
        //     var index = selectOptionTypeContrat.indexOf(val);
        //     setSelectTypeContrat(selectOptionTypeContrat.splice(index, 1));
        // }  
        var tab =[];
        if(stat=="add"){
            tab = selectOptionTypeContrat;
            tab.push(val);
        }else if(stat=="del"){
            var index = selectOptionTypeContrat.indexOf(val);
            tab = selectOptionTypeContrat;
            tab.splice(index, 1)
        } 
        setSelectTypeContrat(tab);
    }
    

    const clickMissionFreeLance = ()=>{
        setShowFreeLance("");
        setShowEmploi("d-none");
    }
    const clickEmploi = ()=>{
        setShowFreeLance("d-none");
        setShowEmploi("");
    }
    const clickEnPosteOui = ()=>{
        setEnPoste("oui");
    }
    const clickEnPosteNon = ()=>{
        setEnPoste("non");
    }
    const updateDomainesFreelance = (id)=>{
        domainesFreelance.forEach(domaine => {
            if(domaine.id==id){
                var tags=[];
                domaine.tags.forEach(tag => {
                    tags.push(<Button tag={tag} id={tag} update={updateCompetencesFreelance}/> ) 
                });
                setSelectCompetencesFreelance([]);
                setOptionCompetencesFreelance(tags);
            }
        });

    }
    const clickCategorie = (e)=>{
        setErrorCategories(false)
        if(categories.length<5){
            var categoriess = categories;
            optionDomainesTravail.forEach(categorie => {
                if(e.target.id == categorie.id){
                    categoriess.push({ id: categorie.id, name: categorie.name+"("+categorie.name_parent+")" });
                }
            });
            setCategories(categoriess);
            setSearchCategorie("");
            setSearchCategories([]);
        }
    }
    const changeCategorie = (e)=>{
        setErrorCategories(false);
        setTexteErrorCategories("");
        setSearchCategorie(e.target.value);
        let searcjQery = e.target.value.toLowerCase();
        if(searcjQery.length>1 && categories.length<5){
            var domainesTravail= optionDomainesTravail;
            var displayedContacts = domainesTravail.filter((c) => {
                let searchValue = c.name.toLowerCase();
                return searchValue.indexOf(searcjQery) !== -1;
            })
            var searchCategories=[];
            displayedContacts.forEach(categorie => {
                var addedcategorie = false;
                categories.forEach(c => {
                    if(c.id == categorie.id) addedcategorie = true;
                });
                if(!addedcategorie){
                    searchCategories.push(
                        <li onClick={clickCategorie} className="searched_companies li_not_clicked" id={categorie.id} name={categorie.name}>{categorie.name+"("+categorie.name_parent+")"}</li>
                    );
                }
            });
            setSearchCategories([]);
            setSearchCategories(searchCategories);
        }else{
            setSearchCategories([]);
            if(categories.length>=5 && searcjQery.length>0){
                setTexteErrorCategories("max5");
            }
        }
    }
    const removeCategorie = (e)=>{
        var newcategories = categories.filter((c) => {
            return c.id != e.target.id;
        });
        setCategories(newcategories);
    }
    useEffect(() => {
        var allDataTypeContrat=[];
        var allDataDomainesFreelance=[];
        Experts.getInfoInscription().then((res)=>{
            if(res.data.get){
                var items = [];
                res.data.niveau_etudes.forEach(item => {
                    items.push(<option value={parseInt(item.id)}>{item.name}</option>);
                });
                setOptionNiveauEtudes(items);

                // var items = [];
                // .forEach(item => {
                //     items.push(<option value={item.id}>{item.name}</option>);
                // });
                setOptionDomainesTravail(res.data.domaines_emploi);
                var items = [];
                res.data.domaines_freelance.forEach(item => {
                    items.push(<option value={parseInt(item.id)}>{item.name}</option>);
                });
                setDomainesFreelance(res.data.domaines_freelance);
                allDataDomainesFreelance=res.data.domaines_freelance;
                setOptionDomainesFreelance(items);

                var items = [];
                res.data.experience.forEach(item => {
                    items.push(<option value={parseInt(item.id)}>{item.name}</option>);
                });
                setOptionExperience(items);

                var items = [];
                setDataTypeContrat(res.data.type_contrat);
                allDataTypeContrat=res.data.type_contrat;
                res.data.type_contrat.forEach(item => {
                    items.push(<Button tag={item.name} id={item.id} update={updateTypeContrat} type="cont"/>);
                });

                setOptionTypeContrat(items);

                var items = [];
                res.data.salaires.forEach(item => {
                    items.push(<option value={item.id}>{item.name}</option>);
                });
                setOptionSalaires(items);
            }
        });
        if(dataUser){
            Experts.getDetailByID(dataUser.code_user).then((res)=>{
                if(res.data.get){
                    setDataInscription(res.data.data);
                    setValue("poste_recherche", res.data.data.poste_recherche);
                    setValue("demandeur_radio", res.data.data.demandeur_radio);
                    if(res.data.data.demandeur_radio=="freelancer"){
                        clickMissionFreeLance();
                        setTimeout(() => {
                            setValue("domaine_freelance", parseInt(res.data.data.domaine_freelance));
                            
                            allDataDomainesFreelance.forEach(domaine => {
                                if(domaine.id==parseInt(res.data.data.domaine_freelance)){
                                    var tags=[];
                                    domaine.tags.forEach(tag => {
                                        
                                        if(res.data.data.competences_freelancer.includes(tag)){
                                            tags.push(<Button clicked="oui" tag={tag} id={tag} update={updateCompetencesFreelance}/> ) ;
                                            updateCompetencesFreelance(tag,'add');
                                        }else{
                                            tags.push(<Button  tag={tag} id={tag} update={updateCompetencesFreelance}/> ) ;
                                        }
                                    });
                                    setSelectCompetencesFreelance([]);
                                    setOptionCompetencesFreelance(tags);
                                }
                            });
                        }, 1000);
                    }
                    if(res.data.data.demandeur_radio=="demandeurdemploi"){
                        clickEmploi();
                        setTimeout(() => {
                            if(Array.isArray(res.data.data.domaine_emploi)){
                                Experts.getInfoInscription().then((res2)=>{
                                    if(res2.data.get){
                                        setCategories([]);
                                        var categoriess = categories;
                                        res.data.data.domaine_emploi.forEach(domaine_emploi => {
                                            res2.data.domaines_emploi.forEach(categorie => {
                                                if(parseInt(domaine_emploi)+1 == parseInt(categorie.id)){
                                                    categoriess.push({ id: categorie.id, name: categorie.name+"("+categorie.name_parent+")" });
                                                }
                                            });
                                        });
                                        setCategories(categoriess);
                                    }
                                });
                            }
                            setValue("salaire_actuel", parseInt(res.data.data.salaire_actuel));
                            setValue("salaire", parseInt(res.data.data.salaire_souhaite));
                            
                            var _competences =[];
                            setCompetences([]);
                            var i = 0;
                            res.data.data.competences_emploi.forEach(competence_emploi => {
                                _competences.push({"id":i,"competence":competence_emploi.text,"p":competence_emploi.p});
                                i++;
                            });
                            if(_competences.length==0){
                                setCompetences([{"id":0,"competence":"","p":"1"}]);
                            }else{
                                setCompetences(_competences);
                            }
                            //res.data.data.type_emploi.forEach(type_emploi => {
                                
                                var items = [];
                                setOptionTypeContrat([]);
                                allDataTypeContrat.forEach(item => {
                                    if(res.data.data.type_emploi.includes(item.id)){
                                        items.push(<Button clicked="oui" tag={item.name} id={item.id} update={updateTypeContrat} type="cont"/>);
                                        updateTypeContrat(item.id,"add");
                                    }else{
                                        items.push(<Button tag={item.name} id={item.id} update={updateTypeContrat} type="cont"/>);
                                    }
                                });
                                setOptionTypeContrat(items);
                            //});
                            if(res.data.data.disponibilite=="immediatement"){
                               setDisponibilite("disponibilite_immediatement"); 
                            }
                            if(res.data.data.disponibilite=="duree"){
                                setDisponibilite("preavis"); 
                                setValue("disponibilite_duree", res.data.data.disponibilite_duree);
                            }
                            if(res.data.data.disponibilite=="datefin"){
                                setDisponibilite("disponibilite_fin");  
                                setValue("disponibilite_datefin", res.data.data.disponibilite_duree);
                            }
                            
                        }, 1000);
                    }
                    setValue("travail_radio", res.data.data.travail_radio);
                    if(res.data.data.travail_radio=="oui"){
                        clickEnPosteOui();
                    }
                    if(res.data.data.travail_radio=="non"){
                        clickEnPosteNon();
                    }
                    setTimeout(() => {
                        setValue("niveau_etude", parseInt(res.data.data.niveau_etude) );
                        setValue("nbr_experience", parseInt(res.data.data.nbr_experience));
                    }, 500);
                    var items = [];
                    
                    
                    setValue("description", res.data.data.description);
                    
                }
            })
        }
    }, []);
    return (
        <div className="container dashboard-content mt-3 block-expert-inscription inscription2">
            {redirect}
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="section-block" id="basicform">
                    </div>
                    <Header active="2"/>
                    <div className="card">
                        <div className="card-body">
                            <div className={loderFrome}>
                                <span className='loading-login'></span>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} className="f1 row m-form-b2b">
                                <div className="col-md-12" id="block2">
                                    <div className="form-group">
                                        <div className="row ">                                    
                                            <div className="col-md-12" >
                                                <div className="form-group row">
                                                    <label className="col-md-6">Poste recherchée (Titre du poste désiré) <span className="star">*</span>:</label>    
                                                    <div className='col-md-6'>
                                                        <input className="form-control selectpicker" {...register("poste_recherche", { required: true })} placeholder="Veuillez saisir votre Poste recherchée" />
                                                        {errors.poste_recherche?<span className="error d-block text-transform-unset" >Le champ « Poste recherchée » est obligatoire</span>:<></>}
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <label className="col-md-4">Vous êtes a la recherche de ? <span className="star">*</span></label>
                                            <label className="col-md-4" onChange={clickMissionFreeLance}>
                                                <input  id="conduite__9876_o"  type="radio" value="freelancer"  {...register("demandeur_radio", { required: true })} />
                                                <label htmlFor="conduite__9876_o" className="d-inline">une mission en FreeLance ? </label>
                                            </label>
                                            <label className="col-md-4" onChange={clickEmploi}>
                                                <input id="conduite__9874_o"  type="radio" value="demandeurdemploi"  {...register("demandeur_radio", { required: true })} />
                                                <label htmlFor="conduite__9874_o" className="d-inline">un emploi ?</label>
                                            </label>
                                        </div>
                                        {errors.demandeur_radio?<span className="error d-block text-transform-unset" >Le champ « Vous êtes a la recherche de ? » est obligatoire</span>:<></>}
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <label className="col-md-4">Actuellement, vous êtes en poste ? <span className="star">*</span></label>
                                            <label className="col-md-4 w-50" onChange={clickEnPosteOui}>
                                                <input id="conduite__9873_o" type="radio" {...register("travail_radio", { required: true })} value="oui" />
                                                <label htmlFor="conduite__9873_o" className="d-inline">Oui</label>
                                            </label>
                                            <label className="col-md-4 w-50" onChange={clickEnPosteNon}>
                                                <input id="conduite__9877_o" type="radio" {...register("travail_radio", { required: true })} value="non"  />
                                                <label htmlFor="conduite__9877_o" className="d-inline">Non</label>
                                            </label>
                                        </div>
                                        {errors.travail_radio?<span className="error d-block text-transform-unset" >Le champ « Actuellement, vous êtes en poste ? » est obligatoire</span>:<></>}
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-6">Quel est votre niveau d’étude ? <span className="star">*</span></label>    
                                        <div className='col-md-6 '>
                                            <select className="form-control selectpicker bs-select-hidden" {...register("niveau_etude", { required: true })} style={{color: '#000', backgroundColor: '#fff', display: 'block !important'}}>
                                                <option value="">-Sélectionner-</option>
                                                {optionNiveauEtudes}
                                            </select>
                                            {errors.niveau_etude?<span className="error d-block text-transform-unset" >Le champ « Quel est votre niveau d’étude ? » est obligatoire</span>:<></>}
                                        </div>
                                    </div> 
                                    <div className={"form-group "+showEmploi} id="domaine_emploi">                                
                                        <p>Choisissez une catégorie qui corresponde à votre domaine d'activité.</p>
                                        {/* <div className="form-group row">
                                            <label className="col-md-6">Catégorie emploi:   <span className="star">*</span></label>
                                            <div className='col-md-6'>
                                                <select className="form-control bs-select-hidden" {...register("domaine", { required: (showEmploi==""?true:false) })} style={{color: '#000', backgroundColor: '#fff', display: 'block !important'}}>
                                                    <option value="">-Sélectionner-</option>
                                                    {optionDomainesTravail}
                                                </select>
                                                {errors.domaine?<span className="error d-block text-transform-unset" >Ce champ est obligatoire</span>:<></>}
                                            </div>
                                            
                                        </div> */}
                                        <div className="form-group row">
                                            <label className="col-md-6">Catégorie emploi (max 05 catégories):   <span className="star">*</span></label>
                                            <div className='col-md-6'>
                                                <div><input onChange={changeCategorie} value={searchCategorie} className="form-control input" placeholder='Catégorie emploi' type="text" autoComplete="off" /></div>
                                                
                                                {error_categories?
                                                <span className="error d-block text-transform-unset" >Le champ « Catégories » est obligatoire</span>:<></>}
                                                {texte_error_categories=="max5"?
                                                <span className="error d-block text-transform-unset" >Max 05 catégories</span>:<></>}
                                                <div id="responsecontainer" style={{maxHeight: '150px', overflow: 'auto',  width: '100%'}} >
                                                    {searchCategories} 
                                                </div>
                                            </div>

                                            <div  className='col-md-12' id="destinataires_container" align="center">
                                                {categories.map(c => {
                                                        return <span class="span_destinataire">{c.name}<b id={c.id} onClick={removeCategorie}>X</b></span>;
                                                })} 
                                            </div>
                                        </div>
                                       
                                        <label className="col-md-6 pl-0">Compétences Techniques :   <span className="star">*</span></label>
                   
                                        <div id="competences_div" style={{}}>
                                            <div id="competences_div_inner" className="row">
                                                { competences.map(c => {
                                                    return <Compitonse competence={c.competence} p={c.p} id={c.id} update={updateCompetence} />
                                                })}
                                            </div>
                                            {competenceserrormsg?<span className="error d-block text-transform-unset" >Le champ « Compétences Techniques » est obligatoire </span>:<></>}
                                            <button className="btn btn-add-formation" onClick={addCompetence} type="button">Ajouter une autre compétence</button>
                                            <button className="btn btn-remove-formation" onClick={removeCompetence} type="button">Supprimer</button>
                                        </div>
                                    </div>
                                    <div className={"form-group "+showFreeLance} id="domaine_freelance" >                                
                                        <p>Choisissez une catégorie correspondant à votre domaine d'activité.</p>
                                        <div className="form-group row">
                                            <label className="col-md-6">Domaine Freelance:<span className="star">*</span></label>  
                                            <div className="col-md-6">
                                                <select className="form-control bs-select-hidden" {...register("domaine_freelance", { required: (showFreeLance==""?true:false) ,onChange: (e) => {updateDomainesFreelance(e.target.value)} })}  style={{display: 'block !important', color: '#000', backgroundColor: '#fff'}}>
                                                    <option value="">-Sélectionner-</option>
                                                    {optionDomainesFreelance}
                                                </select>
                                                {errors.domaine_freelance?<span className="error d-block text-transform-unset" >Le champ « Domaine Freelance » est obligatoire</span>:<></>}
                                            </div>  
                                        </div>
                                    </div>
                                    <div className={"col-md-12 "+showFreeLance} id="competences_freelance" >                                
                                        <div className="form-group row">
                                            <label htmlFor="shortDescription3">Compétences Freelance:</label>
                                            <div id="responsecontainer" align="center">
                                                {optionCompetencesFreelance}
                                                <button type="button" className="btn btn-outline-secondary btn-min-width mr-1 mb-1 btn_not_clicked add_autre" name="add_autre" id="add_autre">Ajouter autre</button>
                                            </div>
                                            {errors_competences_freelance?<span className="error d-block text-transform-unset" >Le champ « Compétences Freelance » est obligatoire</span>:<></>}
                                            <div id="competences_container" align="center">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-6">Quel est votre niveau d'expérience professionnelle? <span className="star">*</span></label>    
                                        <div className='col-md-6'>
                                            <select className="form-control selectpicker bs-select-hidden" {...register("nbr_experience", { required: true })} style={{display: 'block !important', color: '#000', backgroundColor: '#fff'}}>
                                                <option value="">-Sélectionner-</option>
                                                {optionExperience}
                                            </select>
                                            {errors.nbr_experience?<span className="error d-block text-transform-unset" >-	Le champ « Niveau d'expérience professionnelle? » est obligatoire </span>:<></>}
                                        </div>
                                    </div> 
                                    <div className={"form-group "+showEmploi} id="div_type_emploi" >                                
                                        <div className="form-group row">
                                            <label className="col-md-6">Type de contrat souhaité ?   <span className="star">*</span> <span id="error_input_type_emploi" className="star" /></label>  
                                            
                                            <div className="row div_type_contrat w-100">
                                                {optionTypeContrat}
                                            </div>
                                            {errorsTypeContrat?<span className="error d-block text-transform-unset" style={{paddingLeft: "15px"}}>Le champ « Type de contrat souhaité » est obligatoire</span>:<></>}
                                        </div>
                                    </div> 
                                    <div className={"form-group "+showEmploi} id="div_disponibilite">
                                        <label>Disponibilité:  <span className="star">*</span> <span id="error_input_disponibilite" className="star" /></label>
                                        <div className="row">
                                            <div className="col-md-4 disponibilite">
                                                <button type="button" onClick={(e)=>{setDisponibilite("disponibilite_immediatement");setErrorsDisponibilite(false);}} className={"btn btn-outline-secondary btn-min-width mr-1 mb-1 "+(disponibilite=="disponibilite_immediatement"?"btn_clicked":"btn_not_clicked")} >
                                                Je suis disponible <br />immédiatement
                                                </button>
                                            </div>
                                            <div className="col-md-4 disponibilite">
                                                <button type="button" onClick={(e)=>{setDisponibilite("preavis");setErrorsDisponibilite(false);}} className={"btn btn-outline-secondary btn-min-width mr-1 mb-1 "+(disponibilite=="preavis"?"btn_clicked":"btn_not_clicked")}>
                                                Avec préavis
                                                </button>
                                                <input type="text" {...register("disponibilite_duree", { required: disponibilite=="preavis"?true:false })} placeholder="Durée" style={disponibilite=="preavis"?{display: 'block'}:{display: 'none'}} />
                                                {errors.disponibilite_duree?<span className="error d-block text-transform-unset" >Le champ « Avec préavis » est obligatoire</span>:<></>}
                                            </div>
                                            <div className="col-md-4 disponibilite">
                                                <button type="button" onClick={(e)=>{setDisponibilite("disponibilite_fin");setErrorsDisponibilite(false);}}  className={"btn btn-outline-secondary btn-min-width mr-1 mb-1 "+(disponibilite=="disponibilite_fin"?"btn_clicked":"btn_not_clicked")} >
                                                Fin du contrat
                                                </button>
                                                <input type="date" {...register("disponibilite_datefin", { required: disponibilite=="disponibilite_fin"?true:false })} min={new Date().getFullYear()+"-"+(new Date().getMonth()+1<10?"0"+(new Date().getMonth()+1):new Date().getMonth()+1)+"-"+(new Date().getDate()<10?"0"+new Date().getDate():new Date().getDate())} placeholder="Date de fin" style={disponibilite=="disponibilite_fin"?{display: 'block'}:{display: 'none'}} />
                                                {errors.disponibilite_datefin?<span className="error d-block text-transform-unset" >Le champ « Fin du contrat » est obligatoire</span>:<></>}
                                            </div>
                                            {errors_disponibilite?<span className="error d-block text-transform-unset" style={{paddingLeft: "15px"}}>Le champ « Disponibilité » est obligatoire</span>:<></>}
                                        </div>
                                    </div> 
                                    <div className={"row "+showEmploi} id="salaire_emploi" >                                    
                                        <div className={"col-md-6 salaire-actuel "+((enPoste=="oui")?"":"d-none") } style={{float: 'left'}}>
                                            <div className="form-group row">
                                                <label className="col-md-6">Salaire actuel<span className="star">*</span>:</label>  
                                                <div className=' col-md-6'> 
                                                <input className="form-control selectpicker mb-0" {...register("salaire_actuel", { required: ((showEmploi=="" && enPoste=="oui")?true:false) })}  />
                                                {errors.salaire_actuel?<span className="error d-block text-transform-unset" >Le champ « Salaire actuel » est obligatoire </span>:<></>}
                                                </div> 
                                            </div>
                                        </div>
                                        <div className="col-md-6 salaire-souhaite" style={{float: 'left'}}>
                                            <div className="form-group row">
                                                <label className="col-md-6">Salaire souhaité<span className="star">*</span>:</label>    
                                                <div className=' col-md-6'> 
                                                <select className="form-control selectpicker bs-select-hidden" {...register("salaire", { required: (showEmploi==""?true:false) })}  style={{display: 'block !important', color: '#000', backgroundColor: '#fff'}}>
                                                    <option value="">-Sélectionner-</option>
                                                    {optionSalaires}
                                                </select>
                                                {errors.salaire?<span className="error d-block text-transform-unset" >Le champ « Salaire souhaité » est obligatoire</span>:<></>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Présentation («À propos de moi») :  <span className="star">*</span></label>
                                        <textarea className="form-control" rows={14} {...register("description", { required: true })} placeholder="Veuillez saisir votre présentation..." />
                                        {errors.description?<span className="error d-block text-transform-unset" >Le champ « Présentation » est obligatoire</span>:<></>}
                                    </div>  
                                    <div className="custom-form row block-btn-precedent-etape-suivant">
                                        <div className="col-md-4">
                                            <Link to={"/"+i18next.language+"/expert/inscription"} className="btn btn-b2b suivant"  style={{float:"left"}}>Précédent</Link>
                                        </div>
                                        <div className="col-md-4">
                                            <span className="suivant-text text-center w-100">etape 2/3</span>
                                        </div>
                                        <div className="col-md-4">
                                            <input type="submit" name="ok" onClick={clickSubmit} value="Suivant" className="btn btn-b2b suivant"  style={{width:"auto",height:"auto"}} />
                                        </div>
                                        {/* <div className="col-md-12">
                                            {errors.poste_recherche?<span className="error d-block text-transform-unset" >Poste recherchée est obligatoire</span>:<></>}
                                            {errors.demandeur_radio?<span className="error d-block text-transform-unset" >"Vous êtes a la recherche de" est obligatoire</span>:<></>}
                                            {errors.travail_radio?<span className="error d-block text-transform-unset" >"Actuellement en poste" est obligatoire</span>:<></>}
                                            {errors.niveau_etude?<span className="error d-block text-transform-unset" >Niveau d’étude est obligatoire</span>:<></>}
                                            {errors.domaine_freelance?<span className="error d-block text-transform-unset" >Domaine Freelance est obligatoire</span>:<></>}
                                            {errors.nbr_experience?<span className="error d-block text-transform-unset" >Niveau d'expérience professionnelle est obligatoire</span>:<></>}
                                            {errors.disponibilite_datefin?<span className="error d-block text-transform-unset" >Disponibilité "Fin du contrat" est obligatoire</span>:<></>}
                                            {errors.disponibilite_duree?<span className="error d-block text-transform-unset" >Disponibilité "Avec préavis" est obligatoire</span>:<></>}
                                            {errors.salaire_actuel?<span className="error d-block text-transform-unset" >Salaire actuel est obligatoire</span>:<></>}
                                            {errors.salaire?<span className="error d-block text-transform-unset" >Salaire souhaité est obligatoire</span>:<></>}
                                            {errors.description?<span className="error d-block text-transform-unset" >Présentation est obligatoire</span>:<></>}
                                        </div> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>             
    );
}
