
import './styleSlider.css';
import React, { Component } from 'react';
import {b2bNameSite,urlFacebook,urlLinkedin,urlYoutube} from '../../config.json';
export default class pageFacebook extends Component
{
    render() {
        return ( 
            <div className='d-flex justify-content-center w-100'>
                <iframe src={"https://www.facebook.com/plugins/page.php?href="+urlFacebook+"&tabs=timeline&width="+this.props.width+"&height=900&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=721158435421125"} width={this.props.width+"px"} height="900" style={{"border":"none","overflow":"hidden"}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> 
            
            </div>
        );
    }
}



