import React, { Component } from 'react';

export default class Compitonse extends Component
{
    constructor (props) {
        super(props)
        this.state={
            competence:props.competence,
            competenceP:props.p
        }
    }
    ChangeCompetence = (e)=>{
        this.setState({competence:e.target.value});
        this.props.update(e.target.value,this.state.competenceP,this.props.id);
    }
    ChangeCompetenceP = (e)=>{
        this.setState({competenceP:e.target.value});
        this.props.update(this.state.competence,e.target.value,this.props.id);
    }
    render() {
       
        return ( <>
        <div className="block-competence_ col-md-4 m-0">
            <input  type="text" onChange={this.ChangeCompetence} value={this.state.competence} className="form-control input-competence" placeholder="Ajouter une compétence" />
            <input type="number" onChange={this.ChangeCompetenceP} value={this.state.competenceP}  min={1} max={100} defaultValue={100} className="form-control input-competence-p" placeholder={100} />
            <span>%</span>
        </div>
        </>);
    }
}
