
import './style.css';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Products from '../../services/products';
import { Markup } from 'react-render-markup';
import Select from 'react-select'
import MetaTags from 'react-meta-tags';
import Pagination from "react-js-pagination";
import i18next from 'i18next';
import { b2bNameSite, b2bDescriptionSite } from '../../config.json'
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga'
class Index extends Component {
    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
            items: [<ItemLoader />, <ItemLoader />, <ItemLoader />, <ItemLoader />, <ItemLoader />, <ItemLoader />, <ItemLoader />, <ItemLoader />],
            categorieoptions: [{ value: 0, label: '' }],
            entreprisesoptions: [{ value: 0, label: '' }],
            DataAllProduits: [],
            DataProduits: [],
            search_mot_cle: "",
            search_entreprises: 0,
            search_categorie: 0,
            activePage: 1,
            totalItemsCount: 0,
            itemsPerPage: 20,
            progressPage: 0,
            showProgressPage: true,
            l: localStorage.getItem("langb2b")
        }
        setInterval(() => {
            if (this.state.progressPage < 80) {
                this.setState({ progressPage: this.state.progressPage++ });
            }
        }, 100);

        var getFerst = false;
        Products.getProduitsAll(-1).then((res) => {
            if (res.data.get) {
                this.chargeProduits(res);
                this.filterProduits(this.state.search_mot_cle, this.state.search_categorie, this.state.search_entreprises);
                getFerst = true;
            }
        });
        Products.getProduitsAll(this.state.itemsPerPage).then((res) => {
            if (res.data.get && !getFerst) {
                this.chargeProduits(res);
                this.filterProduits(this.state.search_mot_cle, this.state.search_categorie, this.state.search_entreprises);

            }
        });
        Products.getCategories().then((res) => {
            console.log(res.data.data);
            if (res.data.get) {

                var items = [{ value: 0, label: "All Categorie" }];
                res.data.data.forEach(categorie => {
                    items.push({ value: categorie.id, label: categorie.name },);
                });
                this.setState({ categorieoptions: [] });
                this.setState({ categorieoptions: items });
            }
        });
        Products.getEntreprises().then((res) => {
            if (res.data.get) {
                var items = [{ value: 0, label: "All Entreprise" }];
                res.data.data.forEach(entreprise => {
                    items.push({ value: entreprise.id, label: entreprise.name },);
                });
                this.setState({ entreprisesoptions: [] });
                this.setState({ entreprisesoptions: items });
            }
        });
    }
    chargeProduits = (res) => {
        if (res.data.get) {
            var items = [];
            var DataProduits = [];
            res.data.data.forEach(produits => {

                if (produits.pack === "Business" && produits.img) {
                    DataProduits.push({
                        "id": produits.id,
                        "url": produits.url,
                        "img": produits.img,
                        "title": produits.title,
                        "en_title":produits.en_title,
                        "entreprise": produits.entreprise,
                        "urlEntreprise": produits.urlEntreprise,
                        "entreprise_id": produits.entreprise_id,
                        "entreprise_category": produits.entreprise_category,
                        "recherche_mot_cle": produits.recherche_mot_cle,
                        "pack": produits.pack
                    })
                    if (items.length < this.state.itemsPerPage) {
                        items.push(<Item
                            t={this.props.t}
                            key={produits.id}
                            id={produits.id}
                            url={produits.url}
                            img={produits.img}
                            title={(i18next.language == "en") ? produits.en_title :produits.title}
                            enteprise={produits.entreprise}
                            entepriseUrl={produits.urlEntreprise}
                        />);
                    }
                }
            });
            res.data.data.forEach(produits => {
                console.log("p : "+produits.p)
                if (produits.pack === "pro" && produits.img) {

                    DataProduits.push({
                        "id": produits.id,
                        "url": produits.url,
                        "img": produits.img,
                        "title": produits.title,
                        "en_title":produits.en_title,
                        "entreprise": produits.entreprise,
                        "urlEntreprise": produits.urlEntreprise,
                        "entreprise_id": produits.entreprise_id,
                        "entreprise_category": produits.entreprise_category,
                        "recherche_mot_cle": produits.recherche_mot_cle,
                        "pack": produits.pack
                    })
                    if (items.length < this.state.itemsPerPage) {
                        items.push(<Item
                            t={this.props.t}
                            key={produits.id}
                            id={produits.id}
                            url={produits.url}
                            img={produits.img}
                            title={(i18next.language == "en") ? produits.en_title :produits.title}
                            enteprise={produits.entreprise}
                            entepriseUrl={produits.urlEntreprise}
                        />);
                    }
                }
            });
            res.data.data.forEach(produits => {
                if (produits.pack === "gratuit" && produits.img) {
                    DataProduits.push({
                        "id": produits.id,
                        "url": produits.url,
                        "img": produits.img,
                        "title": produits.title,
                        "en_title":produits.en_title,
                        "entreprise": produits.entreprise,
                        "urlEntreprise": produits.urlEntreprise,
                        "entreprise_id": produits.entreprise_id,
                        "entreprise_category": produits.entreprise_category,
                        "recherche_mot_cle": produits.recherche_mot_cle,
                        "pack": produits.pack
                    })
                    if (items.length < this.state.itemsPerPage) {
                        items.push(<Item
                            t={this.props.t}
                            key={produits.id}
                            id={produits.id}
                            url={produits.url}
                            img={produits.img}
                            title={(i18next.language == "en") ? produits.en_title :produits.title}
                            enteprise={produits.entreprise}
                            entepriseUrl={produits.urlEntreprise}
                        />);
                    }
                }
            });
            res.data.data.forEach(produits => {
                if (!produits.img) {
                    DataProduits.push({
                        "id": produits.id,
                        "url": produits.url,
                        "img": produits.img,
                        "title": produits.title,
                        "en_title":produits.en_title,
                        "entreprise": produits.entreprise,
                        "urlEntreprise": produits.urlEntreprise,
                        "entreprise_id": produits.entreprise_id,
                        "entreprise_category": produits.entreprise_category,
                        "recherche_mot_cle": produits.recherche_mot_cle,
                        "pack": produits.pack
                    })
                    if (items.length < this.state.itemsPerPage) {
                        items.push(<Item
                            t={this.props.t}
                            key={produits.id}
                            id={produits.id}
                            url={produits.url}
                            img={produits.img}
                            title={(i18next.language == "en") ? produits.en_title :produits.title}
                            enteprise={produits.entreprise}
                            entepriseUrl={produits.urlEntreprise}
                        />);
                    }
                }
            });
            this.setState({ progressPage: 100 });
            setTimeout(() => {
                this.setState({ showProgressPage: false });
            }, 1000);
            this.setState({ items: [] });
            this.setState({ items: items });
            this.setState({ DataProduits: DataProduits });
            this.setState({ DataAllProduits: DataProduits });
            this.setState({ totalItemsCount: res.data.nbposts });
        }
    }
    filterProduits = (mot_cle, categorie, entreprise) => {
        categorie = parseInt(categorie);
        entreprise = parseInt(entreprise);
        
        this.setState({
            activePage: 1,
            items: []
        });
        var DataProduits = [];
        if (mot_cle != "" && categorie != 0 && entreprise != 0) { //111
            this.state.DataAllProduits.forEach(produit => {
                const title = (i18next.language == "en") ? produit.en_title.toLowerCase() :produit.title.toLowerCase();
                const serche = mot_cle != "" ? mot_cle.toLowerCase() : "";
                if (produit.entreprise_category.includes(categorie) && produit.entreprise_id == entreprise && title.indexOf(serche) != -1) {
                    DataProduits.push(produit);
                }
            });
        } else if (categorie != 0 && entreprise != 0) {//110
            this.state.DataAllProduits.forEach(produit => {
                if (produit.entreprise_category.includes(categorie) && produit.entreprise_id == entreprise) {
                    DataProduits.push(produit);
                }
            });
        } else if (mot_cle != "" && entreprise != 0) {//101
            this.state.DataAllProduits.forEach(produit => {
                const title = (i18next.language == "en") ? produit.en_title.toLowerCase() :produit.title.toLowerCase();
                const serche = mot_cle != "" ? mot_cle.toLowerCase() : "";
                if (title.indexOf(serche) != -1 && produit.entreprise_id == entreprise) {
                    DataProduits.push(produit);
                }
            });
        } else if (mot_cle != "" && categorie != 0) {//011
            this.state.DataAllProduits.forEach(produit => {
                const title = (i18next.language == "en") ? produit.en_title.toLowerCase() :produit.title.toLowerCase();
                const serche = mot_cle != "" ? mot_cle.toLowerCase() : "";
                if (title.indexOf(serche) != -1 && produit.entreprise_category.includes(categorie)) {
                    DataProduits.push(produit);
                }
            });
        } else if (categorie != 0) {//010
            this.state.DataAllProduits.forEach(produit => {
                if (produit.entreprise_category.includes(categorie)) {
                    DataProduits.push(produit);
                }
            });
        } else if (mot_cle != "") {//001
            this.state.DataAllProduits.forEach(produit => {
                const title = (i18next.language == "en") ? produit.en_title.toLowerCase() :produit.title.toLowerCase();
                const serche = mot_cle != "" ? mot_cle.toLowerCase() : "";
                if (title.indexOf(serche) != -1) {
                    DataProduits.push(produit);
                }
            });
        } else if (entreprise != 0) {//100
            this.state.DataAllProduits.forEach(produit => {
                if (produit.entreprise_id == entreprise) {
                    DataProduits.push(produit);
                }
            });
        } else {
            DataProduits = this.state.DataAllProduits;
        }
        var items = [];

        this.state.DataAllProduits.forEach(produit => {
            var produitsponsoriser = false;
            if (produit.recherche_mot_cle) {
                produit.recherche_mot_cle.forEach(cle => {
                    if (mot_cle == cle) {
                        produitsponsoriser = true;
                    }
                });
            }


            if (produitsponsoriser) {
                items.push(<Item
                    t={this.props.t}
                    sponsoriser={true}
                    key={produit.id}
                    id={produit.id}
                    url={produit.url}
                    img={produit.img}
                    title={(i18next.language == "en") ? produit.en_title :produit.title}
                    enteprise={produit.entreprise}
                    entepriseUrl={produit.urlEntreprise}
                />);
            }
        });
        DataProduits.forEach(produit => {
            if (items.length < this.state.itemsPerPage) {
                items.push(<Item
                    t={this.props.t}
                    key={produit.id}
                    sponsoriser={true}
                    id={produit.id}
                    url={produit.url}
                    img={produit.img}
                    title={(i18next.language == "en") ? produit.en_title :produit.title}
                    enteprise={produit.entreprise}
                    entepriseUrl={produit.urlEntreprise}
                />);
            }
        });

        this.setState({
            items: items,
            DataEntreprises: DataProduits,
            totalItemsCount: DataProduits.length
        });
    }
    changeSearch = (e) => {
        this.setState({
            search_mot_cle: e.target.value
        });
        this.filterProduits(e.target.value, this.state.search_categorie, this.state.search_entreprises);
    }
    changeSearchCategories = (e) => {
        this.setState({
            search_categorie: e.value
        });
        this.filterProduits(this.state.search_mot_cle, e.value, this.state.search_entreprises);
    }
    changeSearchEntreprises = (e) => {
        this.setState({
            search_entreprises: e.value
        });
        this.filterProduits(this.state.search_mot_cle, this.state.search_categorie, e.value);
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.initItems();
        const items = [];
        var i = 0;
        this.state.DataProduits.forEach(produit => {
            if (items.length < this.state.itemsPerPage && i >= (pageNumber * this.state.itemsPerPage - this.state.itemsPerPage)) {
                items.push(<Item
                    t={this.props.t}
                    key={produit.id}
                    id={produit.id}
                    url={produit.url}
                    img={produit.img}
                    title={(i18next.language == "en") ? produit.en_title :produit.title}
                    enteprise={produit.entreprise}
                    entepriseUrl={produit.urlEntreprise}
                />);
            }
            i++;
        });
        this.setState({ items: [] });
        this.setState({ items: items });
    }
    initItems() {
        window.scrollTo(0, 0);
        this.setState({
            items: [
                <ItemLoader />,
                <ItemLoader />,
                <ItemLoader />,
                <ItemLoader />,
                <ItemLoader />,
                <ItemLoader />,
                <ItemLoader />,
                <ItemLoader />
            ]
        });
    }
    //****************************** Load update of change language ***************
    componentDidUpdate() {
        if (this.state.l !== localStorage.getItem("langb2b")) {
            var getFerst = false;
            Products.getProduitsAll(-1).then((res) => {
                if (res.data.get) {
                    this.chargeProduits(res);
                    this.filterProduits(this.state.search_mot_cle, this.state.search_categorie, this.state.search_entreprises);
                    getFerst = true;
                }
            });
            Products.getProduitsAll(this.state.itemsPerPage).then((res) => {
                if (res.data.get && !getFerst) {
                    this.chargeProduits(res);
                    this.filterProduits(this.state.search_mot_cle, this.state.search_categorie, this.state.search_entreprises);

                }
            });
            this.setState({
                l: localStorage.getItem("langb2b")
            })
        }
    }
    render() {
        const { t } = this.props;
        return (
            <>
                {this.state.showProgressPage ? <div class="progress-div" style={{ width: "100%" }}><div style={{ width: this.state.progressPage + "%" }} class="progress"></div></div> : <></>}
                <MetaTags>
                    <title>{t("Produits et Services")} | {b2bNameSite} | {localStorage.getItem("langb2b") === "en" ? "B2B marketplace and B2B networking platform in Tunisia and abroad" : b2bDescriptionSite}</title>
                </MetaTags>
                <div className="page-content liste-produits">
                    <section id="list-places-wrapper" >
                        <div className="container">
                            <div className="col-md-12 widget de_widget_categories prod-list" id="search-places">
                                <form role="search" className="place_search_form" action="" method="GET">
                                    <div className="row block-search-all pr-3">
                                        <div className="row col-md-12 de-search-form" id="search-places" style={{ "margin-left": "0px" }}>
                                            <div className="col-md-6 block-search-keyword">
                                                <div className="de-scontrol">
                                                    <input type="text" name="mot_cle" onChange={this.changeSearch} className="keyword-search" placeholder={t("Entrez un mot-clé") + "..."} value={this.state.search_mot_cle} />
                                                </div>
                                            </div>
                                            <div className="col-md-3 block-search-entreprises">
                                                <div className="de-scontrol">
                                                    <Select options={this.state.entreprisesoptions} placeholder={t("Toutes les entreprises")} onChange={this.changeSearchEntreprises} />
                                                </div>
                                            </div>
                                            <div className="col-md-3  block-search-categorie">
                                                <div className="de-scontrol">
                                                    <Select options={this.state.categorieoptions} placeholder={t("Toutes les catégories")} onChange={this.changeSearchCategories} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-1 block-search-btn" style={{"padding-right":"0"}}>
                                        <button className="btn btn-primary" type="button" onClick={this.submitSearch} style={{"background-color":"#ED2829","border":"none","height":"65px","width": "100%"}}>
                                            <i className="fa fa-search fa-lg"></i>
                                        </button>
                                    </div> */}
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-12 widget de_widget_categories prod-list" style={{ "margin-bottom": "10px", "text-transform": "uppercase", "text-align": "center" }}><h3></h3></div>
                            <ul className="list-places list-prodact list-posts row" style={{ "list-style": "none" }}>
                                {this.state.items}
                            </ul>
                            <div className="paginations-wrapper">
                                <div className="paginations">
                                    {this.state.totalItemsCount > this.state.itemsPerPage ?
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.itemsPerPage}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                        : <></>}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}


export default withTranslation()(Index);


class Item extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: props.id,
            entreprise_name: props.enteprise,
            entreprise_url: props.entepriseUrl,
            produit_name: props.title,
            produit_url: props.url,
            produit_image: props.img,
        }
    }

    render() {
        const { t } = this.props;
        return (
            <li className="block-company-mobile place-item post-1809 place type-place status-publish has-post-thumbnail hentry place_category-industrie location-tunis col-md-3 col-xs-6" style={{ "padding-left": "0", "padding-right": "10px" }}>
                <div className="place-wrapper" style={{ "box-shadow": "none", "border": "1px solid #a9aaaa", "padding": "5px", "background-color": "white" }}>
                    <div className="hidden-img" style={{ "border": "1px solid #a9aaaa42" }}>
                        <NavLink exact={true} to={"/" + i18next.language + this.state.produit_url} className="img-place" title={this.state.produit_name}>
                            <img onContextMenu={(e) => e.preventDefault()} className="lazy" src={this.state.produit_image} style={{ "display": "block", "height": "150px", "width": "auto", "margin": "0 auto" }} />
                        </NavLink>
                    </div>
                    <div className="place-detail-wrapper details-list" style={{ "height": "130px" }}>
                        <NavLink exact={true} to={"/" + i18next.language + this.state.produit_url} title={this.state.produit_name}>
                            <h2 className="title-place2 title-produit-liste mb-0" style={{ "height": "64px" }}><Markup markup={this.state.produit_name} /></h2>
                        </NavLink>
                        <div style={{ "text-align": "center", "height": "35px", "color": "#7b7b7c" }}>
                            <NavLink exact={true} to={"/" + i18next.language + this.state.entreprise_url} style={{ "color": "#7b7b7c" }}>
                                <i className="fa fa-building"></i><Markup markup={this.state.entreprise_name} />
                            </NavLink>
                        </div>
                        {/* <p style={{"width":"100%","font-weight":"300","padding-left":"5px","text-align":"center"}}></p> */}
                        <NavLink className="btn-demandez-un-devis" exact={true} to={"/" + i18next.language + this.state.produit_url + "?devis-produit=true"} >{t("Demandez un Devis")} </NavLink>
                    </div>
                </div>
            </li>
        );
    }
}
class ItemLoader extends Component {
    render() {

        return (
            <li className="block-company-mobile place-item post-1809 place type-place status-publish has-post-thumbnail hentry place_category-industrie location-tunis col-md-3 col-xs-6" style={{ "padding-left": "0", "padding-right": "10px" }}>
                <div className="place-wrapper" style={{ "box-shadow": "none", "border": "1px solid #a9aaaa", "padding": "5px", "background-color": "white" }}>
                    <div className="hidden-img" style={{ "border": "1px solid #a9aaaa42" }}>
                        <div className="img-place loader-entreprise-image" ><p></p></div>
                    </div>
                    <div className="place-detail-wrapper details-list" style={{ "height": "130px" }}>
                        <div className="title-place notranslate loader-entreprise-title"><p></p></div>
                        <div style={{ "text-align": "center", "height": "35px", "color": "#7b7b7c" }}>
                            <a href="#" style={{ "color": "#7b7b7c" }}>
                                <i className="fa fa-building"></i><span className="loader-entreprise-tel-add-prod"></span>
                            </a>
                        </div>
                        <p style={{ "width": "100%", "font-weight": "300", "padding-left": "5px", "text-align": "center" }}></p>
                        <a className="btn-demandez-un-devis" href="#" >Demandez un Devis </a>
                    </div>
                </div>
            </li>
        );
    }
}
