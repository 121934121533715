import http from "./httpService";
import { apiUrl } from  "../config.json";

const getOffresDemploi = (paged=1,mot_cle="",domaine=0,type_emploi=[],experiences=[],niveau_etudes=[],regions=[],disponibilie=[],remunerationProposee=[],langue=[]) => {
    var apiEndoint =  apiUrl+"/offresDemploi/getOffresDemploi.php?paged="+paged+"&mot_cle="+mot_cle+"&domaine="+domaine;
    if(type_emploi.length>0){
        type_emploi.forEach(element => {
            apiEndoint = apiEndoint+"&type_emploi%5B%5D="+element;
        });
    }
    if(experiences.length>0){
        experiences.forEach(element => {
            apiEndoint = apiEndoint+"&experience%5B%5D="+element;
        });
    }
    if(disponibilie.length>0){
        disponibilie.forEach(element => {
            apiEndoint = apiEndoint+"&disponibilite%5B%5D="+element;
        });
    }
    if(niveau_etudes.length>0){
        niveau_etudes.forEach(element => {
            apiEndoint = apiEndoint+"&niveau_etude%5B%5D="+element;
        });
    }
    if(regions.length>0){
        regions.forEach(element => {
            apiEndoint = apiEndoint+"&gouvernorat%5B%5D="+element;
        });
    }
    if(remunerationProposee.length>0){
        remunerationProposee.forEach(element => {
            apiEndoint = apiEndoint+"&salaire%5B%5D="+element;
        });
    }
    
    if(langue.length>0){
        langue.forEach(element => {
            apiEndoint = apiEndoint+"&langue%5B%5D="+element;
        });
    }
    return http.get(apiEndoint);
}
const registerMessage = (entrepriseid,entrepriseuser,offreid,data) => {
    const apiEndoint =  apiUrl+"/security/registerMessageOffresDemploi.php";
    const formData = new FormData();
    formData.append("entrepriseid", entrepriseid);
    formData.append("entrepriseuser", entrepriseuser);
    formData.append("offreid", offreid);
    formData.append("token", localStorage.getItem("tokenb2b"));
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return http.post(apiEndoint,formData);
}
const getDetail = (slug) => {
    const apiEndoint =  apiUrl+"/offresDemploi/getDetail.php?slug="+slug;
    return http.get(apiEndoint);
}


export default 
{
    getOffresDemploi,
    getDetail,
    registerMessage
}