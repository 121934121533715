import http from "./httpService";
import { apiUrl } from  "../config.json";

const getEvenementsPasses = (paged=1,lieu=0,mot_cle="") => {
    const apiEndoint =  apiUrl+"/evenements/getEvenementsPasses.php?paged="+paged+"&mot_cle="+mot_cle+"&lieu="+lieu;
    return http.get(apiEndoint);
}
const getEvenementsEnCours = (paged=1,lieu=0,mot_cle="") => {
    const apiEndoint =  apiUrl+"/evenements/getEvenementsEnCours.php?paged="+paged+"&mot_cle="+mot_cle+"&lieu="+lieu;
    return http.get(apiEndoint);
}
const getEvenementsVenir = (paged=1,lieu=0,mot_cle="") => {
    const apiEndoint =  apiUrl+"/evenements/getEvenementsVenir.php?paged="+paged+"&mot_cle="+mot_cle+"&lieu="+lieu;
    return http.get(apiEndoint);
}
const getDetail = (slug) => {
    const apiEndoint =  apiUrl+"/evenements/getDetail.php?slug="+slug;
    return http.get(apiEndoint);
}
const getLieus = () => {
    const apiEndoint =  apiUrl+"/evenements/getLieux.php";
    return http.get(apiEndoint);
}

const participe = (titre,description,start_date_periode,start_time_periode,end_date,end_time) => {
    const apiEndoint =  apiUrl+"/evenements/participe.php";
    const formData = new FormData();
    formData.append("titre", titre);
    formData.append("description", description);
    formData.append("start_date_periode", start_date_periode);
    formData.append("start_time_periode", start_time_periode);
    formData.append("end_date", end_date);
    formData.append("end_time", end_time);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
export default 
{
    getLieus,
    getDetail,
    getEvenementsPasses,
    getEvenementsEnCours,
    getEvenementsVenir,
    participe
}