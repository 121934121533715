import React, { Component } from 'react';

const Detail = () => {
    return (
        <Index />
    );
}

export default Detail;

class Index extends Component {
    render() {
        return (
            <div>
                
            </div>
        );
    }
}

