import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './conference.css';
class Compteur  extends Component {
    constructor (props) {
        super(props); 
        this.state = {
            resrt_j :this.props.data.resrtdiff.d,
            resrt_h :this.props.data.resrtdiff.h,
            resrt_m :this.props.data.resrtdiff.i,
            resrt_s :this.props.data.resrtdiff.s,
            date_encours : this.props.data.date_encours,
        }
        setInterval(() => {
            var s = this.state.resrt_s;
            var i = this.state.resrt_m;
            var h = this.state.resrt_h;
            var j = this.state.resrt_j;
            s--;
            if(s==0 && i==0 && h==0 && j==0) this.setState({date_encours:true})
            if(s<=0){s = 60; i--;}
            if(i<=0 && this.props.data.resrtdiff.m!=0){i = 60; h--;}
            if(h<=0 && this.props.data.resrtdiff.h!=0){h = 24; j--;}
            // if(j<=0){j = 30; m--;}
            // if(m<=0){m = 12; a--;}
            this.setState({
                resrt_j :j,
                resrt_h :h,
                resrt_m :i,
                resrt_s :s,
            })
        }, 1000);
    }
    render() {
        const {t} = this.props
        return (
            <div className='date-resrt'>
            {this.props.data.date_expirer?<>
                <div className='date-resrt-expirer'>{t('Expiré')}</div>
            </>:<>
                {this.state.date_encours?<>
                    <div className='date-resrt-encours'>{t('En cours')}</div>
                </>:<>
                    <div className='date-resrt-deff'>
                        {(this.state.resrt_j>0)?<>
                        <div className='date-resrt-jhms'>{this.state.resrt_j} {t('jours')}</div>
                        </>:<></>}
                        <div className='date-resrt-jhms'>{this.state.resrt_h}h</div>
                        <div className='date-resrt-jhms'>{this.state.resrt_m}m</div>
                        <div className='date-resrt-jhms'>{this.state.resrt_s}s</div>
                    </div>
                </>}
            </>}
            </div>
        );
    }
}

export default withTranslation()(React.memo(Compteur))