import React, { Component } from 'react';
import Enteprises from '../../services/enteprises';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import './detail.css';
import { withTranslation } from 'react-i18next';
class BlockProduits extends Component {
    constructor(props) {
        super(props)
        var ComponentProduits = [];
        this.props.data.produits.forEach(produit => {
            if (produit.thumbnail) {
                ComponentProduits.push(<ItemProduit t={props.t} produit={produit} />);
            }
        });
        this.state = {
            produits: ComponentProduits
        }
    }
    render() {
        const { t } = this.props;
        return (
            <div className="list-single-main-item">
                <div className="list-single-main-item-title fl-wrap">
                    <h3 className="size-titre-b2b grie-b2b">{t("LES PRODUITS ET LES SERVICES DE")} <span className="place_post_title">{this.props.data.post_title}</span> :</h3>
                </div>
                <div className=" product-list no-padding" width="100%">
                    <ul className="list-places list-posts row" style={{ listStyle: 'none', width: '100%', margin: 0 }}>
                        {this.state.produits}
                    </ul>
                </div>
            </div>
        );
    }
}
export default withTranslation()(BlockProduits);
class ItemProduit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            produit: props.produit
        }
    }
    render() {
        const { t } = this.props;
        return (
            <li className="place-item post-1809 place type-place status-publish has-post-thumbnail hentry place_category-industrie location-tunis col-md-3 col-xs-12" style={{ paddingLeft: 0, paddingRight: '10px' }}>
                <div className="place-wrapper" style={{ boxShadow: 'none', border: '1px solid #a9aaaa', padding: '5px', backgroundColor: 'white' }}>
                    <div className="hidden-img" style={{ border: '1px solid #a9aaaa42' }}>
                        <Link to={"/" + i18next.language + this.state.produit.permalink} className="img-place" title={this.state.produit.post_title}>
                            <img onContextMenu={(e) => e.preventDefault()} className="lazy" src={this.state.produit.thumbnail} style={{ display: 'inline' }} />
                        </Link>
                    </div>
                    <div className="place-detail-wrapper details-list" style={{ height: '150px' }}>
                        <Link to={"/" + i18next.language + this.state.produit.permalink} title={this.state.produit.post_title} >
                            <h2 className="title-prode-liste">{this.state.produit.post_title}</h2>
                        </Link>
                        <br />
                        <div className="div-btn-prodact-devi">
                            <Link to={"/" + i18next.language + this.state.produit.permalink} style={{ float: 'left' }} className="btn-prodact-devi btn-b2b">{t("Consulter")}</Link>
                            <Link class="btn-prodact-devi btn-b2b" style={{ "float": "right", "margin-right": "10px" }} to={"/" + i18next.language + this.state.produit.permalink + "?devis-produit=true"}>{t("Devis")}</Link>
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}
