import './style.css';
import React, { Component } from 'react';
import Auth from '../../../services/Auth'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import i18next from 'i18next';
import { useEffect } from "react";
import { useState } from "react";

export default class Index extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            isLogin:true
        }
        Auth.isLogin().then((res)=>{
            if(res.data!=false){
                this.setState({isLogin:true});
            }else{
                this.setState({isLogin:false});
            }
        });
        window.scrollTo(0, 0);
    }
    render() {
        if (this.state.isLogin) {
            return ( <Inscription/> );
        } else {
            return ( <Redirect to={"/"+i18next.language}/> );
        }
    }
}
function Inscription() {       
    const [message, setMessage] = useState(true);
    const sendMail =()=>{
        Auth.inscriptionExpertMail();
        setMessage(false);
    }

    return (
        <div className="dashboard-wrapper page-inscription">
            <div className="container dashboard-content">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5 mb-5">
                         <div className="card message-sind-fin"> 
                            <figure>
                                <img onContextMenu={(e)=> e.preventDefault()} src="/assets/upload/image/mail.png" className="message-sind-img" style={{width:"100px"}} alt="Email"/>
                            </figure>
                            <label>Plus qu’une étape !</label>
                            <p className="message-sind-text-1">Veuillez vérifier votre boite email, afin de confirmer votre adresse email.</p>
                            {message?<p className="message-sind-text-2">Vous n'avez pas encore reçu l'email de confirmation, merci de <span onClick={sendMail} style={{color:"red",cursor:"pointer"}}>cliquez ici</span> pour recevoir à nouveau le lien de confirmation. </p>:<></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}




