import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Devis from "../../../services/devis"
import {prefixe} from "../../../config.json"
export default function ModalDevi(props) {
    const [parent, setParent] = useState(props.parent);
    const [prodId, setProdId] = useState(props.IdProd);
    const [optiondevipays, setOptiondevipays] = useState(null);
    const [showsociete, setShowsociete] = useState("d-none");
    const [minimale, setMinimale] = useState(props.minimale);
    const [loderFrome, setLoderFrome] = useState("d-none");
    const [sundDevis, setSundDevis] = useState(false);
    const [showactivite, setShowactivite] = useState("");
    const t = new Date();
    const date = ('0' + t.getDate()).slice(-2);
    const month = ('0' + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();
    const [time, setTime] = useState(`${year}-${month}-${date}`);
    const { register, handleSubmit, reset , formState: { errors } } = useForm();
    const onSubmit = (data) => 
    {
        setLoderFrome("loder-from");
        
        Devis.registerDevieOffline(data,prodId,parent).then((res)=>{
            if(res.data){
               reset(); 
               setSundDevis(true);
            }
            setLoderFrome("d-none");
        });
    }
    useEffect(() => {
        Devis.getCountry().then((res)=>{
            if(res.data.get){
                var countrys = [];
                res.data.data.forEach(country => {
                    if(parseInt(country.code) == parseInt(prefixe)){
                        countrys.push(<option selected value={country.code}>{country.name}</option>);
                    }else{
                        countrys.push(<option value={country.code}>{country.name}</option>);
                    }
                    
                });
                setOptiondevipays(countrys);
            }
        });
    }, []);
    const chingeDeviEtes =(e)=>{
        if(e.target.value==1){
            setShowsociete("d-none");
            setShowactivite("");
        }else{
            setShowsociete("");
            setShowactivite("d-none");
        }
    }
    return (
        <div className="connxtion_body" style={{position:"relative"}}>
            <div className={loderFrome}>
                <span className='loading-login'></span>
            </div>
            <div className="content-ihc">
                {sundDevis?
                <div className="sund-form-sucsses">
                    <span>Votre demande de devis a été enregistrée avec succès</span>
                </div>:<></>}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_etes">Vous êtes* :</label>
                            <select {...register("devi_etes")} className="form-control" onChange={chingeDeviEtes}>
                                <option value="1">Un particulier</option>
                                <option value="2">Une entreprise</option>
                            </select>
                            {errors.devi_etes?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <div className={showactivite}>
                                <label htmlFor="devi_activite">Activité* :</label>
                                <input {...register("activite", { required: showactivite==""?true:false })} className="form-control" />
                                {errors.activite?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                            </div>
                            <div className={showsociete}>
                                <label htmlFor="devi_nom_societe">Nom de la société* :</label>
                                <input {...register("nom_societe", { required: showsociete==""?true:false })} className="form-control" />
                                {errors.nom_societe?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                            </div>
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_nom">Nom* :</label>
                            <input {...register("nom", { required: true })} className="form-control" />
                            {errors.nom?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_prenom">Prénom* :</label>
                            <input {...register("prenom", { required: true })} className="form-control" />
                            {errors.prenom?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_email">Email* :</label>
                            <input type="email" {...register("email", { required: true })} className="form-control" />
                            {errors.email?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_telephone">Téléphone* :</label>
                            <input type="tel" {...register("telephone", { required: true , maxLength: 10 })} className="form-control" />
                            {errors.telephone?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_pays">Pays* :</label>
                            <select {...register("devi_pays")} className="form-control">
                                {optiondevipays}
                            </select>
                            {errors.devi_pays?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_ville">Ville* :</label>
                            <input {...register("ville", { required: true })}  className="form-control" />
                            {errors.ville?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_adresse">Adresse* :</label>
                            <input {...register("adresse", { required: true })} className="form-control" />
                            {errors.adresse?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_code_postal">Code postal* :</label>
                            <input {...register("code_postal", { required: true })} className="form-control" />
                            {errors.code_postal?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_date_livraison">Date de livraison* :</label>
                            <input type="date" {...register("date_livraison", { required: true })} min={time} className="form-control" />
                            {errors.date_livraison?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="devi_quantite">Quantité * :</label>
                            <input type="number" {...register("quantite", { required: true,min:minimale })} className="form-control" />
                            {errors.quantite?<span className="error d-block">Ce champ est obligatoire et Quantité minimale {minimale}</span>:<></>}
                        </div>
                        <div className="col-md-12 form-group">
                            <label htmlFor="devi_pays">Message* :</label>
                            <textarea className="form-control" {...register("message", { required: true })}/>
                            {errors.message?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                    </div>
                    
                    <input type="submit" className="btn btn-b2b"/>
                </form>
            </div>
        </div>
    );
}
