
import React, { Component, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import Enteprises from '../../services/enteprises';
import AliceCarousel from 'react-alice-carousel';
import Slider from '../Slider/SliderEnVedette';
import BtnPartage from '../Slider/btnPartage';
import Login from '../header/loginheader';
import Modal, { closeStyle } from 'simple-react-modal';
import BlockProduits from './produits';
import { prefixe, b2bNameSite, backUrl, lengthTel, b2bDescriptionSite } from '../../config.json';
import Auth from '../../services/Auth'
import jwt_decode from "jwt-decode";
import MetaTags from 'react-meta-tags';
import ReactPlayer from "react-player";
import { Link, NavLink } from 'react-router-dom';
import './detail.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useParams } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import i18next from 'i18next';
import PageVisite from '../Slider/pageVisite';
import Myb2b from '../../services/myb2b';
import { withTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import ReactGA from 'react-ga'
import ModalContact from './ModalContact';
import parse from 'react-html-parser'
function Detail(props) {
    let { slug } = useParams();
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    const { t } = props;
    return (
        <Index slug={slug} t={t} offset={offset} />
    );
}
export default withTranslation()(Detail);
class Index extends Component {
    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        var userLogin = false;
        var dataUser = null;
        const tokenb2b = localStorage.getItem('tokenb2b');
        if (tokenb2b && tokenb2b != "") {
            userLogin = true;
            dataUser = jwt_decode(tokenb2b);
        }
        const urlParams = new URLSearchParams(window.location.search);
        var reclamerb2b = false;
        if (urlParams.get('reclamerb2b') && urlParams.get('reclamerb2b') == 'true') {
            reclamerb2b = true;
        }

        super(props)
        this.state = {
            reclamerb2b: reclamerb2b,
            slug: props.slug,
            userLogin: userLogin,
            dataUser: dataUser,
            blockmenu: <BlockPresentation t={this.props.t} data={null} userLogin={userLogin} />,
            catalogue: false,
            partenaires: true,
            produits: 5,
            data: null,
            htmlModaleYoutube: <></>,
            modaleyoutube: false,
            youtube_key: "tN4ZIkb9Am0",
            sue_menu_entreprise_b2b: {},
            modaleLogin: false,
            modaleLogincontact: false,
            htmlModaleLogin: <></>,
            enteprisesAussi: <LoderBlockEntreprisesAussi />,
            l: localStorage.getItem("langb2b")
        }
        Auth.isLogin().then((res) => {
            if (res.data != false) {
                this.setState({
                    userLogin: true,
                    dataUser: res.data
                });
            } else {
                this.setState({
                    userLogin: false,
                    dataUser: null
                });
            }
        });

        Enteprises.getDetail(this.state.slug).then((res) => {
            if (res.data.get) {
                
                this.setState({
                    data: res.data.data,
                    blockmenu: <BlockPresentation t={this.props.t} data={res.data.data} userLogin={this.state.userLogin} />,
                    produits: res.data.data.nb_produit
                });
                if (res.data.data.pdf != "") {
                    this.setState({ catalogue: true });
                }
                if (res.data.data.partenaires) {
                    this.setState({ partenaires: true });
                }
                setTimeout(() => {
                    if (res.data.data.youtube_video_id != "") {
                        this.setState({ youtube_key: res.data.data.youtube_video_id });
                    }
                    if (res.data.data.entreprises_aussi != "") {
                        this.setState({ enteprisesAussi: <BlockEntreprisesAussi t={this.props.t} data={res.data.data} /> });
                    }

                }, 2000);

            }
        });


    }

    scrollMenu = () => {
        var win = window, doc = document, docElem = doc.documentElement, body = doc.getElementsByTagName('body')[0], x = win.innerWidth || docElem.clientWidth || body.clientWidth, y = win.innerHeight || docElem.clientHeight || body.clientHeight;
        window.scrollTo(0, 300);
        document.getElementById("header_b2b").style.display = "none";
        this.setState({
            sue_menu_entreprise_b2b: {
                position: "fixed",
                zIndex: "999999",
                top: "0px",
                left: "0px",
                width: "100%",
                backgroundColor: "#fff",
            }
        })
        if (x < 700) {
            window.scrollTo(0, 850);
        } else {
            window.scrollTo(0, 300);
        }
    }
    selectItem = (e) => {
        var items = document.getElementsByClassName("active-item");
        var i;
        for (i = 0; i < items.length; i++) {
            items[i].classList.remove("active-item");
        }
        e.target.classList.add('active-item');
        this.scrollMenu();
    }
    presentation = (e) => {
        this.selectItem(e);
        this.setState({
            blockmenu: <BlockPresentation t={this.props.t} data={this.state.data} userLogin={this.state.userLogin} />
        });
    }
    catalogue = (e) => {
        this.selectItem(e);
        this.setState({
            blockmenu: <BlockCatalogue t={this.props.t} data={this.state.data} userLogin={this.state.userLogin} />
        });
    }
    // chiffres = (e)=>{
    //     this.selectItem(e);
    //     this.setState({
    //         blockmenu : <BlockChiffres data={this.state.data} />
    //     });
    // }
    activites = (e) => {
        this.selectItem(e);
        this.setState({
            blockmenu: <BlockActivites t={this.props.t} data={this.state.data} />
        });
    }
    produits = (e) => {
        if (document.getElementById("header_b2b")) document.getElementById("header_b2b").style.display = "none";
        this.selectItem(e);
        setTimeout(() => {
            document.getElementById('produits').scrollIntoView();
        }, 10);
    }
    partenaires = (e) => {
        this.selectItem(e);
        this.setState({
            blockmenu: <BlockPartenaires t={this.props.t} data={this.state.data} />
        });
    }
    contact = (e) => {
        this.selectItem(e);
        this.setState({
            blockmenu: <BlockContact t={this.props.t} data={this.state.data} userLogin={this.state.userLogin} dataUser={this.state.dataUser} showModaleLogin={this.showModaleLogin} />
        });
    }
    playerYoutube = (e) => {
        this.setState({
            modaleyoutube: true,
            htmlModaleYoutube: <ReactPlayer
                url={'https://www.youtube.com/embed/' + this.state.youtube_key + '?enablejsapi=1&widgetid=1'}
                className='react-player'
                playing
                width='100%'
                height='100%'
            />
        })
    }
    close = () => {
        this.setState({
            modaleyoutube: false,
            modaleLogin: false,
            reclamerb2b: false,
            htmlModaleLogin: <></>,
            htmlModaleYoutube: <></>,
            modaleLogincontact: false,
            modaleLoginAvis: false,


        })
    }
    showModaleLogin = () => {
        this.setState({
            modaleLogincontact: true,
            htmlModaleLogin: <Login t={this.props.t} close={this.close.bind(this)} />
        })
    }
    changePossedecompte = e => {
        const { name, value } = e.target;
        if (value == "oui") {
            this.setState({
                modaleLogincontact: true,
                modaleLoginAvis: false,
                htmlModaleLogin: <Login close={this.close.bind(this)} />
            });
        } else {
            this.setState({
                modaleLogincontact: true,
                modaleLoginAvis: false,
                htmlModaleLogin: <ModalContact t={this.props.t} data={this.state.data} userLogin={this.state.userLogin} dataUser={this.state.dataUser} />
            });
        }
    };
    //****************************** Load update of change language ***************
    componentDidUpdate() {
        if (this.state.l !== localStorage.getItem("langb2b")) {
            Enteprises.getDetail(this.state.slug).then((res) => {
                if (res.data.get) {

                    this.setState({
                        data: res.data.data,
                        blockmenu: <BlockPresentation t={this.props.t} data={res.data.data} userLogin={this.state.userLogin} />,
                        produits: res.data.data.nb_produit
                    });
                    if (res.data.data.pdf != "") {
                        this.setState({ catalogue: true });
                    }
                    if (res.data.data.partenaires) {
                        this.setState({ partenaires: true });
                    }
                    setTimeout(() => {
                        if (res.data.data.youtube_video_id != "") {
                            this.setState({ youtube_key: res.data.data.youtube_video_id });
                        }
                        if (res.data.data.entreprises_aussi != "") {
                            this.setState({ enteprisesAussi: <BlockEntreprisesAussi t={this.props.t} data={res.data.data} /> });
                        }

                    }, 2000);

                }
            });
            this.setState({
                l: localStorage.getItem("langb2b")
            })
        }
    }
    render() {
        const { t } = this.props;
        let sue_menu_entreprise_b2b = {};
        if (this.props.offset < 300) {
            if (document.getElementById("header_b2b")) document.getElementById("header_b2b").style.display = "block";
            sue_menu_entreprise_b2b = {};
        } else {
            if (document.getElementById("header_b2b")) document.getElementById("header_b2b").style.display = "none";
            sue_menu_entreprise_b2b = {
                position: "fixed",
                zIndex: "999999",
                top: "0px",
                left: "0px",
                width: "100%",
                backgroundColor: "#fff",
            };
        }
        if (this.state.data) {
            var content = "";

            var post_title = "";

            if (i18next.language == "fr" || i18next.language == "fr-FR") {
                content = this.state.data.post_content;

                post_title = this.state.data.post_title;
            } else if (i18next.language == "en") {
                content = this.state.data.en_post_content;

                post_title = this.state.data.en_post_title;
            } else {
                content = this.state.data.post_content;

                post_title = this.state.data.post_title;
            }

        }
        return (<>
            <MetaTags>
                <title>{this.state.data ? post_title : ""} | {b2bNameSite} | {this.state.data ? parse(content.replace(/<[^>]*>?/gm, '').substring(0, 50)) + '...' : ""}</title>
            </MetaTags>

            <div onScroll={this.handleScroll} className='page-detail-entreprises'>
                {this.state.data ? <PageVisite postId={this.state.data.ID} /> : <></>}
                {(this.state.reclamerb2b && this.state.userLogin) ?
                    <div className="modal-login-hadeer modal-login-myb2b">
                        <Modal
                            closeOnOuterClick={true}
                            show={this.state.reclamerb2b}
                            onClose={this.close.bind(this)}
                        >
                            <div classNameName="div-loginn">
                                <div className="connxtion_header">
                                    <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">
                                        {t("Réclamation")} :
                                    </h2>
                                    <div className="divbtnclose">
                                        <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='block-login-myb2b p-3' style={{ "background-color": "#fff", "border-radius": "5px" }}>
                                            {this.state.data ? <Reclamerb2b id={this.state.data.ID} /> : <></>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Modal>
                    </div>
                    : <></>}
                <div className="modal-login-hadeer">
                    <Modal
                        closeOnOuterClick={true}
                        show={this.state.modaleLogincontact}
                        onClose={this.close.bind(this)}
                    >
                        <div classNameName="div-loginn">
                            <div className="connxtion_header">
                                <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">{t("JE POSSEDE UN COMPTE SUR")} « {b2bNameSite} » ?
                                    <input id="possedecompteoui" value="oui" checked="checked" name="Possedecompte" type="radio" onChange={this.changePossedecompte} /><label for="possedecompteoui" style={{ marginLeft: "5px" }} >{t("OUI")}</label>
                                    <input id="possedecomptenon" value="non" name="Possedecompte" type="radio" onChange={this.changePossedecompte} /><label for="possedecomptenon" style={{ marginLeft: "5px" }} >{t("NON")}</label>
                                </h2>
                                <div className="divbtnclose">
                                    <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                                </div>
                            </div>
                            {this.state.htmlModaleLogin}
                        </div>
                    </Modal>
                </div>
                <div className="modal-login-hadeer modale-youtube">
                    <Modal
                        closeOnOuterClick={true}
                        show={this.state.modaleyoutube}
                        onClose={this.close.bind(this)}
                    >
                        {this.state.htmlModaleYoutube}
                    </Modal>
                </div>
                <div className="youtube_video" onClick={this.playerYoutube}>
                    <div>
                        <img onContextMenu={(e) => e.preventDefault()} src={(this.state.data && this.state.data.youtube_miniature) ? this.state.data.youtube_miniature : "/upload/image/min-youtube.jpg"} style={{ width: '100%', height: '350px', objectFit: 'cover' }} />
                    </div>
                    <div className="min-youtube_video">
                        <i className="fa fa-youtube-play" aria-hidden="true" style={{ fontSize: '100px', color: 'red' }} />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sue-menu" style={sue_menu_entreprise_b2b}>
                                <nav id="menu_desktop" className="scroll-nav scroll-init menu-desk">
                                    <ul>
                                        <li>
                                            <a onClick={this.presentation} href="javascript:void(0)" className="menu-item active-item grie-b2b">{t("PRÉSENTATION")} </a>
                                        </li>
                                        {this.state.catalogue ?
                                            <li>
                                                <a onClick={this.catalogue} href="javascript:void(0)" className="menu-item catalogue_button grie-b2b">{t("CATALOGUE")}</a>
                                            </li>
                                            : <></>}
                                        {/* <li>
                                            <a onClick={this.chiffres} href="#chiffres" className="menu-item grie-b2b"> CHIFFRES CLÉS </a>
                                        </li> */}
                                        <li>
                                            <a onClick={this.activites} href="javascript:void(0)" className="menu-item grie-b2b">{t("ACTIVITÉS")} </a>
                                        </li>
                                        {(this.state.data && this.state.data.nb_produit > 0) ?
                                            <li>
                                                <a onClick={this.produits} href="javascript:void(0)" className="menu-item grie-b2b">{t("PRODUITS ET SERVICES")}</a>
                                            </li>
                                            : <></>}
                                        {(this.state.data && this.state.data.partenaires.length > 0) ?
                                            <li>
                                                <a onClick={this.partenaires} href="javascript:void(0)" className="menu-item grie-b2b">{t("CLIENTS & RÉFÉRENCES")}</a>
                                            </li>
                                            : <></>}
                                        <li>
                                            <a onClick={this.contact} href="javascript:void(0)" className="menu-item grie-b2b">{t("CONTACTER L'ENTREPRISE")}</a>
                                        </li>
                                    </ul>
                                </nav>
                                <nav id="menu_mobile" className="scroll-nav scroll-init ">
                                    <ul className="row mx-0 mb-1">
                                        <li className="col-xs-3 px-1">
                                            <a onClick={this.presentation} href="#presentation" className="menu-item px-1 active-item grie-b2b">{t("PRÉSENTATION")}</a>
                                        </li>
                                        {this.state.catalogue ?
                                            <li className="col-xs-3  px-1">
                                                <a onClick={this.catalogue} href="#div_catalogue" className="menu-item px-1 catalogue_button">{t("CATALOGUE")}</a>
                                            </li>
                                            : <></>}
                                        <li className="col-xs-3  px-1">
                                            <a onClick={this.activites} href="#activites" className="menu-item px-1 grie-b2b">{t("ACTIVITÉS")}</a>
                                        </li>
                                        {(this.state.data && this.state.data.nb_produit > 0) ?
                                            <li className="col-xs-3  px-1">
                                                <a onClick={this.produits} href="#produits" className="menu-item px-1 grie-b2b">{t("PRODUITS ET SERVICES")}</a>
                                            </li>
                                            : <></>}
                                        {(this.state.data && this.state.data.partenaires.length > 0) ?
                                            <li className="col-xs-3  px-1">
                                                <a onClick={this.partenaires} href="#partenaires" className="menu-item px-1 grie-b2b">{t("RÉFÉRENCES")}</a>
                                            </li>
                                            : <></>}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-4 px-1 pb-2">
                                    <div className="borde-block" >
                                        <BlockImageTitle t={this.props.t} data={this.state.data} dataUser={this.state.dataUser} />
                                    </div>
                                </div>
                                <div className="col-md-8 px-1 pb-2">
                                    <div className="borde-block" >
                                        {this.state.blockmenu}
                                    </div>
                                </div>
                                {(this.state.data != null && this.state.data.nb_produit > 0) ?
                                    <div className="col-md-12 px-1 pb-2 position-relative">
                                        <div className="position-absolute" id="produits" style={{ top: "-50px" }}></div>
                                        <div className="borde-block" >
                                            <BlockProduits data={this.state.data} />
                                        </div>
                                    </div>
                                    : <></>}
                                {/* {(this.state.data && this.state.data.partenaires.length>0)?
                                <div className="col-md-12 px-1 pb-2 position-relative">
                                    <div className="position-absolute" id="partenaires" style={{top: "-50px"}}></div>
                                    <div className="borde-block" >
                                        <BlockPartenaires t={this.props.t} data={this.state.data}/>
                                    </div>
                                </div>
                                :<></>} */}
                                <div className="col-md-12 px-1 pb-2" id="enteprises">
                                    <div className="borde-block" >
                                        {this.state.enteprisesAussi}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 px-1 ">
                            <div className="borde-block p-3">
                                <Slider />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="bottom_buttons" style={{ backgroundColor: '#cc4040', width: '100%', height: '52px', textAlign: 'center', position: 'fixed', bottom: 0, zIndex: 999999999999 }}>
                    <a className="telephone_b2b" href={"tel:" + prefixe + (this.state.data ? this.state.data.phone != "" ? this.state.data.phone : this.state.data.fixe != "" ? this.state.data.fixe : this.state.data.fixe2 : "")}>
                        <div style={{ backgroundColor: '#AFAAA4', width: '49.5%', height: '52px', display: 'inline-block', color: 'white', paddingTop: '5px', fontSize: '22px', marginLeft: '-5px' }}>
                            <i className="fa fa-phone" /> {t("Appeler")}
                        </div>
                    </a>
                    <a onClick={this.contact} href="#contact" id="contacterlink2" role="tab" data-toggle="tab">
                        <div style={{ backgroundColor: '#cc4040', width: '48%', height: '52px', display: 'inline-block', color: 'white', paddingTop: '5px', fontSize: '22px' }}>
                            <i className="fa fa-envelope-o" /> {t("Contacter")}
                        </div>
                    </a>
                </div>
            </div>
        </>);
    }
}
class BlockImageTitle extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { t } = this.props;
        if (this.props.data) {
            var categorys = [];
            var i = 1;
            this.props.data.categorys.forEach(category => {
                categorys.push(<span className='mr-3'><Link to={"/" + i18next.language + "/entreprises/?categorie=" + category.name}>{category.name}</Link></span>)
                if (i != this.props.data.categorys.length) {
                    categorys.push(<span> | </span>)
                }
                i++
            });
            var content = "";
            
            var post_title = "";
            var url = "";
            if (i18next.language == "fr" || i18next.language == "fr-FR") {
                content = this.props.data.post_content;
                
                post_title = this.props.data.post_title;
                url=this.props.data.urlPartage;
            } else if (i18next.language == "en") {
                content = this.props.data.en_post_content;
                
                post_title = this.props.data.en_post_title;
                url=this.props.data.urlPartage.replace("/fr/", "/en/");
            } else {
                content = this.props.data.post_content;
                
                post_title = this.props.data.post_title;
                url=this.props.data.urlPartage;
            }
            
            return (
                <div className="list-single-main-item">
                    <h3 style={{ textAlign: 'center', color: 'red' }} className="mb-3"><Markup markup={post_title} /></h3>
                    {this.props.dataUser && this.props.data && this.props.dataUser.code_user != this.props.data.post_author ? <Suivre dataUser={this.props.dataUser} t={t} company={this.props.data.ID} /> : <></>}
                    <figure style={{ textAlign: 'center' }}>
                        <img onContextMenu={(e) => e.preventDefault()} style={{ width: '100%', height: '150px', objectFit: 'contain' }} src={this.props.data.thumbnail} />
                    </figure>
                    {this.props.data.ville ?
                        <p className="pb-0 mb-0">
                            <span style={{ fontWeight: 'bold', color: '#5d5f5e' }}>{t("Ville")} : </span>
                            <span><Link to={"/" + i18next.language + "/region/" + this.props.data.ville.slug}>{this.props.data.ville.name}</Link></span>
                        </p>
                        : <></>}
                    {categorys.length > 0 ?
                        <p className="pb-0 mb-0">
                            <span style={{ fontWeight: 'bold', color: '#5d5f5e' }}>{t("Secteur(s) d'activité")} : </span>
                            {categorys}
                        </p>
                        : <></>}
                    {this.props.dataUser == null && this.props.data.post_author == 1 ?
                        <div className style={{ textAlign: 'center' }}>
                            <h3 className="grie-b2b size-titre-b2b" id="text_gerez_entreprise">
                                {t("Vous étes le propriétaire de")}
                                <span className="place_post_title">
                                    <Markup markup={post_title} />
                                </span>?
                            </h3>
                            <h6>{t("Gérez les informations de votre entreprise")}</h6>
                            <Link to={"/" + i18next.language + "/compte-entreprise?entreprise=" + this.props.data.ID + "&title=" + post_title} style={{ border: '1px solid #e11a18', color: '#e11a18', background: '#fff', borderRadius: '50px', marginBottom: '10px', height: '40px', lineHeight: '0px', width: '100%', display: 'flex', justifyContent: "center", alignItems: "center" }} className="btn  big-btn  color-bg flat-btn">
                                {t("Mettre à jour")}
                            </Link>
                        </div>
                        : <></>}
                    <p className="pb-0 mb-0">
                        <span style={{ fontWeight: 'bold', color: '#5d5f5e' }}>{localStorage.getItem("langb2b") === "fr" ? t("PARTAGER SUR") : t("SHARE ON")} : </span>
                    </p>
                    <BtnPartage
                        image={this.props.data.thumbnail}
                        title={post_title}
                        content={content}
                        url={url} />
                    <div className="divqrcode">
                        <iframe src={"https://www.b2b.tn/phpqrcode/index.php?data=" + url} />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="list-single-main-item">
                    <h3 className="loader-entreprise-title"></h3>
                    <div className="loader-entreprise-image"></div>
                    <p className="pb-0 mb-0">
                        <span style={{ fontWeight: 'bold', color: '#5d5f5e' }}>{t("Ville")} : </span>
                        <span><p className="loader-entreprise-text"></p></span>
                    </p>
                    <p className="pb-0 mb-0">
                        <span style={{ fontWeight: 'bold', color: '#5d5f5e' }}>{t("Catégories")} : </span>
                        <span><p className="loader-entreprise-text"></p></span>
                    </p>
                    <div >
                        <span style={{ fontWeight: 'bold' }}>{localStorage.getItem("langb2b") === "fr" ? t("PARTAGER SUR") : t("SHARE ON")} : </span>
                        <p className="loader-entreprise-text"></p>
                    </div>
                </div>
            );
        }
    }
}
class BlockPresentation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dirigeants: false
        }
        if (this.props.data && this.props.data.dirigeants.length > 0) this.setState({ dirigeants: true });
    }
    render() {
        const { t } = this.props;
        if (this.props.data) {
            var dirigeants = [];
            if (this.props.data.dirigeants.length > 0 && this.props.userLogin) {

                this.props.data.dirigeants.forEach(dirigeant => {
                    var Pname = "";
                    if (dirigeant.fonction != "") {
                        Pname = <><b>{dirigeant.fonction}</b> : {dirigeant.genre} {dirigeant.nom}</>
                    } else {
                        Pname = <>{dirigeant.genre} {dirigeant.nom}</>
                    }
                    var Pemail = "";
                    if (dirigeant.email != "") {
                        Pemail = <p><b>{t("Email")}</b> : {dirigeant.email}</p>
                    }
                    var Ptel = "";
                    if (dirigeant.email != "") {
                        Ptel = <p><b>{t("GSM")}</b> : <span>{prefixe}</span> <span className='formatGSM'>{dirigeant.tel}</span></p>
                    }
                    dirigeants.push(
                        <div className='dirigeants'>
                            <p>{Pname} </p>
                            {Pemail}
                            {Ptel}
                        </div>
                    );
                });
            }
            var content = "";
            var post_title = "";
            if (i18next.language == "fr" || i18next.language == "fr-FR") {
                content = this.props.data.post_content;
                post_title = this.props.data.post_title;
            } else if (i18next.language == "en") {
                content = this.props.data.en_post_content;
                post_title = this.props.data.en_post_title;
            } else {
                content = this.props.data.post_content;
                post_title = this.props.data.post_title;
            }

            return (
                <div id="_presentation" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("CHIFFRES CLÉS DE")}<span className="place_post_title"> <Markup markup={this.props.data.post_title} /></span> :</h3>
                    </div>
                    <div className="listing-features fl-wrap">
                        <div className="mb-0 pb-1"><i className="fa fa-calendar mr-1" aria-hidden="true" style={{ fontSize: '15px', color: 'red' }} /> <b>{t("Date Fondation")}</b> : {this.props.data.date_fondation}</div>
                        <div className="mb-0 pb-1"><i className="fa fa-users mr-1" aria-hidden="true" style={{ fontSize: '15px', color: 'red' }} /><b>{t("Effectifs")} :</b>{this.props.data.nbr_employes} {t("employés")}</div>
                        <div className="mb-0 pb-1"><i className="fa fa-cubes mr-1" aria-hidden="true" style={{ fontSize: '15px', color: 'red' }} /><b>{t("Produits et services")} :</b>{this.props.data.nb_produit}</div>
                        {/* <div className="mb-0 pb-1"><b> Employés :</b>{this.props.data.nbr_employes}</div>                         */}
                    </div>
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("PRÉSENTATION DE")} <span className="place_post_title"><Markup markup={post_title} /></span> :</h3>
                    </div>
                    <div id="descr" className="col-md-12 block-descr px-1">
                        <div style={{ "text-align": "justify" }}>
                            <p><Markup markup={content} /></p>
                        </div>
                    </div>
                    {(dirigeants.length > 0) ?
                        <>
                            <div className="list-single-main-item-title fl-wrap mt-3">
                                <h3 className="grie-b2b">{t("RESPONSABLE(S) DE")}  <span className="place_post_title"><Markup markup={post_title} /></span> :</h3>
                            </div>
                            <div id="descr" className="col-md-12 block-descr px-1" >
                                <div style={{ "text-align": "justify" }}>
                                    {dirigeants}
                                </div>
                            </div>
                        </>
                        : <></>}
                </div>
            );
        } else {
            return (
                <div id="_presentation" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("CHIFFRES CLÉS")} :</h3>
                    </div>
                    <div className="listing-features fl-wrap">
                        <div className="mb-0 pb-1 mr-1"> <b> {t("Date Fondation")}</b> : <p className="loader-entreprise-text"></p></div>
                        <div className="mb-0 pb-1 mr-1"><b> {t("Effectifs")} :</b><p className="loader-entreprise-text"></p></div>
                        <div className="mb-0 pb-1 mr-1"><b> {t("Produits et services")} :</b><p className="loader-entreprise-text"></p></div>
                        <div className="mb-0 pb-1 mr-1"><b> {t("Employés")} :</b><p className="loader-entreprise-text"></p></div>
                    </div>
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("PRÉSENTATION")} :</h3>
                    </div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                </div>
            );
        }

    }
}

class BlockActivites extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { t } = this.props;
        if (this.props.data) {
            var activites = "";
            if (i18next.language == "fr" || i18next.language == "fr-FR") {
                activites = this.props.data.activites;
            } else if (i18next.language == "en") {
                activites = this.props.data.en_activites;
            } else {
                activites = this.props.data.activites;
            }
            return (
                <div id="_activites" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("ACTIVITÉS DE")} <span className="place_post_title"><Markup markup={this.props.data.post_title} /></span> :</h3>
                    </div>
                    <div id="descr" className="col-md-12 block-descr px-1">
                        <div style={{ "text-align": "justify" }}>
                            <p><Markup markup={activites} /></p>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div id="_activites" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("ACTIVITÉS")} :</h3>
                    </div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                </div>
            );
        }

    }
}

class BlockCatalogue extends Component {
    constructor(props) {
        super(props)
        this.state = {
            numPages: null,
            pageNumber: 1
        }
    }
    onDocumentLoadSuccess = (numPages) => {
        this.setState({ numPages: numPages });
    }
    render() {
        const { t } = this.props;
        if (this.props.data) {
            return (
                <div id="_activites" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("CATALOGUE DE")} <span className="place_post_title"><Markup markup={this.props.data.post_title} /></span> :</h3>
                    </div>

                    <div id="descr" className="col-md-12 block-descr px-1">
                        <iframe src={"https://docs.google.com/gview?url=" + this.props.data.pdf + "&embedded=true"} style={{ width: "100%", height: "420px" }} frameborder="0"></iframe>
                        {/* <Document file={this.props.data.pdf} onLoadSuccess={this.onDocumentLoadSuccess}>
                            <Page pageNumber={this.state.pageNumber} />
                        </Document>
                        <p>
                             Page {this.state.pageNumber} of {this.state.numPages}
                        </p> */}
                    </div>
                </div>
            );
        } else {
            return (
                <div id="_activites" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("CATALOGUE")} :</h3>
                    </div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                </div>
            );
        }

    }
}
class BlockContact extends Component {
    constructor(props) {
        super(props);
        var urlsite = props.data.url;
        urlsite = urlsite.replace("https://", "");
        urlsite = urlsite.replace("http://", "");

        this.state = {
            urlsite: urlsite,
            showDivHorairesTravail: false
        }
    }
    clickShowModaleLogin = () => {
        this.props.showModaleLogin()
    }
    showHorairesTravail = () => {
        if (this.state.showDivHorairesTravail) {
            this.setState({
                showDivHorairesTravail: false
            });
        } else {
            this.setState({
                showDivHorairesTravail: true
            });
        }
    }
    text2Binary = (string) => {
        return string.split('').map(function (char) {
            return char.charCodeAt(0).toString(2);
        }).join(' ');
    }
    render() {
        const { t } = this.props;
        if (this.props.data) {
            var company_serve_time = <></>;

            if (this.props.data.company_serve_time == "toujoursouvert") {
                company_serve_time = <div className="toujoursouvert">{t("Toujours Ouvert")}</div>;
            } else {
                var serve_times = [];
                var jour = 1;
                this.props.data.company_serve_time.forEach(serve_time => {
                    if (jour == 1) {
                        serve_times.push(<li><span className="opening-hours-day">{t("Lundi")} </span><span className="opening-hours-time">{serve_time == "true" ? "Fermé" : serve_time}</span></li>);
                    } else if (jour == 2) {
                        serve_times.push(<li><span className="opening-hours-day">{t("Mardi")} </span><span className="opening-hours-time">{serve_time == "true" ? "Fermé" : serve_time}</span></li>);
                    } else if (jour == 3) {
                        serve_times.push(<li><span className="opening-hours-day">{t("Mercredi")} </span><span className="opening-hours-time">{serve_time == "true" ? "Fermé" : serve_time}</span></li>);
                    } else if (jour == 4) {
                        serve_times.push(<li><span className="opening-hours-day">{t("Jeudi")} </span><span className="opening-hours-time">{serve_time == "true" ? "Fermé" : serve_time}</span></li>);
                    } else if (jour == 5) {
                        serve_times.push(<li><span className="opening-hours-day">{t("Vendredi")} </span><span className="opening-hours-time">{serve_time == "true" ? "Fermé" : serve_time}</span></li>);
                    } else if (jour == 6) {
                        serve_times.push(<li><span className="opening-hours-day">{t("Samedi")} </span><span className="opening-hours-time">{serve_time == "true" ? "Fermé" : serve_time}</span></li>);
                    } else if (jour == 7) {
                        serve_times.push(<li><span className="opening-hours-day">{t("Dimanche")} </span><span className="opening-hours-time">{serve_time == "true" ? "Fermé" : serve_time}</span></li>);
                    }
                    jour++;
                });
                company_serve_time = <ul>{serve_times}</ul>;
            }
            return (
                <div id="_activites" className="presentation-body-item">
                    <div className="col-md-12 mb-2" >
                        <div className="list-single-main-item-title fl-wrap mt-3">
                            <h3 className="grie-b2b">{t("LES COORDONNÉES DE")}  <span className="place_post_title"><Markup markup={this.props.data.post_title} /></span>:</h3>
                        </div>
                        <div className="list-author-widget-contacts list-item-widget-contacts">
                            <ul>
                                <li>
                                    <span><i className="fa fa-map-marker"></i> {t("Adresse")} :</span>
                                    <a className="telephone_b2b" href="javascript: void(0)">{this.props.data.adresse}</a>
                                </li>
                                {(this.props.data.phone != "") ?
                                    <li>
                                        <span><i className="fa fa-phone"></i> {t("Téléphone")}:  </span>
                                        <a className="telephone_b2b" href={"tel:" + prefixe + this.props.data.phone}><span>{prefixe}</span> <span className="formatFixe">{this.props.data.phone}</span></a>
                                    </li>
                                    : <></>}
                                {(this.props.data.fixe != "") ?
                                    <li>
                                        <span><i className="fa fa-phone"></i> {t("Fixe")}:  </span>
                                        <a className="telephone_b2b" href={"tel:" + prefixe + this.props.data.fixe}><span>{prefixe}</span> <span className="formatFixe">{this.props.data.fixe}</span></a>
                                    </li>
                                    : <></>}
                                {(this.props.data.fixe2 != "") ?
                                    <li>
                                        <span><i className="fa fa-phone"></i> {t("Fixe")}:  </span>
                                        <a className="telephone_b2b" href={"tel:" + prefixe + this.props.data.fixe2}><span>{prefixe}</span> <span className="formatFixe">{this.props.data.fixe2}</span></a>
                                    </li>
                                    : <></>}
                                {(this.props.data.fax != "") ?
                                    <li>
                                        <span><i className="fa fa-phone"></i> {t("Fax")}:  </span>
                                        <a className="telephone_b2b" href={"tel:" + prefixe + this.props.data.fax}><span>{prefixe}</span> <span className="formatFixe">{this.props.data.fax}</span></a>
                                    </li>
                                    : <></>}
                                {(this.props.data.email != "") ?
                                    <li>
                                        <span><i className="fa fa-envelope"></i> {t("E-mail")}:  </span>
                                        <a className="telephone_b2b" href="javascript:void(0)">{this.props.data.email}</a>
                                        {/* <a className="telephone_b2b" href="javascript:void(0)"><img onContextMenu={(e)=> e.preventDefault()} src={backUrl+"/b2b/api/texte.php?text="+this.text2Binary(this.props.data.email)} style={{height:"23px"}}/></a> */}
                                    </li>
                                    : <></>}
                                {(this.state.urlsite != "") ?
                                    <li>
                                        <span><i className="fa fa-globe"></i> {t("Site Web")}:</span>
                                        <a target="_blank" href={"http://" + this.state.urlsite}>{this.state.urlsite}</a>
                                    </li>
                                    : <></>}
                                <li id="btn_horaires" style={{ cursor: "pointer" }} onClick={this.showHorairesTravail}><span><i className="fa fa-clock-o"></i> {t("Horaires de travail")} :</span>{t("Cliquez ici")} </li>
                            </ul>
                        </div>
                        <div id="horaires" style={this.state.showDivHorairesTravail ? { display: "block" } : { display: "none" }}>
                            <div className="box-opening-hours">
                                {company_serve_time}
                            </div>
                        </div>
                        {(this.props.data.url_fb != "" || this.props.data.url_li != "") ?
                            <div className="list-widget-social" style={{ "float": "unset", "display": "flex" }}>
                                <ul>
                                    <li>{t("Suivre")} <b><Markup markup={this.props.data.post_title} /></b> {t("sur les réseaux sociaux")} </li>
                                    {(this.props.data.url_fb != "") ?
                                        <li><a href={this.props.data.url_fb} target="_blank"><i className="fa fa-facebook" style={{ "display": "flex", "justify-content": "center", "align-items": "center", "height": "100%" }}></i></a></li>
                                        : <></>}
                                    {(this.props.data.url_li != "") ?
                                        <li><a href={this.props.data.url_li} target="_blank"><i className="fa fa-linkedin" style={{ "display": "flex", "justify-content": "center", "align-items": "center", "height": "100%" }}></i></a></li>
                                        : <></>}
                                </ul>
                            </div>
                            : <></>}
                    </div>
                    {this.props.userLogin ? <>

                        {this.props.dataUser && this.props.data && this.props.dataUser.code_user != this.props.data.post_author ? <>
                            <div className="col-md-12 ">
                                <div className="list-single-main-item-title fl-wrap mt-3">
                                    <h3 className="grie-b2b">{t("ÉCRIRE À")}  <span className="place_post_title"><Markup markup={this.props.data.post_title} /></span> :</h3>
                                </div>
                                <div className="" style={{ "margin-top": "20px", "border": "0", "height": "190px" }}>
                                    {this.props.dataUser.valid_b2b == "ok" ?
                                        <FormContact t={t} entrepriseUser={this.props.data.post_author} entrepriseID={this.props.data.ID} />
                                        : <>
                                            {t("Vous ne pouvez pas effectuer cette action avant la validation de votre compte.")}
                                        </>}
                                </div>
                            </div>
                        </> : <></>}
                    </> :
                        <div className="col-md-12 ">
                            <div style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '30px 0px' }}>
                                <div>
                                    <h3 className="grie-b2b size-titre-b2b">{t("Veuillez vous connecter pour pouvoir contacter l’entreprise")} <span className="place_post_title"><Markup markup={this.props.data.post_title} /></span> :
                                    </h3>
                                    <button onClick={this.clickShowModaleLogin} id="myBtn" style={{ border: '1px solid #e11a18', color: '#e11a18', background: '#fff', borderRadius: '50px', padding: '5px 10px' }} className="btn  big-btn  color-bg flat-btn">{t("Se Connecter")} </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            );
        } else {
            return (
                <div id="_activites" className="presentation-body-item">
                    <div className="col-md-12 mb-2" style={{ zIndex: "999999" }}>
                        <div className="list-single-main-item-title fl-wrap mt-3">
                            <h3 className="grie-b2b">{t("LES COORDONNÉES")}:</h3>
                        </div>
                        <div className="list-author-widget-contacts list-item-widget-contacts">
                            <ul>
                                <li>
                                    <span><i className="fa fa-map-marker"></i> {t("Adresse")} :</span>
                                    <p className="loader-entreprise-text"></p>
                                </li>
                                <li>
                                    <span><i className="fa fa-phone"></i> {t("Fixe")}:  </span>
                                    <p className="loader-entreprise-text"></p>
                                </li>
                                <li>
                                    <span><i className="fa fa-globe"></i> {t("Site Web")}:</span>
                                    <p className="loader-entreprise-text"></p>
                                </li>
                                <li id="btn_horaires"><span><i className="fa fa-clock-o"></i> {t("Horaires de travail")} :</span>{t("Cliquez ici")} </li>
                            </ul>
                        </div>
                        <div id="horaires_de_travail" style={this.state.showDivHorairesTravail ? { display: "block" } : { display: "none" }}>
                            <div className="box-widget-item fl-wrap" >
                                <div className="box-widget opening-hours">
                                    {company_serve_time}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 ">
                        <div className="list-single-main-item-title fl-wrap mt-3">
                            <h3 className="grie-b2b">{t("ÉCRIRE")}:</h3>
                        </div>
                        <div className="loader-entreprise-ecrire-a"></div>
                    </div>
                </div>
            );
        }
    }
}

class BlockPartenaires extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: []
        }
    }
    render() {
        const { t } = this.props;

        if (this.props.data && this.props.data.partenaires.length > 0) {
            var items = [];
            this.props.data.partenaires.forEach(partenaire => {
                items.push(<ItemPartenaire partenaire={partenaire} />);
            });
            return (
                <div className="list-single-main-item">
                    <div className="list-single-main-item-title fl-wrap">
                        <h3 className="size-titre-b2b grie-b2b">{t("ILS ONT FAIT CONFIANCE À ")} <span className="place_post_title"><Markup markup={this.props.data.post_title} /></span> :</h3>
                    </div>
                    <div className=" product-list no-padding" width="100%">
                        <AliceCarousel
                            mouseTracking
                            autoPlay={true}
                            items={items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval={2000}
                            responsive={{ 1200: { items: 4 }, 1000: { items: 3 }, 800: { items: 2 }, 300: { items: 2 } }} />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="list-single-main-item"></div>
            );
        }

    }
}
class ItemPartenaire extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { t } = this.props;

        return (
            <div className="partenaires-item item">
                <figure>
                    <img onContextMenu={(e) => e.preventDefault()} width="200" height="200" src={this.props.partenaire.logo} className="attachment-200x200 size-200x200 " alt={this.props.partenaire.title} />
                </figure>
                <h6>{this.props.partenaire.title}</h6>
            </div>
        );
    }
}

class BlockEntreprisesAussi extends Component {
    constructor(props) {
        super(props)
        var items = []
        this.props.data.entreprises_aussi.forEach(entreprise => {
            if (entreprise.image) {

                items.push(<ItemEntrepriseAussi data={entreprise} />)
            }
        });
        this.state = {
            items: items
        }
    }
    render() {
        const { t } = this.props;
        if (true) {

            return (
                <div className="list-single-main-item">
                    <div className="list-single-main-item-title fl-wrap">
                        <h3 className="size-titre-b2b grie-b2b text-uppercase">{t("AUTRES ENTREPRISES DANS LE MÊME SECTEUR D’ACTIVITÉ")}:</h3>
                    </div>
                    <div className=" product-list no-padding" width="100%">
                        <AliceCarousel
                            mouseTracking
                            autoPlay={true}
                            items={this.state.items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval={2000}
                            responsive={{ 1200: { items: 4 }, 1000: { items: 3 }, 800: { items: 2 }, 300: { items: 2 } }} />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="list-single-main-item"></div>
            );
        }

    }
}
class ItemEntrepriseAussi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data
        }
    }
    render() {
        const { t } = this.props;

        return (
            <div className="partenaires-item item">
                <a href={"/" + i18next.language + this.state.data.permalink} style={{ width: "100%", height: "100%", display: "block" }}>
                    <figure style={{height:"200px"}}>
                        <img onContextMenu={(e) => e.preventDefault()} width="200" height="200" src={this.state.data.image} className="attachment-200x200 size-200x200 " alt={this.state.data.post_title} />
                    </figure>
                    <h6>{this.state.data.post_title}</h6>
                </a>
            </div>
        );
    }
}

class LoderBlockEntreprisesAussi extends Component {
    constructor(props) {
        super(props)
        const item = <div className="partenaires-item item">
            <div className="p-2"><div className="loder-produi-aussi-image"></div></div>
            <div className="p-2"><h6 className="loder-produi-aussi-name"></h6></div>
        </div>;
        this.state = {
            items: [item, item, item, item, item, item]
        }
    }
    render() {
        return (
            <div className="list-single-main-item">
                <div className="list-single-main-item-title fl-wrap">
                    <h3 className="size-titre-b2b grie-b2b text-uppercase">les entreprises qui peuvent vous intéresser:</h3>
                </div>
                <div className=" product-list no-padding" width="100%">
                    <AliceCarousel
                        mouseTracking
                        autoPlay={true}
                        items={this.state.items}
                        infinite
                        renderDotsItem={false}
                        autoPlayInterval={2000}
                        responsive={{ 1200: { items: 4 }, 1000: { items: 3 }, 800: { items: 2 }, 300: { items: 2 } }} />
                </div>
            </div>
        );
    }
}
function FormContact(props) {

    const [loderFrome, setLoderFrome] = useState("d-none");
    const [sund, setSund] = useState(false);
    const [entrepriseid, setEntrepriseid] = useState(props.entrepriseID);
    const [entrepriseuser, setEntrepriseuser] = useState(props.entrepriseUser);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        setLoderFrome("loder-from");
        Enteprises.registerMessage(entrepriseid, entrepriseuser, data).then((res) => {
            if (res.data.register) {
                reset();
                setSund(true);
                setLoderFrome("d-none");
            }
        });
    }
    const { t } = props;
    useEffect(() => {

    }, []);
    return (<>
        {sund ?
            <div className="sund-form-sucsses">
                <span>{t("Votre Message a été enregistrée avec succès")}</span>
            </div> : <></>}
        <div className={loderFrome}>
            <span className='loading-login'></span>
        </div>
        <form className="add-comment custom-form" onSubmit={handleSubmit(onSubmit)}>
            <select {...register("sujet", { required: true })} className="bs-select-hidden sujet-form-contact">
                <option value="Demande d'information">{t("Demande d'information")}</option>
                <option value="Demande de devis">{t("Demande de devis")}</option>
                <option value="Demande contact entreprise">{t("Demande contact entreprise")}</option>
            </select>
            {errors.sujet ? <span className="error d-block">{t("Ce champ est obligatoire")}</span> : <></>}
            <br />
            <textarea cols="40" rows="3" placeholder={t("Message")} {...register("message", { required: true })} style={{ "height": "100px", "padding": "15px 20px", "border-radius": "5px" }} className="form-control"></textarea>
            <button style={{ "border": "1px solid #e11a18", "background": "#e11a18", "float": "right", "margin": "10px 0px" }} className="site-button btn-b2b" type="submit">{t("Envoyer")}</button>
        </form>
    </>
    );
}

class Suivre extends Component {
    constructor(props) {
        super(props)
        this.state = {
            company: props.company,
            classname: "icon-follow",
            text: "Suivre",
            show: false
        }

        if (this.props.dataUser.type == "societe") {
            Myb2b.IsSuivreCompany(this.state.company).then((res) => {
                if (res.data.status == "followed") {
                    this.setState({
                        classname: "icon-no-follow",
                        text: "Ne plus suivre",
                        show: true
                    })
                } else if (res.data.status == "unfollowed") {
                    this.setState({
                        classname: "icon-follow",
                        text: "Suivre",
                        show: true
                    })
                } else {
                    this.setState({
                        show: false
                    })
                }
            });
        } else {
            this.setState({
                show: false
            });
        }
    }
    clickSuivre = () => {
        Myb2b.suivreCompany(this.state.company).then((res) => {
            if (res.data.status == "add") {
                this.setState({
                    classname: "icon-no-follow",
                    text: this.props.t("Ne plus suivre"),
                    show: true
                })
            } else if (res.data.status == "delete") {
                this.setState({
                    classname: "icon-follow",
                    text: this.props.t("Suivre"),
                    show: true
                })
            } else {
                this.setState({
                    show: false
                })
            }
        })
    }
    render() {
        if (this.state.show) {
            return (
                <div class="d-flex justify-content-center mb-2">
                    <a href="javascript:void(0)" onClick={this.clickSuivre} className="save-btn" style={{ padding: '3px 15px!important' }}><span className={this.state.classname}>{this.state.text}</span></a>
                </div>
            );
        } else {
            return (<></>);
        }

    }
}



class Reclamerb2b extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id_reclamation: props.id,
            telephone: "",
            message: "",
            file: null,
            error: false,
            sundReclamation: false,
            textError: ""
        }
    }
    changeMessage = (e) => {
        this.setState({ message: e.target.value });
        this.setState({
            error: false,
            textError: ""
        });
    }
    changeTelephone = (e) => {
        let value = e.target.value;
        value = value.replace(/o/g, "");
        value = value.replace(/[^\d]/g, "");
        this.setState({ telephone: value });
        this.setState({
            error: false,
            textError: ""
        });
        return false;
    }
    changeFile = (e) => {
        this.setState({ file: e.target.files[0] });
        this.setState({
            error: false,
            textError: ""
        });
    }
    submitReclamation = (e) => {
        this.setState({
            error: false,
            textError: ""
        });
        if (this.state.telephone == "") {
            this.setState({
                error: true,
                textError: "Téléphone est obligatoire"
            });
            return false;
        }
        if (this.state.message == "") {
            this.setState({
                error: true,
                textError: "Message est obligatoire"
            });
            return false;
        }
        if (this.state.file == null) {
            this.setState({
                error: true,
                textError: "Piece justificativ est obligatoire"
            });
            return false;
        }

        Enteprises.reclamation(this.state.telephone, this.state.message, this.state.file, this.state.id_reclamation).then((res) => {
            if (res.data.reclamation) {
                this.setState({ sundReclamation: true });
            }
        })
    }
    render() {
        return (<>
            <div className="info-form-addentreprise">
                <p> Il existe des entreprises ayant le même nom que celui de votre entreprise, si vous êtes à l’origine de cette action veuillez juste <strong>VOUS-CONNECTER</strong>  et GÉRER librement votre entreprise, sinon veuillez nous <strong>RÉCLAMER</strong> que votre entreprise est enregistrée sur « <strong>{b2bNameSite}</strong> », et que vous n’êtes pas à l’origine de cet enregistrement. </p>
            </div>
            <div id="form_claim">
                <form className="w-100 row ml-1" >
                    {this.state.sundReclamation ? <>
                        <p className="col-md-12 reclamation-succes">Réclamation envoyée avec succès</p>
                    </> : <>
                        <p className="col-md-12">
                            <label style={{ fontWeight: 'normal', color: '#969696' }}>Téléphone : {prefixe}</label>
                            <input type="tel" className="text-field input-item input" value={this.state.telephone} onChange={this.changeTelephone} maxLength={lengthTel} />
                        </p>
                        <p className="col-md-12">
                            <label style={{ fontWeight: 'normal', color: '#969696' }}>Message : </label>
                            <textarea className="input-logo w-100" onChange={this.changeMessage} defaultValue={this.state.message} />
                        </p>
                        <p className="col-md-12">
                            <label style={{ fontWeight: 'normal', color: '#969696' }}>Piece justificativ (une facture de vente, une facture d’achat, un bulletin de paie, un relevé bancaire, un listing informatique…): </label>
                            <input type="file" className="text-field input-item input" onChange={this.changeFile} />
                        </p>
                        {this.state.error ? <div className="col-md-12"><span id="error">{this.state.textError}</span></div> : <></>}
                        <div className="col-md-6">
                            <div style={{ float: 'left' }}>
                                <button type="button" onClick={this.annulerReclamation} className="btn btn-b2b">Annuler</button>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-b2b" onClick={this.submitReclamation}>Valider</button>

                            </div>
                        </div>
                    </>}
                </form>
            </div>
        </>);
    }
}

