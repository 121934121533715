import http from "./httpService";
import { apiUrl } from  "../config.json";

const getPosts = () => {
    const apiEndoint =  apiUrl+"/security/myB2B.php";
    return http.get(apiEndoint);
}
const getPostsConnected = (paged=1) => {
    const apiEndoint =  apiUrl+"/security/myB2B.php";
    const formData = new FormData();
    formData.append("token", localStorage.getItem("tokenb2b"));
    formData.append("paged", paged);
    return http.post(apiEndoint,formData);
}
const getNbVues = () => {
    const apiEndoint =  apiUrl+"/security/myB2BnbVues.php";
    const formData = new FormData();
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
const getNbFolloweds = () => {
    const apiEndoint =  apiUrl+"/security/myB2Bfolloweds.php";
    const formData = new FormData();
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
const suivreCompany = (company) => {
    const apiEndoint =  apiUrl+"/security/myB2BSuivre.php";
    const formData = new FormData();
    formData.append("token", localStorage.getItem("tokenb2b"));
    formData.append("company_to_follow", company);
    return http.post(apiEndoint,formData);
}

const IsSuivreCompany = (company) => {
    const apiEndoint =  apiUrl+"/security/myB2BIsSuivre.php";
    const formData = new FormData();
    formData.append("token", localStorage.getItem("tokenb2b"));
    formData.append("company", company);
    return http.post(apiEndoint,formData);
}

const reactPost = (post) => {
    const apiEndoint =  apiUrl+"/security/myB2BreactPost.php";
    const formData = new FormData();
    formData.append("token", localStorage.getItem("tokenb2b"));
    formData.append("post", post);
    return http.post(apiEndoint,formData);
}
const likePost = (post) => {
    const apiEndoint =  apiUrl+"/security/myB2Blike.php";
    const formData = new FormData();
    formData.append("token", localStorage.getItem("tokenb2b"));
    formData.append("post", post);
    return http.post(apiEndoint,formData);
}
const sharePost = (post) => {
    const apiEndoint =  apiUrl+"/security/myB2Bshare.php";
    const formData = new FormData();
    formData.append("token", localStorage.getItem("tokenb2b"));
    formData.append("post", post);
    return http.post(apiEndoint,formData);
}
const commentPost = (post,comment) => {
    const apiEndoint =  apiUrl+"/security/myB2Bcomment.php";
    const formData = new FormData();
    formData.append("token", localStorage.getItem("tokenb2b"));
    formData.append("post", post);
    formData.append("comment", comment);
    return http.post(apiEndoint,formData);
}
const removeCommentPost = (comment) => {
    const apiEndoint =  apiUrl+"/security/myB2BremoveComment.php";
    const formData = new FormData();
    formData.append("token", localStorage.getItem("tokenb2b"));
    formData.append("comment", comment);
    return http.post(apiEndoint,formData);
}

export default 
{
    getPosts,
    getPostsConnected,
    getNbVues,
    getNbFolloweds,
    suivreCompany,
    IsSuivreCompany,
    reactPost,
    likePost,
    sharePost,
    commentPost,
    removeCommentPost
}