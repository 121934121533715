
import React, { Component,useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Detail from './detail';
export default function Index(){ 
    let { slug } = useParams();
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    return (
        <Detail slug={slug} offset={offset}/>
    );
}