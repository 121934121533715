import React, { Component , useEffect , useState } from 'react';
import Maselectionentreprises from './maSelectionEntreprises'
import Mesabonnes from './mesabonnes'
import Entreprisesenvedette from './entreprisesenvedette'
import Produitsenvedette from './produitsenvedette'
import Ajoutezavotrefilactualite from './ajoutezavotrefilactualite'
import Evenementsavenir from './evenementsavenir'
import Filactualite from './filactualite';
import Monentreprise from './monentreprise';
import { b2bNameSite,b2bDescriptionSite } from  "../../config.json"; 
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import MetaTags from 'react-meta-tags';
import Modal, {closeStyle} from 'simple-react-modal';
import { withTranslation } from 'react-i18next';
import './style.css';
function MyB2b(){
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    return (
        <Index offset={offset}/>
    );
}
export default withTranslation()(MyB2b);

class Index extends Component {
    constructor (props) {
        super(props)
        this.state = {
            fixsedtop:false,
            fixsedtopmobile:false,
            styleleftmenu:{},
            stylerightmenu:{},
            stylebtnmenu:{},
            clickleftmenu:false,
            clickightmenu:false,
        }
        // setTimeout(() => {
        //     const slidemyb2bleft = document.getElementById("slidemyb2bleft");
        //     if(slidemyb2bleft){
        //         var topofslideleft = slidemyb2bleft.offsetTop;
        //         const intElemOffsetHeight = slidemyb2bleft.offsetHeight; 
        //         console.log(slidemyb2bleft.offsetHeight);
        //         console.log(slidemyb2bleft.clientHeight);
        //         console.log(topofslideleft);
        //     }
        // }, 2000);
    }
    clickleftmenu=()=>{
        if(this.state.clickleftmenu){
            this.setState({
                styleleftmenu:{left:"-1000px"},
                clickleftmenu:false,
                stylebtnmenu:{}
            })
        }else{
            this.setState({
                styleleftmenu:{left:"0px"},
                clickleftmenu:true,
                stylerightmenu:{right:"-1000px"},
                clickightmenu:false,
                stylebtnmenu:{
                    zIndex: "99999",
                    position: "fixed",
                    width: "100%",
                    top: "70px"
                }
            })
        }
    }
    clickrightmenu=()=>{
        if(this.state.clickightmenu){
            this.setState({
                stylerightmenu:{right:"-1000px"},
                clickightmenu:false,
                stylebtnmenu:{}
            })
        }else{
            this.setState({
                stylerightmenu:{right:"0px"},
                clickightmenu:true,
                styleleftmenu:{left:"-1000px"},
                clickleftmenu:false,
                stylebtnmenu:{
                    zIndex: "97",
                    position: "fixed",
                    width: "100%",
                    top: "70px"
                }
            })
        }
    }
    
     
    render() { 
        if(window.scrollY>1000){
            if(!this.state.fixsedtop){
                this.setState({fixsedtop:true})
            }
        }else{
            if(this.state.fixsedtop){
                this.setState({fixsedtop:false})
            }  
        }
        if(window.scrollY>100){
            if(!this.state.fixsedtopmobile){
                this.setState({fixsedtopmobile:true})
            }
        }else{
            if(this.state.fixsedtopmobile){
                this.setState({fixsedtopmobile:false})
            }  
        }
        return (<>
            <MetaTags>
                <title>{localStorage.getItem("langb2b")==="en" ?"MY B2B" : "Mon B2B"} | {b2bNameSite} | {localStorage.getItem("langb2b")==="en" ? "B2B marketplace and B2B networking platform in France and abroad" : b2bDescriptionSite}</title>
            </MetaTags>
            <div className="page-content page-myb2b"> 
                <div className="container ">
                    <div className="row pt-3">
                        <div className='col-lg-12 p-0'>
                            <div className={this.state.fixsedtopmobile?'block-mobile fixsedtopmobile':'block-mobile'} style={this.state.stylebtnmenu}>
                                <button className='btn' onClick={this.clickleftmenu}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                                <button className='btn' onClick={this.clickrightmenu}><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                            </div>
                        </div> 
                        <div className={this.state.fixsedtop?"col-lg-3 p-0 fixsedtop":"col-lg-3 p-0 statictop"} id="slidemyb2bleft" style={this.state.styleleftmenu}>
                            <aside className="sidebar static">
                                <Maselectionentreprises />
                                <Mesabonnes />
                                <Entreprisesenvedette />
                            </aside>
                        </div>
                        <div className='col-lg-6'>
                            <Filactualite />
                        </div>
                        <div className={this.state.fixsedtop?"col-lg-3 p-0 fixsedtop":"col-lg-3 p-0 statictop"} id="slidemyb2bright" style={this.state.stylerightmenu}>
                            <aside className="sidebar static">
                                <Monentreprise />
                                <Produitsenvedette />
                                <Ajoutezavotrefilactualite />
                                <Evenementsavenir />
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            
        </>);
    }
}
 
