import React from "react";
const fallback = () => {
    return (
        <div style={{
            width:"100%",
            height:"100vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
        }}></div>
    )
}
export default fallback;